<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div style="padding-bottom: 30px;">

            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="168" :lazy-src="$root.imagePlaceholder" src="../assets/2024/PlatinumClub_Header_Confirmations_1125x450.jpg"></v-img>

                <!-- <div v-if="$root.Envirenment == 3"></div> -->
                <!-- <div v-if="$root.Envirenment == 3">
                    <v-card flat class="zindex2 transparentbg">
                        <v-card-text><p>Check back for updates!</p></v-card-text>
                    </v-card>
                </div> -->

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <template v-if="listContents && listContents.length">
                
                    <v-card class="ma-4 zindex2" v-for="(item, index) in listContents" :key="index">
                        <div class="title-wrapper pl-4 pr-4 pt-2 pb-2">
                            <h3 class="title">{{item.Title}}</h3>
                        </div>
                        <v-divider class="mr-4 ml-4"></v-divider>
                        <v-card-text class="pb-4 short-card-text">
                            <div class="pb-3" v-if="item.ActivityDate != null && item.ActivityDate != ''"><strong>Date: </strong> {{item.ActivityDate | moment("ddd, MMM Do YYYY")}}</div>
                            <div class="pb-3" v-if="item.DisplayTime != null && item.DisplayTime != ''"><strong>Time: </strong> {{item.DisplayTime}}</div>
                            <div v-if="item.Attendees.length">
                                <div><strong>Attendees</strong></div>
                                <v-layout row wrap ma-0>
                                    <v-flex xs6 sm6 md6 pt-2 v-for="(attendee, attendeeIndex) in item.Attendees" :key="attendeeIndex">
                                        <div>{{attendee.AttendeeName}}</div>
                                        <div light-opacity-color>{{attendee.AttendeeType}}</div>
                                    </v-flex>
                                </v-layout>

                                <v-divider class="mt-4 mb-4"></v-divider>
                            </div>

                            <div v-if="item.ActivityDate && item.Title != 'Pinnacle Winner Rolex Watch Fitting'" class="pb-1"><strong>Schedule: </strong>{{item.ActivityDate | moment("ddd, MMM Do YYYY")}}</div>
                            <div v-html="item.Content"></div>
                        </v-card-text>

                        <v-card-actions v-if="item.ActivityDate" @click="fn_openMoreDetails(item)">
                            <v-btn color="primary" text>View More</v-btn>
                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-card-actions>
                        
                    </v-card>             
                    
                </template>
                
            </template>
        </div>

        <div class="pcfooter_image">
            <img src="../assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
        </div>
        <div class="pcfooter_wave_image">
            <img src="../assets/2024/PlatinumClub_BottomWave_1120x112.png">
        </div>
        <!-- <div class="pcfooter_image">
            <img v-show="0" src="../assets/PlatinumClub2023_FooterImage_420x200.png">
        </div> -->

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('eveningevents')">Refresh Now</v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="contentDialog" scrollable transition="dialog-bottom-transition" content-class="platinumclub-dialog" fullscreen persistent>
            <v-card flat v-if="currentItem">
                
                <v-card-title class="title pb-4 pl-4" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn icon class="mr-4" @click="contentDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    {{currentItem.Title}}
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-tabs v-model="tabIndex" fixed-tabs background-color="#f0f0f0" :slider-color="$root.themeColor">
                        <v-tab>Details</v-tab>
                        <v-tab>Attendees</v-tab>

                        <v-tab-item>
                            <div class="pa-4">
                                <div class="pb-1" v-if="currentItem.ActivityDate != null && currentItem.ActivityDate != ''"><strong>Date: </strong> {{currentItem.ActivityDate | moment("ddd, MMM Do YYYY")}}</div>
                                <div class="pb-1" v-if="currentItem.DisplayTime != null && currentItem.DisplayTime != ''"><strong>Time: </strong> {{currentItem.DisplayTime}}</div>
                                <div v-html="currentItem.Content"></div>
                            </div>
                            
                        </v-tab-item>

                        <v-tab-item>
                            <div class="pt-4">

                                <div v-for="(item, index) in currentItem.Attendees" :key="index">

                                    <!-- <v-subheader class="title" color="grey">{{item.AttendeeName}}</v-subheader> -->
                                    <div class="title pl-4 pr-4 pt-2 pb-2">{{item.AttendeeName}}</div>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card class="ma-4" v-for="(question, questionIndex) in item.Questions" :key="questionIndex">
                                        <v-card-text class="">
                                            <p><strong>Q.  {{question.Question}}</strong></p>
                                            <p>A. {{question.Response}}</p>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs>
                </v-card-text>
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="contentDialog = false">close</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import overview from './sub_components/overview'

// import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        // 'accordians':accordians
    },
    data() {
        return {
            error:false,
            additionalContent:null,
            contentDialog:false,
            currentItem:null,
            tabIndex:0
        }
    },
    methods:{


        fn_openMoreDetails: function(item){
            this.currentItem = item;
            this.tabIndex = 0;
            this.contentDialog = true;
            this.fn_captureMetrics();
        },

        fn_updateAvailable: function(dataObj){
            let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
            if(updateObj.pageName==='myactivities'){
                if(this.overviewContent || this.accordianContent){
                    this.snackbarMsg = true;
                }
                else{
                    this.fn_getData('myactivities');
                }
            }
        },

        fn_closeDialog: function(){
            if(this.contentDialog){
                this.contentDialog = false;
            }
            else{
                window.js_globals.fn_onBackPress = this.$root.fn_goBack;
                this.$root.fn_goBack();
            }
        },

        fn_captureMetrics: function(){
            var tempObj = { ObjectTitle: this.currentItem.Title, ObjectType: "My Confirmations", ActionType: 'Link', LinkPath: null };
            this.$root.fn_postAccordianClickLinkMetrics(tempObj);
        }

    },
    mounted(){
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.$root.fn_postClickMetrics('Explore Tiles', 'My Confirmations', null);
        this.fn_getPageDataFromServer('myactivities');
        window.js_globals.fn_onBackPress = this.fn_closeDialog;
        // this.fn_refresh();
    }
}
</script>