<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Share App</v-toolbar-title>
        </v-toolbar>

        <div>
            <v-card flat center-align class="pa-4 pt-8 qrstepper">
                <!--<template v-if="$root.tripOnLoadData.AttendeeInfo">
                    <div class="title">
                        <template v-if="this.$route.params.giftFor == 1">
                            {{$root.tripOnLoadData.AttendeeInfo.AttendeeName}} &amp; Guest
                        </template>
                        <template v-else-if="this.$route.params.giftFor == 2">
                            {{$root.tripOnLoadData.AttendeeInfo.AttendeeName}}
                        </template>
                        <template v-else>
                            Guest
                        </template>
                    </div>
                    <div style="font-size:1rem" v-if="$root.tripOnLoadData.AttendeeInfo.JobTitle">{{$root.tripOnLoadData.AttendeeInfo.JobTitle}}</div>
                </template>-->
                
                <v-card-text class="text-left pa-0">
                    <div class="pb-3">
                        <div>
                            <p>Please follow the below steps to download the <span class="font-weight-medium">Platinum Club Trip</span> Mobile App. If your hosted guest would also like to download the app, please ask them to follow the instructions below.</p>
                        </div>
                    </div>

                    <div>
                        <div>
                            <v-card class="mb-4">
                                <div class="title-wrapper">
                                    <h3 class="title pl-4 pr-4 pt-2 pb-2">Guest's Login Details</h3>
                                </div>
                                <v-divider class="mr-4 ml-4"></v-divider>
                                <v-card-text class="pb-1 pt-0">
                                    <div class="form_sub_sections pt-4 pb-4">                   
                                        <div class="login-form">
                                            <!-- <div class="subtitle-1 pt-0">Enter guest mobile number<span class="red--text">*</span></div>
                                            <v-text-field prefix="+91" hide-details :disabled="loading || disableSaveBtn" type="tel" name="code" v-model="PhoneNumber" class="pa-0" required @input="fn_isCodeValid"></v-text-field> -->
                                            <!-- <label for="email" font-small>Email</label> -->
                                            <div class="subtitle-1 pt-0">Enter Guest's Email Address<span class="red--text">*</span></div>
                                            <v-text-field :disabled="loading || disableSaveBtn" hide-details id="email" name="email" type="email" v-model="GuestEmail" class="pa-0" required @input="fn_validateEmail"></v-text-field>
                                        </div>
                                        
                                        <div v-if="InvitationCode != null" class="pt-4">
                                            <p>Invitation Code: <b>{{ InvitationCode }}</b> (valid till <b>48 hours</b>)</p>
                                            <p><a @click="fn_generateUserCode(true)">Generate New Code</a></p>
                                        </div>
                                        <div class="text-right mt-4" v-if="$root.tripOnLoadData.AttendeeInfo.AttendeeType != 2">
                                            <v-btn v-if="disableSaveBtn" aria-label="Click to go next step." outlined @click="fn_editPhoneNumber()">Edit</v-btn>
                                            <v-btn v-else :disabled="GuestEmail == null" aria-label="Click to go next step." outlined @click="fn_SaveGuestPhoneNumber()">Save</v-btn>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                            
                        </div>

                        <div>
                            <v-card class="mb-4">
                                <div class="title-wrapper">
                                    <h3 class="title pl-4 pr-4 pt-2 pb-2">Share App</h3>
                                </div>
                                <v-divider class="mr-4 ml-4"></v-divider>
                                <v-card-text class="pb-1 pt-0">
                                    <div class="form_sub_sections pt-4 pb-4">                   
                                        <p class="mb-2">Click on the <span class="font-weight-medium">Share</span> button to share club trip app with guest.</p>
                                        <div class="text-right mt-4">
                                            <v-btn aria-label="Click to share app." outlined @click="fn_shareApp()" class="mr-0">Share</v-btn>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </div>
                        <!-- <v-stepper v-model="currentFormStep" vertical class="pb-0 form_stepper">
                            <v-stepper-step editable complete-icon="edit" class="pt-2 pb-2 font-weight-medium" :complete="currentFormStep > 1" step="1" id="firststep">Add Guest Phone Number</v-stepper-step>
                            <v-stepper-content class="pb-0 pl-4 pr-4 pt-0 mt-0 mr-0 pr-0" :class="{'activestep': currentFormStep==1}" step="1">
                                <div class="pb-5 mb-4">
                                    <div class="form_item">
                                        <div class="form_content height100">
                                            <div class="form_sections height100">
                                                <div class="form_sub_sections pt-4 pb-4">
                                                   
                                                    <div class="login-form">
                                                        <div class="subtitle-1 pt-2">Enter guest mobile number<span class="red--text">*</span></div>
                                                        <v-text-field prefix="+91" hide-details :disabled="loading || disableSaveBtn" type="tel" name="code" v-model="PhoneNumber" class="pa-0" required @input="fn_isCodeValid"></v-text-field>
                                                    </div>
                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right mt-3 pr-3" v-if="PhoneNumber != null && PhoneNumber != ''">
                                        <v-btn v-if="disableSaveBtn" :disabled="PhoneNumber == null" aria-label="Click to go next step." outlined @click="fn_editPhoneNumber()">Edit</v-btn>
                                        <v-btn v-else :disabled="PhoneNumber == null" aria-label="Click to go next step." outlined @click="fn_SaveGuestPhoneNumber()">Save & NEXT</v-btn>
                                    </div>
                                </div>
                            </v-stepper-content>
                            
                            <v-stepper-step editable complete-icon="edit" class="pt-2 pb-2 font-weight-medium" :complete="currentFormStep > 2" step="2" id="firststep">Download Club Trip App</v-stepper-step>
                            <v-stepper-content class="pb-0 pl-4 pr-4 pt-0 mt-0 mr-0 pr-0" :class="{'activestep': currentFormStep==2}" step="2">
                                <div class="pb-5 mb-4">
                                    <div class="form_item">
                                        <div class="form_content height100">
                                            <div class="form_sections height100">
                                                <div class="form_sub_sections">
                                                    <div>
                                                        <v-radio-group v-model="DownloadTypeSelection" row hide-details class="pb-0">
                                                            <v-radio :class="{'pb-2': $vuetify.breakpoint.smAndDown }" label="Download iOS App" value="1"></v-radio>
                                                            <v-radio :class="{'pb-2': $vuetify.breakpoint.smAndDown }" label="Download Android App" value="2"></v-radio>
                                                            <v-radio label="Already have App" value="3"></v-radio>
                                                        </v-radio-group>
                                                    </div>
                                                    <div class="download_steps pt-1" v-if="DownloadTypeSelection == 1">
                                                        <div class="qr_section">
                                                            <p class="mb-2">Scan below QR code from your mobile camera to download the app</p>
                                                            <div class="qr_image">
                                                                <img src="../assets/iOSAppQR.png">
                                                            </div>
                                                        </div>
                                                        <div class="qr_section mb-2 mt-2">
                                                            <p class="mb-0 font-weight-medium">OR</p>
                                                        </div>
                                                        <div class="qr_section mb-2 mt-2">
                                                            <p class="mb-2">Open below link to download the app<br><a target="_blank" @click="$root.fn_openExternalPage('http://aka.ms/clubtripapp/iOS')">http://aka.ms/clubtripapp/iOS</a></p>
                                                        </div>
                                                    </div>
                                                    <div class="download_steps pt-1" v-if="DownloadTypeSelection == 2">
                                                        <div class="qr_section">
                                                            <p class="mb-2">Scan below QR code from your mobile camera to download the app</p>
                                                            <div class="qr_image">
                                                                <img src="../assets/AndroidAppQR.png">
                                                            </div>
                                                        </div>
                                                        <div class="qr_section mb-2 mt-2">
                                                            <p class="mb-0 font-weight-medium">OR</p>
                                                        </div>
                                                        <div class="qr_section mb-2 mt-2">
                                                                <p class="mb-2">Open below link to download the app<br><a target="_blank" @click="$root.fn_openExternalPage('https://aka.ms/clubtripapp/Android')">https://aka.ms/clubtripapp/Android</a></p>
                                                        </div>
                                                    </div>
                                                    <div class="download_steps pt-1" v-if="DownloadTypeSelection == 3">
                                                        <div class="qr_section mb-2 mt-2">
                                                            <p class="mb-2">Click on <span class="font-weight-medium">NEXT</span> button for login instructions.</p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right mt-3 pr-3" v-if="DownloadTypeSelection != null">
                                        <v-btn aria-label="Click to share app." outlined class="mr-4" @click="fn_shareApp">Share</v-btn>
                                        <v-btn aria-label="Click to go next step." outlined @click="fn_moveNext()">NEXT</v-btn>
                                    </div>
                                </div>
                            </v-stepper-content>
                            <v-stepper-step :editable="currentFormStep > 2" class="pt-2 pb-2 font-weight-medium" complete-icon="edit" :complete="currentFormStep > 3" step="3" id="guestsecondstep">Login into Club Trip App</v-stepper-step>

                            <v-stepper-content class="pb-0 pl-4 pr-4 mt-0 mr-0 pr-0 openstepper" step="3" >
                                <div class="pb-5 mb-4" >
                                    <div class="form_item">
                                        <div class="form_content height100">
                                            <div class="form_sections height100">
                                                <div class="form_sub_sections">
                                                    <div class="fields_sections_header">Login Instructions</div>
                                                    <p class="font-weight-medium">Login via Mobile Number</p>
                                                    <ol>
                                                        <li>Open <span class="font-weight-medium">Club Trip</span> App</li>
                                                        <li>Enter your mobile number and click on <span class="font-weight-medium">Continue</span> button</li>
                                                        <li>Enter 6 digit <span class="font-weight-medium">OTP</span> sent on provided mobile number</li>
                                                        <li>Click on <span class="font-weight-medium">Trip</span> tile to complete login process</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-stepper-content>
                        </v-stepper> -->
                    </div>


                    <!-- <template v-if="!loading">
                        <p class="mt-2 mb-0">Get this QR Code scanned to Login as Guest</p>         
                    </template> -->
                    
                    <!-- Scan this QR Code for more information -->

                    <!-- <v-img style="margin:auto" width="320" height="320" :src="dataUrl">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img> -->
                </v-card-text>
            </v-card>

            <!-- <div style="color:#999">Show this QR Code at the reception to get scanned</div> -->
        </div>
        
        <v-dialog v-model="adding" persistent width="300">
            <v-card>
                <v-card-text class="pt-4">{{LoadingDialogMessage}}
                    <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import QRCode from 'qrcode'
import axios from 'axios';

  export default {
    data: () => ({ 
        dataUrl:null,
        loading:false,
        LoadingDialog: false,
        AttendeeCode: null,
        AttendeeRegistrationID: null,
        DeepLink: null,
        currentFormStep: 1,
        DownloadTypeSelection: null,
        hours: null,
        minutes: null,
        seconds: null,
        CodeDetails: {},
        ShowInvitationCode: false,
        AttendeeType: null,
        IsUpdateTimer: true,
        GuestEmail: null,
        isCodeValid:false,
        adding: false,
        disableSaveBtn: false,
        CountryCode: "+91 ",
        InvitationCode: null,
        LoadingDialogMessage: null,
        isValid:true,
    }),
    methods:{

        // fn_getRequestId: function(){
        //     // this.showToast("Test");
        //     if(!navigator.onLine){
        //         this.showToast("Internet not available");
        //         return;
        //     }
            
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.TripSiteID = 7;
        //     dataObj.GiftID = this.$route.params.giftId;
        //     dataObj.GiftFor = this.$route.params.giftFor;
        //     this.postServerData('GiftRequestId', dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.fn_generateQRCode(response.RequestID);
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //             }, 
        //         }
        //     );
        // },


        fn_goback: function (stes) {
            this.currentFormStep = stes;
            this.fn_scrollToView('firststep');
        },

        fn_validateEmail: function(value){
            let emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            this.isValid = emailCheck.test(value);
        },

        fn_moveNext: function () {
            this.currentFormStep = parseInt(this.currentFormStep);
            this.currentFormStep = this.currentFormStep + 1;
            this.fn_scrollToView('guestsecondstep');
            //this.AttendeeType = 2;
            //this.fn_getGenerateQRCode();
        },

        fn_getRequestId: function(){

            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                return;
            }

            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    this.error = true;
                    this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/guest/qrcode/attendee/generate", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        var GuestQRCode = JSON.stringify({ InvitationCode: 'clubtripappinstallation?iid=' + response.data.Code, Email: response.data.EmailAddress,  WinnerType: 2 });
                        this.fn_generateQRCode(GuestQRCode);
                        this.fn_timer();
                        console.log(GuestQRCode);
                        // this.giftItems = response.data.gifts;
                    }).catch(e => {
                        this.loading = false;
                        this.error = true;
                        console.log(e);
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                    })
                }
            })

        },

        fn_generateQRCode: function(requestId){
            // With promises
            QRCode.toDataURL(requestId).then(url => {
                console.log(url);
                this.dataUrl = url;
            })
            .catch(err => {
                console.error(err)
            })
        },

        fn_timer: function () {
            //this.IsUpdateTimer = false;
            var countDownTime = null;
            countDownTime = new Date().getTime() + 5 * 60 * 1000;
            this.hours = 0;
            this.minutes = 0;
            this.seconds = 0;
            let self = this;
            var x = setInterval(function () {
                var now = new Date().getTime();
                var distance = countDownTime - now;
                self.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                self.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                self.seconds = Math.floor((distance % (1000 * 60)) / 1000);

                if (distance < 0) {
                    self.fn_getRequestId();
                    clearInterval(x);
                }
            }, 1000);
        },

        fn_openExternalPage: function(pageUrl){
            this.getData('OpenBrowser', pageUrl, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_scrollToView: function (value) {
            var ele = document.getElementById(value);
            if (ele != null) {
                ele.scrollIntoView();
            }
        },

        fn_isCodeValid: function(){
            this.isCodeValid = !this.GuestEmail || this.GuestEmail.length != 10 ? false : true;
        },

        fn_SaveGuestPhoneNumber: function(){
            this.loading = true;
            this.adding = true;
            this.error = false;
            this.LoadingDialogMessage = "Saving...";
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    this.adding = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    // let self = this;
                    let dataObj = {};
                    dataObj.EmailAddress = this.GuestEmail;
                
                    console.log(headersObj);
                    axios.post(this.$root.baseUrl + "/api/mobileapp/guest/emailaddress/add", dataObj, headersObj, {timeout:30000})
                    .then(response => {
                        this.$root.tripOnLoadData.AttendeeInfo.EmailAddress = this.GuestEmail;
                        this.loading = false;
                        // this.adding = false;
                        this.disableSaveBtn = true;
                        if(this.InvitationCode == null)
                            this.fn_generateUserCode(true);
                        else
                            this.fn_generateUserCode(false);
                        console.log(response);
                        // this.snackbarMsg = false;
                        this.fn_moveNext();
                        //this.fn_getDetails();
                        
                    }).catch(e => {
                        this.loading = false;
                        this.adding = false;
                        // this.error = true;
                        console.log(e);
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                    })
                }
            })
        },

        fn_editPhoneNumber: function(){
            this.disableSaveBtn = false;
        },

        fn_shareApp: function(){
            let self = this;
            let dataObj = {};
            dataObj.ShareAppMessage = `ClubTrip App - Travel companion for your trip, with all your trip information in one place.
Email Address: ${this.GuestEmail}
Invitation Code: ${this.InvitationCode}`;
            if(this.$root.platform === 'ios'){
                dataObj.ShareAppWebsiteURL = "";
            }
            else{
                dataObj.ShareAppWebsiteURL = "";
            }
            this.postData('ShareApp', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.$root.fn_showToast('exception');
                    }, 
                }
            );
        },

        fn_generateUserCode: function(isregeneratecode){
            this.loading = true;
            this.adding = true;
            this.LoadingDialogMessage = "Loading...";
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    this.adding = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    // let self = this;
                
                    let dataObj = {};
                    dataObj.IsRegenerateCode = isregeneratecode;
                    console.log(headersObj);
                    axios.post(this.$root.baseUrl + "/api/mobileapp/guest/emailaddress/code/generate", dataObj, headersObj, {timeout:30000})
                    .then(response => {
                        this.InvitationCode = response.data.Code;
                        this.loading = false;
                        this.adding = false;
                        console.log(response);
                    }).catch(e => {
                        this.loading = false;
                        this.adding = false;
                        // this.error = true;
                        console.log(e);
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                    })
                }
            })
        }

    },
    mounted: function(){
        // this.fn_generateQRCode();
        this.$root.fn_postClickMetrics('Profile', 'Share App', null);
        //this.fn_getRequestId();
        this.GuestEmail = this.$root.tripOnLoadData.AttendeeInfo.EmailAddress;
        if(this.GuestEmail != null && this.GuestEmail !=''){
            this.fn_generateUserCode(false);
            this.disableSaveBtn = true;
        }
            
        //window.js_globals.fn_detailsUpdated = this.fn_updateGiftDetails;
    }
  }
</script>

<style>
    .qrstepper .v-card__text .v-stepper{
        overflow: visible;
        margin-bottom: 16px;
    }


</style>