<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <!-- <a type="button" href="./../assets/platinumclub/MC_PC_2022_DA_Monaco_map.pdf" target="_blank">View Map</a> -->
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/2024/PC_KeyLocationsBanner_1125x450.png"></v-img>

                <v-card flat>
                    <div>
                        <v-card-title class="pb-2">The Ritz-Carlton Maui, Kapalua</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">1 Ritz-Carlton Drive,<br>Kapalua, Hawaii, USA, 96761</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('The Ritz-Carlton Maui, Kapalua', 21.001034, -156.654064)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('The Ritz-Carlton Maui, Kapalua', 21.000427, -156.653961)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                </v-card>

                <!-- <v-card class="ma-4">
                    <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/GCAmercasResortMap_400x220.png"></v-img>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <a type="maplink" class="pr-2" href="/assets/gcasia/resort_map_9.pdf" target="_blank">View Map</a>
                    </v-card-actions>
                </v-card> -->

                <!-- <overview :content="overviewContent" v-if="overviewContent"></overview>

                <accordians :content="accordianContent" v-if="accordianContent"></accordians> -->

            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('hotel')">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

    </div>
</template>

<script>
// import overview from './sub_components/overview'
// import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    // components:{
    //     'overview':overview,
    //     'accordians':accordians
    // },
    methods:{
        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     if(updateObj.pageName==='hotel'){
        //         if(this.overviewContent || this.accordianContent){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             this.fn_getData('hotel');
        //         }
        //     }
        // }

        fn_openLocationOnMap: function(placeName, latitude, longitude){
            let dataObj = {};
            dataObj.PlaceName = placeName;
            dataObj.Latitude = latitude;
            dataObj.Longitude = longitude;
            this.fn_captureMetrics(placeName);
           
            this.postData('OpenMaps', dataObj, 
                {
                    onSuccess: function(response){
                        console.log("Success Map");
                        console.log(response);
                    },
                    onError: function(error){
                        console.log("Error Map");
                        console.log(error);
                    }
                }
            );
        },

        fn_captureMetrics: function(objecttitle){
            var tempObj = { ObjectTitle: objecttitle, ObjectType: this.$route.name, ActionType: 'Link', LinkPath: null };
            this.$root.fn_postAccordianClickLinkMetrics(tempObj);
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Resort Info', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getPageDataFromServer('keylocations');
    }
}
</script>