<template>
    <div>
        <v-expansion-panels accordion focusable>
            <v-expansion-panel v-for="(item, index) in content" class="transparentbg" :key="index" @click="fn_getCategoryAnalytics($event)">
                <v-expansion-panel-header class="font-medium pa-4">{{item.title}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- <div v-html="item.content" v-if="item.content"></div> -->
                    <v-card flat class="transparentbg">
                        <v-card-text class="pa-0">
                            <div v-html="item.content" v-if="item.content"></div>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default{
        props:['content'],
        methods:{
            fn_getCategoryAnalytics: function (event) {
                console.log(event.target.parentElement.classList);
                var tempObj = { href: null, ObjectTitle: null, ObjectType: this.$route.name, objecttitle: null, actiontype: null };
                if (event.target.parentElement.classList.contains('v-expansion-panel-header__icon')) {
                    if (!(event.target.parentElement.parentElement.classList.contains('v-expansion-panel--active')
                        || event.target.parentElement.parentElement.classList.contains('v-expansion-panel-header--active'))) {
                        tempObj.LinkPath = 'Questionclickactive';
                        tempObj.ObjectTitle = event.target.parentElement.parentElement.textContent;
                    }
                    else if (event.target.parentElement.parentElement.classList.contains('v-expansion-panel--active')
                        || event.target.parentElement.parentElement.classList.contains('v-expansion-panel-header--active')) {
                        tempObj.LinkPath = 'Questionclickinactive';
                        tempObj.ObjectTitle = event.target.parentElement.parentElement.textContent;
                    }
                }
                else {
                    if (!(event.target.parentElement.classList.contains('v-expansion-panel--active')
                        || event.target.parentElement.classList.contains('v-expansion-panel-header--active'))) {
                        tempObj.LinkPath = 'Questionclickactive';
                        tempObj.ObjectTitle = event.target.textContent;
                    }
                    else if (event.target.parentElement.classList.contains('v-expansion-panel--active')
                        || event.target.parentElement.classList.contains('v-expansion-panel-header--active')) {
                        tempObj.LinkPath = 'Questionclickinactive';
                        tempObj.ObjectTitle = event.target.textContent;
                    }
                }

                //tempObj.ObjectTitle = event.target.textContent;
                tempObj.ActionType = "Accordian";
                this.$root.fn_postAccordianClickLinkMetrics(tempObj);


            }
        }
    }
</script>