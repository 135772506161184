<template>
    <div full-height grey-back>
        <v-list v-if="$root.tripOnLoadData.AttendeeInfo">
            <v-list-item>
                <v-btn icon @click="$router.go(-1)">
                    <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                </v-btn>
                <v-list-item-content>
                    <v-list-item-title>{{$root.tripOnLoadData.AttendeeInfo.AttendeeName}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar color="#f0f0f0">
                    <v-img :src="profilePicture" v-if="$root.tripOnLoadData.AttendeeInfo.ProfilePicture"></v-img>
                    <v-icon v-else>person</v-icon>
                </v-list-item-avatar>   
            </v-list-item>
        </v-list>

        <v-list two-line class="pa-0 mt-2">
            <v-list-item @click="fn_changeTrip">
                <v-list-item-icon>
                    <v-icon>swap_horiz</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Change Trip</v-list-item-title>
                    <v-list-item-subtitle>Switch to another trip</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!-- <v-divider></v-divider>

            <v-list-item @click="fn_gotoAddImagePage">
                <v-list-item-icon>
                    <v-icon>mdi-account-arrow-up</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.ProfilePicture ? 'Change Photo' : 'Upload Photo' }}</v-list-item-title>
                    <v-list-item-subtitle>{{$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.ProfilePicture ? 'Change' : 'Upload'}} your photo</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-divider></v-divider> -->

            <!-- <v-list-item to="/platinumclub/qrcode">
                <v-list-item-icon>
                    <v-icon>qr_code_scanner</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Generate QR Code</v-list-item-title>
                    <v-list-item-subtitle>Generate QR Code to share information</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-list-item to="/platinumclub/gifts">
                <v-list-item-icon>
                    <v-icon>redeem</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>My Gifts</v-list-item-title>
                    <v-list-item-subtitle>View and collect your gifts</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider> -->

            <!-- <v-list-item @click="fn_checkCameraPermission">
                <v-list-item-icon>
                    <v-icon>qr_code_scanner</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Scan QR Code</v-list-item-title>
                    <v-list-item-subtitle>Scan QR Code to verify gifting details</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-divider></v-divider>

            <v-list-item to="/platinumclub/photoalbum">
                <v-list-item-icon>
                    <v-icon>photo_library</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Photo Album</v-list-item-title>
                    <v-list-item-subtitle>View your photo album</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-divider></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon>help_outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Help &amp; Support</v-list-item-title>
                    <v-list-item-subtitle>FAQs &amp; More</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->

            <v-divider v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.AttendeeType != 2"></v-divider>

            <v-list-item v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.AttendeeType != 2" @click="fn_gotoQRPage">
                <v-list-item-icon>
                    <v-icon>mdi-share-variant-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Share App</v-list-item-title>
                    <v-list-item-subtitle>Share with Hosted Guest</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.IsMobileAdmin"></v-divider>

            <v-list-item v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.IsMobileAdmin" @click="fn_openAdminApp">
                <v-list-item-icon>
                    <v-icon>mdi-account-cog</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Admin Process</v-list-item-title>
                    <v-list-item-subtitle>Admin Process</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="fn_about">
                <v-list-item-icon>
                    <v-icon>info_outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>About</v-list-item-title>
                    <v-list-item-subtitle>More about app</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="logout-item" @click="$root.fn_logOut">
                <v-list-item-icon class="mb-4">
                    <v-icon color="red">logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- <v-divider></v-divider>

            <v-list-item @click="$root.fn_testFn">
                <v-list-item-icon class="mb-4">
                    <v-icon>info</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>For Testing</v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->

            <!--<template v-if="$root.platform === 'ios'">
            
                <v-divider></v-divider>

                <v-list-item class="logout-item" @click="$root.fn_logOut">
                    <v-list-item-icon class="mb-4">
                        <v-icon color="red">logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                
            </template>-->

            <!-- <v-divider></v-divider>

            <v-list-item @click="$root.fn_copyLogs">
                <v-list-item-icon class="mb-4">
                    <v-icon>content_copy</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Copy Logs</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="$root.fn_token">
                <v-list-item-icon class="mb-4">
                    <v-icon>more_horiz</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>For Development</v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-btn color="success" @click="fn_downloadImage">text</v-btn> -->


        </v-list>

        <v-dialog v-model="contentDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card>
                <v-card-title class="title pb-4 pl-4" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn icon class="mr-4" @click="contentDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    Upload Photo
                    <v-spacer></v-spacer>
                    <v-btn class="white--text" :color="$root.themeColor" @click="fn_UploadPhoto()">Upload</v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content override_scroll pt-0 pl-0 pr-0">
                    <template>
                        <template v-if="uploadLoading">
                            <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
                        </template>
                        <template v-else>
                            <div v-if="formObj.ImageBase64" style="position: relative;">
                                <!-- <v-btn @click="fn_removeImage()" class="remove_btn" color="white" icon >
                                    <v-icon size="28px">mdi-close</v-icon>
                                </v-btn> -->
                                <img style="display: block;" width="100%" :src="'data:image/png;base64, ' + formObj.ImageBase64"/>
                            </div>
                        </template>
                        
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
        profilePicture:null,
        formObj: { ImageName: null, ImageBase64: null },
        loading: false,
        error: false,
        contentDialog: false,
        uploadLoading: false
    }),
    methods: {
        fn_about: function(){
          this.$router.push({path:'/about'});
        },
        
        fn_changeTrip: function(){
            this.$router.push({path:'/trips', query:{goback:1}})
        },

        fn_gotoQRPage: function(){
            //this.$router.push({name:'emearesort', params:{pageName:pagename}});
            if(this.$root.tripOnLoadData.AttendeeInfo.IsBringGuest)
                this.$router.push('/gcasia/guestqrcode');
            else
                this.$root.fn_showToast('No guest exist to share app!');
        },

        fn_checkCameraPermission: function(){
            let self = this;
            this.getData('CheckCameraPermission', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_scanQR();
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            ); 
        },

        fn_downloadImage: function(){
            let self = this;
            let dataObj = {};
            dataObj.ImageName = "abc.png";
            dataObj.ImageUrl = "https://picsum.photos/id/11/10/6";
            this.getServerData('DownloadImage', dataObj, {
                onSuccess: function(response){
                    console.log(response);
                    self.fn_getImage();
                }, 
                onError: function(error){
                    console.log(error);
                    self.$root.fn_showToast('exception');
                }, 
            });
        },

        fn_getImage: function(){
            this.getData('GetImage', 'abc.png', 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_shareImage: function(){
            this.getData('ShareImage', 'abc.png', 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_scanQR: function(){
            this.getData('OpenScanner', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_photoAuth: function() {

            this.loading = true;
            this.error = false;
            let self = this;

            if (self.$root.tripOnLoadData.AttendeeInfo.ProfilePicture && !self.$root.tripOnLoadData.AttendeeInfo.ProfilePicture.startsWith('data:image/')){
                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        this.loading = false;
                        this.error = true;
                        this.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };

                        var getUrl = self.$root.baseUrl + self.$root.tripOnLoadData.AttendeeInfo.ProfilePicture;
                        axios.get(getUrl, headersObj, {timeout:30000})
                        .then(response => {
                            self.profilePicture = 'data:image/png;base64,' + response.data;
                            console.log(self.attendeeInfo.ImageUrl);
                            this.loading = false;
                            self.$forceUpdate();
                        }).catch(e => {
                            this.loading = false;
                            this.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            this.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

            }
            else{
                self.profilePicture = self.$root.tripOnLoadData.AttendeeInfo.ProfilePicture;
            }
        },

        fn_openAdminApp: function(){
            console.log('adminview');
            let postObj = {};
            let baseChatUrl = "";
            console.log(this.$root.Envirenment);
            if(this.$root.Envirenment == 3){
                baseChatUrl = "https://clubtripadminapp.web.app/";
            }
            else{
                baseChatUrl = "https://clubtripadminapp.web.app/";
                // baseChatUrl = "https://pcgctripadmin-dev.web.app/";
                // baseChatUrl = "https://pcgctripadmin-uat.web.app/";
                // baseChatUrl = "http://192.168.100.40:8081/";
            }

            postObj.url = baseChatUrl + "?tid="+this.$root.tripSiteId;

            this.postData('OpenAdminApp', postObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_gotoAddImagePage: function(){
          this.fn_checkStorage();
            // this.$router.push({path:'/platinumclub/attendee/'+attendeeId});
        },

        fn_checkStorage: function(){
          this.formObj.ImageBase64 = null;
          this.formObj.ImageName = null;
          if(this.$root.platform === 'ios'){
              this.fn_selectFile(1);
          }
          else{
              this.$root.fn_checkStoragePermission(()=>{
                  this.fn_selectFile(1)
              })
          }
        },

        fn_selectFile: function(fileType){
            let self = this;
            let dataObj = {};
            dataObj.fileType = fileType;
            // dataObj.fileExtensions = ['PDF','DOC','DOCX','TEXT','XLX','XLSX','CSV'];
            dataObj.imageExtension = ['JPG','JPEG','PNG','GIF'];
            // this.dialogLoading = true;
            this.postData("SelectLocalDocument", dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        console.log('Called File');
                        // self.fn_uploadfile(response, fileType)
                        self.formObj.ImageName = response.fileName;
                        if(self.$root.platform === 'ios'){
                            self.formObj.FileType = fileType;
                            self.formObj.ImageBase64 = response.base64StringData;
                            self.contentDialog = true;
                        }
                        else{
                            self.fn_openFile(response, fileType);
                        }
                    },
                    onError: function(error){
                        self.dialogLoading = false;
                        console.log(error);
                    }
                }
            );
        },

        fn_openFile: function(fileDetails, fileType){
            let self = this;
            this.getFilterData('OpenDocumentFile', fileDetails.filePath, null,
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.formObj.FileType = fileType;
                        // self.formObj.ImageName = response.fileName;
                        self.formObj.ImageBase64 = response;
                        //self.fn_uploadfile(response, fileDetails, fileType)
                        self.contentDialog = true;
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.dialogLoading = false;
                    }
                }
            );
        },

        fn_UploadPhoto: function(){

          if(!navigator.onLine){
              this.showToast("nointernet");
              return;
          }

          this.uploadLoading = true;
          this.error = false;
          var self = this;
          this.$root.fn_getToken(token=>{
              if(token == 'error'){
                self.uploadLoading = false;
                self.error = true;
                self.$root.fn_showToast('exception');
              }
              else{
                  var headersObj = {
                      headers: {
                          'Authorization': 'bearer '+token,
                          }
                      };

                  let dataObj = {};
                  dataObj.ImageName = self.formObj.ImageName;
                  dataObj.ImageBase64 = self.formObj.ImageBase64;

                  axios.post(this.$root.baseUrl + "/api/mobileapp/attendee/uploadprofile/picture", dataObj, headersObj, {timeout:30000})
                  .then(response => {
                      self.uploadLoading = false;
                      self.contentDialog = false;
                      self.profilePicture = 'data:image/png;base64,' + self.formObj.ImageBase64;
                      self.$root.tripOnLoadData.AttendeeInfo.ProfilePicture = self.profilePicture;
                      console.log(response);
                      // this.giftItems = response.data.gifts;
                  }).catch(e => {
                      self.uploadLoading = false;
                      self.error = true;
                      console.log(e);
                      let toastMsg = e && e.message ? e.message : 'exception';
                      self.$root.fn_showToast(toastMsg);
                  })
              }
          })
        }
    },

    mounted() {
        this.fn_photoAuth();
        this.$root.fn_postClickMetrics('Profile', 'Default', null);
    },

  }
</script>