<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <!-- <a type="button" href="./../assets/platinumclub/MC_PC_2022_DA_Monaco_map.pdf" target="_blank">View Map</a> -->
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="168" :lazy-src="$root.imagePlaceholder" src="../assets/2024/Americas_ResortBanner_1125x450.png"></v-img>

                <v-card flat>
                    <v-card-title>Grand Wailea Resort</v-card-title>
                    
                    <v-card-text>
                        <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                        <div style="padding-left:40px">3850 Wailea Alanui Drive<br>Wailea, HI 96753</div>

                        <div class="pt-4">
                            <v-icon style="float:left">mdi-directions</v-icon>
                            <div style="padding-left:40px">
                                <a style="text-decoration: underline;" @click="fn_openLocationOnMap">Get Directions</a>
                            </div>
                        </div>

                        <div class="pt-4">
                            <v-icon style="float:left">mdi-phone-outline</v-icon>
                            <div style="padding-left:40px">
                                <a type="tel" href="tel:18088751234">1-808-875-1234</a>
                            </div>
                        </div>

                        <!-- <div class="pt-4">
                            <v-icon style="float:left">mdi-email-outline</v-icon>
                            <div style="padding-left:40px"><a type="mail" href="mailto:hh@sbm.mc">hh@sbm.mc</a></div>
                        </div> -->

                        <div class="pt-4">
                            <v-icon style="float:left">mdi-web</v-icon>
                            <div style="padding-left:40px"><a type="link" href="https://www.grandwailea.com/">https://www.grandwailea.com/</a></div>
                        </div>
                        <v-card class="ml-0 mr-0 mt-6 ma-4 zindex2">
                            <v-subheader><strong>Grand Wailea Resort</strong></v-subheader>
                            <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/2024/ResortMap_Thumbnail.png"></v-img>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <a type="maplink" class="pr-2" href="/assets/gcamericas/resort_map_17.pdf" target="_blank">View Map</a>
                            </v-card-actions>
                        </v-card>
                    </v-card-text>
                </v-card>

                <!-- <v-card class="ma-4">

                    <v-img height="150" @click="fn_openLocationOnMap" :lazy-src="$root.imagePlaceholder" src="../assets/GCAmercasResortMap_400x220.png"></v-img>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <a type="maplink" class="pr-2" @click="fn_openLocationOnMap">View Map</a>
                    </v-card-actions>
                </v-card> -->

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <accordians :content="accordianContent" v-if="accordianContent"></accordians>

            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('hotel')">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

    </div>
</template>

<script>
import overview from './sub_components/overview'
import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        'accordians':accordians
    },
    methods:{
        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     if(updateObj.pageName==='hotel'){
        //         if(this.overviewContent || this.accordianContent){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             this.fn_getData('hotel');
        //         }
        //     }
        // }

        fn_openLocationOnMap: function(){
            console.log('called');
            let dataObj = {};
            dataObj.PlaceName = "Grand Wailea Resort";
            if(this.$root.platform === 'ios'){
                dataObj.Latitude = 20.684385;
                dataObj.Longitude = -156.440565;
            }
            else{
                dataObj.Latitude = 20.684357;
                dataObj.Longitude = -156.440369;
            }
            
            this.postData('OpenMaps', dataObj, 
                {
                    onSuccess: function(response){
                        console.log("Success Map");
                        console.log(response);
                    },
                    onError: function(error){
                        console.log("Error Map");
                        console.log(error);
                    }
                }
            );
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Resort Info', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        // this.fn_getData('hotel');
        this.fn_getPageDataFromServer('hotel');
    }
}
</script>