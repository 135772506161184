<template>
  <div>
      <!-- <v-alert :color="$root.themeColor" style="border-radius:0;position:sticky;top:0;z-index:1" dense type="info" :value="true">
          Choose attendee to chat with
      </v-alert> -->
      <v-toolbar :color="$root.themeColor" flat style="color:#fff" extended extension-height="62">
          <v-toolbar-title>Winner Attendees</v-toolbar-title>
          <v-text-field dense grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
      </v-toolbar>

      <div>

        <template v-if="!error">
          <v-list class="pa-0" three-line>
            <template v-for="(item, index) in winners">
              <v-list-item :key="index" @click="fn_gotoChatPage(item.AttendeeID)">

                <v-list-item-avatar class="grey lighten-3">
                    <!-- {{fn_abbreviatedName(item.WinnerName)}} -->
                    <template v-if="item.ImageUrl"><v-img alt="winnerimage" :src="$root.getPhotoURL(item.ImageUrl)" lazy-src="../../../assets/person.svg"></v-img></template>
                    <template v-else>{{fn_abbreviatedName(item.WinnerName)}}</template>
                    
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-text="item.WinnerName"></v-list-item-title>
                    <v-list-item-subtitle v-if="item.JobTitle">{{item.JobTitle}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.AreaName">{{item.AreaName}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.Subsidiary && item.AreaName != item.Subsidiary">{{item.Subsidiary}}</v-list-item-subtitle>
                </v-list-item-content>

                <!-- <v-list-item-icon>
                <v-icon>
                    mdi-message-outline
                </v-icon>
                </v-list-item-icon> -->
                </v-list-item>

                <v-divider inset :key="index+'divider'" v-if="index != winners.length - 1"></v-divider>
            </template>
              

              <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results" class="pa-4 light-opacity-color">No attendee found!</div>
              </infinite-loading>
          </v-list>
        </template>

        <template v-else>
          <div style="text-align:center;" class="pa-4">
              <p>Something went wrong. Please try again.</p>
              <br>
              <v-btn outlined @click="fn_debounceSearch"><v-icon left>refresh</v-icon> Retry</v-btn>
          </div>
        </template>
      </div>

      <dialog-attendee-info ref="ref_dialogAttendeeInfo"></dialog-attendee-info>

      
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
import dialogAttendeeInfo from '../winner_attendee_profile'
var controller = null;

// const controller = new AbortController();

  export default {
    data: () => ({
      winners:[],
      infiniteId: +new Date(),
      pageOffset:0,
      searchValue:'',
      timeoutid:null,
      error:false,
    }),
    components: {
      InfiniteLoading,
      'dialog-attendee-info':dialogAttendeeInfo
    },
    methods:{
      fn_debounceSearch: function(){
        console.log('debounce');
        if (this.timeoutid != null){
          clearTimeout(this.timeoutid);
        }

        this.timeoutid = setTimeout(() => { 
          this.error = false;
          this.pageOffset = 0;
          this.winners = [];
          this.infiniteId = +new Date();
        },500);
      },

      // fn_openChat: function(){
      //   this.$router.push("/pinnacle/chat");
      // },

      infiniteHandler($state) { 
        this.error = false;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
              this.error = true; 
              this.$root.fn_showToast('exception');
            }
            else{
                if(controller){
                  controller.abort();
                  console.log('Fetch aborted');
                }
                
                controller = new AbortController();

                var headersObj = {
                  headers: { 
                        'Authorization': 'bearer ' +  token,
                      }
                  };

                let postObj = {}
                postObj.keywords = this.searchValue;

                console.log(JSON.stringify(postObj));

                axios.post(this.$root.baseUrl + "/api/mobileapp/getwinnerattendees?offset="+this.pageOffset+"&limit=30", postObj, headersObj, {timeout:30000}, {signal: controller.signal})
                .then(response => {
                    console.log(response);
                    if(response.data.count > this.winners.length){
                      for(let i=0; i<response.data.attendeeWinners.length; i++){
                          this.winners.push(response.data.attendeeWinners[i]);
                      }
                      $state.loaded();
                    }
                    else{
                      $state.complete();
                    }
                    controller = null;
                    this.pageOffset = this.winners.length;
                }).catch(e => {
                    console.log(e);
                    if(e.response.data == "Token Expired"){
                      this.fn_debounceSearch();
                    }
                    else{
                      let toastMsg = e && e.message ? e.message : 'exception';
                      this.$root.fn_showToast(toastMsg);
                      this.error = true;
                    }
                })
            }
        })
        
      },

        fn_gotoChatPage: function(attendeeId){
          this.$refs['ref_dialogAttendeeInfo'].fn_getAttendeeInfo(attendeeId);
            // this.$router.push({path:'/platinumclub/attendee/'+attendeeId});
        },

        fn_abbreviatedName: function(str){
            if(!str){
              return ''
            }
            //let words = str.trim().split(' ');
            //let words = str.trim().split(' ');
            //words = words.replace(/ +(?= )/g,'');

            let words = str.trim().replace(/ +(?= )/g,'').split(' ');

            if(words.length > 1){
                return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
            }
            else{
                return words[0][0].toUpperCase();
            }
        },
    },
  }
</script>