<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <!-- <a type="button" href="./../assets/platinumclub/MC_PC_2022_DA_Monaco_map.pdf" target="_blank">View Map</a> -->
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/Demo_ResortBanner_420x200.png"></v-img>

                <v-card flat>
                    <v-card-title>Burasari Phuket Resort &amp; Spa</v-card-title>
                    
                    <v-card-text>
                        <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                        <div style="padding-left:40px">18/110 Ruamjai Road, Tambon Patong,<br>Amphur Kathu, Patong Beach<br>Phuket 83150, Thailand</div>

                        <div class="pt-4">
                            <v-icon style="float:left">mdi-phone-outline</v-icon>
                            <div style="padding-left:40px">
                                <a type="tel" href="tel:+6676292929">+66-76-292-929</a>
                            </div>
                        </div>

                        <div class="pt-4">
                            <v-icon style="float:left">mdi-email-outline</v-icon>
                            <div style="padding-left:40px"><a type="mail" href="mailto:inquiry@burasari.com">inquiry@burasari.com</a></div>
                        </div>

                        <div class="pt-4">
                            <v-icon style="float:left">mdi-web</v-icon>
                            <div style="padding-left:40px"><a type="link" href="https://phuket.burasari.com">https://phuket.burasari.com</a></div>
                        </div>
                    </v-card-text>

                    <!-- <v-btn color="success" @click="$root.fn_refreshWebApp">text</v-btn> -->
                </v-card>

                <!-- <v-card class="ma-4">
                    <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/GCAmercasResortMap_400x220.png"></v-img>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <a type="maplink" class="pr-2" href="/assets/demotrip/resort_map_8.pdf" target="_blank">View Map</a>
                    </v-card-actions>
                </v-card> -->

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <accordians :content="accordianContent" v-if="accordianContent"></accordians>
            </template>
            
        </div>

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('hotel')">Refresh Now</v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import overview from './sub_components/overview'
import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        'accordians':accordians
    },
    methods:{
        fn_updateAvailable: function(dataObj){
            let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
            if(updateObj.pageName==='hotel'){
                if(this.overviewContent || this.accordianContent){
                    this.snackbarMsg = true;
                }
                else{
                    this.fn_getData('hotel');
                }
            }
        }
    },
    mounted(){
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getData('hotel');
    }
}
</script>