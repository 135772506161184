<template>
    <div>

        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Resorts</v-toolbar-title>
        </v-toolbar>
        <div>
            <template>
                
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../../assets/EMEA_ResortBanner_420x200_2023.png"></v-img>
                <div class="more-tiles-wrapper mr-4 ml-4 mt-6">
                    
                    <v-layout class="tile-section" row wrap ma-0>
                        <v-flex xs6 sm6 md6 mt-4 v-for="(item, index) in resortages" :key="index">
                            <v-card flat class="tile-wrapper pa-0 mr-2" :class="(index+1)%2 !== 0 ? 'pr-1' : 'pl-1'" @click="fn_gotoInfoPage(item.PageName)">
                                <v-img :lazy-src="$root.imagePlaceholder" height="120" :src="item.ImageName"></v-img>
                                <!-- <img :src="item.ImageName" alt=""> -->
                                <div class="tile-title">{{item.Title}}</div>
                            </v-card>
                        </v-flex>   
                    </v-layout>
                </div>
                
            </template>
        </div>
        
    </div>
</template>

<script>
    export default{
        props:['content', 'readMore', 'addToCalendar'],
        data(){
            return{
                colorClasses:['light-blue', 'light-pink', 'light-purple', 'light-green'],
                resortages:[
                    {'Title':'InterContinental Ljubljana', 'Path':'resort', 'PageName':'hotel1', 'ImageName':require('../../assets/EMEA_Hotel_IC_200x200.png')},
                    {'Title':'Grand Plaza Hotel', 'Path':'resort', 'PageName':'hotel2', 'ImageName':require('../../assets/EMEA_Hotel_GranPlaza_200x200.png')},
                ]
            }
        },

        methods:{
            fn_gotoInfoPage: function(pagename){
                this.$router.push({name:'emearesort', params:{pageName:pagename}});
            },
        },

        mounted(){
            //window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
            //this.fn_getData('hotel');
        }
    }
</script>