<template>
  <div>
      <!-- <v-alert :color="$root.themeColor" style="border-radius:0;position:sticky;top:0;z-index:1" dense type="info" :value="true">
          Choose attendee to chat with
      </v-alert> -->
      <v-toolbar :color="$root.themeColor" flat style="color:#fff" extended extension-height="62">
          <v-toolbar-title>Attendees</v-toolbar-title>
          <!-- <v-spacer v-if="$root.Envirenment != 3"></v-spacer>
          <div class="pr-4 pt-0 include_guest_btn"><v-switch hide-details @change="fn_isShowGuest" v-model="IsShowGuest" label="Include Guest"></v-switch></div> -->
          <v-spacer></v-spacer>
          <!-- <v-badge :content="$root.unreadMessageCount" :value="$root.unreadMessageCount" color="#f69220" overlap>
                <v-icon color="white" @click="$root.fn_openChatView">chat</v-icon>
          </v-badge> -->
          <v-text-field dense grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
      </v-toolbar>

      <div>

        <template v-if="!error">
          <v-list class="pa-0" three-line>
            <template v-for="(item, index) in winners">
              <v-list-item :key="index" class="attendeelist_customformatting">

                <v-list-item-avatar @click="fn_gotoChatPage(item.AttendeeID)" class="grey lighten-3">
                    <!-- {{fn_abbreviatedName(item.WinnerName)}} -->
                    <template v-if="item.ImageUrl"><v-img alt="winnerimage" :src="item.ImageUrl" lazy-src="../../../assets/person.svg"></v-img></template>
                    <template v-else>{{fn_abbreviatedName(item.WinnerName)}}</template>
                    
                </v-list-item-avatar>

                <v-list-item-content @click="fn_gotoChatPage(item.AttendeeID)">
                    <v-list-item-title v-text="item.WinnerName"></v-list-item-title>
                    <v-list-item-subtitle v-if="item.AttendeeType == 2 && item.PrimaryAttendeeName">Guest of {{item.PrimaryAttendeeName}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.JobTitle">{{item.JobTitle}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.AreaName">{{item.AreaName}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.Subsidiary && item.AreaName != item.Subsidiary">{{item.Subsidiary}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon v-if="item.CanChat" style="margin: auto;" @click="$root.fn_openChatView('instantchat', item.AttendeeID)">
                    <v-icon>mdi-message-outline</v-icon>
                </v-list-item-icon>

                <!-- <v-list-item-icon>
                <v-icon>
                    mdi-message-outline
                </v-icon>
                </v-list-item-icon> -->
                </v-list-item>

                <v-divider inset :key="index+'divider'" v-if="index != winners.length - 1"></v-divider>
            </template>
              

              <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results" class="pa-4 light-opacity-color">No attendee found!</div>
              </infinite-loading>
          </v-list>
        </template>

        <template v-else>
          <div style="text-align:center;" class="pa-4">
              <p>Something went wrong. Please try again.</p>
              <br>
              <v-btn outlined @click="fn_debounceSearch"><v-icon left>refresh</v-icon> Retry</v-btn>
          </div>
        </template>
      </div>

      <dialog-attendee-info ref="ref_dialogAttendeeInfo"></dialog-attendee-info>

      
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
import dialogAttendeeInfo from '../winner_attendee_profile'
//import { CometChat } from "@cometchat-pro/chat";
var controller = null;

// const controller = new AbortController();

  export default {
    data: () => ({
      winners:[],
      infiniteId: +new Date(),
      pageOffset:0,
      searchValue:'',
      timeoutid:null,
      error:false,
      IsShowGuest: true,
      // unreadMessageCount:0,
    }),
    components: {
      InfiniteLoading,
      'dialog-attendee-info':dialogAttendeeInfo
    },
    methods:{
      fn_debounceSearch: function(){
        console.log('debounce');
        if (this.timeoutid != null){
          clearTimeout(this.timeoutid);
        }

        this.timeoutid = setTimeout(() => { 
          this.error = false;
          this.pageOffset = 0;
          this.winners = [];
          this.infiniteId = +new Date();
        },500);
      },

      infiniteHandler($state) { 
        this.error = false;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
              this.error = true; 
              this.$root.fn_showToast('exception');
            }
            else{
                if(controller){
                  controller.abort();
                  console.log('Fetch aborted');
                }
                
                controller = new AbortController();

                var headersObj = {
                  headers: { 
                        'Authorization': 'bearer ' +  token,
                      }
                  };

                let postObj = {}
                postObj.keywords = this.searchValue;
                postObj.IsGuestSearchEnable = this.IsShowGuest;

                console.log(JSON.stringify(postObj));

                axios.post(this.$root.baseUrl + "/api/mobileapp/getwinnerattendees?offset="+this.pageOffset+"&limit=30", postObj, headersObj, {timeout:30000}, {signal: controller.signal})
                .then(response => {
                    console.log(response);
                    if(response.data.count > this.winners.length){
                      for(let i=0; i<response.data.attendeeWinners.length; i++){
                          this.winners.push(response.data.attendeeWinners[i]);
                      }
                      $state.loaded();
                    }
                    else{
                      $state.complete();
                    }
                    controller = null;
                    this.fn_photoAuthThumbnail();
                    this.pageOffset = this.winners.length;
                }).catch(e => {
                    console.log(e);
                    if(e.response.data == "Token Expired"){
                      this.fn_debounceSearch();
                    }
                    else{
                      let toastMsg = e && e.message ? e.message : 'exception';
                      this.$root.fn_showToast(toastMsg);
                      this.error = true;
                    }
                })
            }
        })
        
      },

        fn_gotoChatPage: function(attendeeId){
          this.$refs['ref_dialogAttendeeInfo'].fn_getAttendeeInfo(attendeeId);
          // this.$root.fn_postClickMetrics('Attendee', item.WinnerName, item.AttendeeID);
            // this.$router.push({path:'/gcasia/attendee/'+attendeeId});
        },

        fn_abbreviatedName: function(str){
            if(!str){
              return ''
            }
            //let words = str.trim().split(' ');
            //words = words.replace(/ +(?= )/g,'');
            let words = str.trim().replace(/ +(?= )/g,'').split(' ');

            if(words.length > 1){
                return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
            }
            else{
                return words[0][0].toUpperCase();
            }
        },

        fn_isShowGuest: function(){
          this.fn_debounceSearch();
        },

        async fn_photoAuthThumbnailOld() {

            // this.loading = true;
            // this.error = false;
            let self = this;

            
            for (let i = 0; i < this.winners.length; i++) {
              if(self.winners[i].ImageUrl && !self.winners[i].ImageUrl.startsWith('data:image/')){
                try {
                    const token = await new Promise((resolve, reject) => {
                        this.$root.fn_getToken((token) => {
                            if (token === 'error') {
                                reject('Token error');
                            } else {
                                resolve(token);
                            }
                        });
                    });

                    console.log(token + "Token Called");

                    const headersObj = {
                        headers: { 
                            'Authorization': 'Bearer ' + token,
                        }
                    };

                    
                    console.log(self.$root.baseUrl);
                    const response = await axios.get(self.$root.baseUrl + '/' + self.winners[i].ImageUrl, headersObj, {timeout: 30000});
                    
                    // Update album item with the retrieved data
                    console.log(response.data);
                    self.winners[i].ImageUrl = 'data:image/png;base64,' + response.data;
                    
                    console.log(self.winners[i].ImageUrl);
                    self.$forceUpdate();
                } catch (e) {
                    self.error = true;
                    console.log(e);
                    // let toastMsg = e && e.message ? e.message : 'exception';
                    // self.$root.fn_showToast(toastMsg);
                    break; // Stop processing on error
                }
              }
            }

            this.loading = false;
            this.error = false;
        },

        async fn_photoAuthThumbnail() {
          let self = this;
          // this.loading = true;
          // this.error = false;

          const batchSize = 10; // Size of each batch

          // Filter winners to get those that have an ImageUrl and are not already base64
          const winnersWithImages = self.winners.filter(winner => winner.ImageUrl && !winner.ImageUrl.startsWith('data:image/'));

          // If there are no winners with valid ImageUrl, return early
          if (winnersWithImages.length === 0) {
              this.loading = false;
              return;
          }

          // Function to process a batch of winners
          const processBatch = async (batch) => {
              try {
                  // Prepare the list of AttendeeIds and ImageUrls to send in the POST request
                  const imagePayload = batch.map(winner => ({
                      AttendeeID: winner.AttendeeID,
                      PhotoUrl: winner.ImageUrl
                  }));

                  const token = await new Promise((resolve, reject) => {
                      this.$root.fn_getToken((token) => {
                          if (token === 'error') {
                              reject('Token error');
                          } else {
                              resolve(token);
                          }
                      });
                  });

                  console.log(token + " Token Called");

                  const headersObj = {
                      headers: { 
                          'Authorization': 'Bearer ' + token,
                      }
                  };

                  console.log(self.$root.baseUrl);

                  // Send the POST request with the array of AttendeeId and ImageUrl pairs
                  const response = await axios.post(
                      self.$root.baseUrl + '/api/mobileapp/Document/Download/Url/list', // Change to your POST endpoint
                      imagePayload,          // Send array of AttendeeId and ImageUrl pairs
                      headersObj,
                      { timeout: 30000 }
                  );

                  console.log(response.data);

                  // Update winners' ImageUrl based on the response, matching AttendeeId
                  response.data.forEach(respItem => {
                      const matchedWinner = self.winners.find(winner => winner.AttendeeID === respItem.AttendeeID);
                      if (matchedWinner) {
                          matchedWinner.ImageUrl = 'data:image/png;base64,' + respItem.Base64;
                          console.log(matchedWinner.ImageUrl);
                      }
                  });

                  self.$forceUpdate();
              } catch (e) {
                  self.error = true;
                  console.log(e);
                  throw e; // Stop further processing on error
              }
          };

          try {
              if (winnersWithImages.length <= 10) {
                  // If the number of items with ImageUrl is less than or equal to 10, process them in one call
                  await processBatch(winnersWithImages);
              } else {
                  // Otherwise, split them into batches of 10
                  for (let i = 0; i < winnersWithImages.length; i += batchSize) {
                      const batch = winnersWithImages.slice(i, i + batchSize);
                      await processBatch(batch); // Process each batch sequentially
                  }
              }
          } catch (error) {
              console.log('Batch processing failed', error);
          } finally {
              this.loading = false;
              this.error = false;
          }
        }
    },

    mounted(){
      this.$root.fn_postClickMetrics('Attendee', 'Default', null);
      // this.$root.fn_getUnreadMessageCount();
      // if(localStorage.getItem("pn_registered_"+this.$root.tripSiteId)){
      //     CometChat.getUnreadMessageCount().then(array => {
      //       console.log("Message count fetched", array);
      //       for(let group in array){
      //           for(let key in array[group]){
      //               this.unreadMessageCount += array[group][key];
      //           }
      //       }
      //     }, error => {
      //       console.log("Error in getting message count", error);
      //     });
      // }
      
    },
  }
</script>