<template>
  <div>
    <v-dialog v-model="attendeeDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
      <!-- <v-alert :color="$root.themeColor" style="border-radius:0;position:sticky;top:0;z-index:1" dense type="info" :value="true">
          Choose attendee to chat with
      </v-alert> -->
      <v-card>
        <v-card-title class="title pb-0 pl-0 pr-0 pt-0" style="flex-wrap:nowrap;word-break: break-word;">
          <v-toolbar class="toolbar_custom_css" flat extended extension-height="62">
            <v-btn icon class="mr-4" @click="attendeeDialog=false">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Likes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field dense grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" slot="extension"></v-text-field>
            
        </v-toolbar>
      </v-card-title>
      <!-- <v-toolbar flat style="color:#fff" extended extension-height="62">
          <v-btn icon class="mr-4" @click="attendeeDialog=false">
              <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
          </v-btn>
          <v-toolbar-title>Tag People</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field dense grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
          
      </v-toolbar> -->

      <v-card-text class="scrollable-content pt-4 pl-0 pr-0">
        <div v-if="Loading" class="text-center pb-2">
          <v-progress-circular width="2" indeterminate color="grey"></v-progress-circular>
        </div>

        <template v-if="!error">
          <v-list class="pa-0" three-line>
            <template v-for="(item, index) in FilteredList">
              <v-list-item :key="index" class="zindex2 attendeelist_customformatting">

                <v-list-item-avatar class="grey lighten-3" style="align-self: center;">
                    <!-- {{fn_abbreviatedName(item.WinnerName)}} -->
                    <template v-if="item.ProfilePicture"><v-img alt="winnerimage" :src="item.ProfilePicture" lazy-src="../../../assets/person.svg"></v-img></template>
                    <template v-else>{{fn_abbreviatedName(item.UserName ? item.UserName : item.UserAlias)}}</template>
                    
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-text="item.UserName ? item.UserName : item.UserAlias"></v-list-item-title>
                    <!-- <v-list-item-subtitle v-if="item.AttendeeType == 2 && item.PrimaryAttendeeName">Guest of {{item.PrimaryAttendeeName}}</v-list-item-subtitle> -->
                    <!-- <v-list-item-subtitle v-if="item.JobTitle">{{item.JobTitle}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.AreaName">{{item.AreaName}}</v-list-item-subtitle> -->
                    <!-- <v-list-item-subtitle v-if="item.Subsidiary && item.AreaName != item.Subsidiary">{{item.Subsidiary}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.RegistrationFormType != 1 && item.Organization != null">{{item.Organization}}</v-list-item-subtitle> -->
                    
                </v-list-item-content>

                <!-- <v-list-item-action>
                  <v-checkbox v-model="taggedList" :value="item.AttendeeID"></v-checkbox>
                </v-list-item-action> -->

                <!-- <v-list-item-icon v-if="item.CanChat" style="margin: auto;" @click="$root.fn_openChatView('instantchat', item.AttendeeID)">
                    <v-icon>mdi-message-outline</v-icon>
                  </v-list-item-icon> -->
                </v-list-item>

                <v-divider inset :key="index+'divider'" v-if="index != winners.length - 1"></v-divider>
            </template>
              

              <!-- <infinite-loading ref="attendeeinfiniteLoading" :identifier="infiniteId" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results" class="pa-4 light-opacity-color">No attendee found!</div>
              </infinite-loading> -->
          </v-list>
        </template>

        <template v-else>
          <div style="text-align:center;" class="pa-4">
              <p>Something went wrong. Please try again.</p>
              <br>
              <v-btn outlined @click="fn_debounceSearch"><v-icon left>refresh</v-icon> Retry</v-btn>
          </div>
        </template>
      </v-card-text>
      
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
//import dialogAttendeeInfo from '../winner_attendee_profile'
// import { CometChat } from "@cometchat-pro/chat";
// var controller = null;

// const controller = new AbortController();

  export default {
    props:['fn_updateTags'],
    data: () => ({
      winners:[],
      infiniteId: +new Date(),
      pageOffset:0,
      searchValue:'',
      timeoutid:null,
      error:false,
      unreadMessageCount:0,
      IsShowGuest: true,
      attendeeDialog: false,
      taggedList: [],
      PostID: null,
      Loading: false
    }),
    components: {
      // InfiniteLoading,
      // 'dialog-attendee-info':dialogAttendeeInfo
    },
    methods:{

      fn_showDialog(postid){
        this.pageOffset = 0;
        this.searchValue = '';
        this.winners = [];
        // this.taggedList = JSON.parse(JSON.stringify(tags));
        this.attendeeDialog = true;
        // this.$refs.attendeeinfiniteLoading.stateChanger.reset(); // Reset infinite loading state
        this.PostID = postid;
        this.Loading = false;
        this.getListData();
      },

      fn_debounceSearch: function(){
        console.log('debounce');
        if (this.timeoutid != null){
          clearTimeout(this.timeoutid);
        }

        this.timeoutid = setTimeout(() => { 
          this.error = false;
          this.pageOffset = 0;
          this.winners = [];
          this.infiniteId = +new Date();
        },500);
      },

      getListData() { 
        this.error = false;
        this.Loading = true;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
              this.error = true; 
              this.$root.fn_showToast('exception');
            }
            else{
                // if(controller){
                //   controller.abort();
                //   console.log('Fetch aborted');
                // }
                
                // controller = new AbortController();

                var headersObj = {
                  headers: { 
                        'Authorization': 'bearer ' +  token,
                      }
                  };

                // let postObj = {}
                // postObj.keywords = this.searchValue;
                // postObj.IsGuestSearchEnable = this.IsShowGuest;

                // console.log(JSON.stringify(postObj));

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/post/like/" + this.PostID +"/list", headersObj, {timeout:30000})
                .then(response => {
                    console.log(response);
                    this.winners = response.data;
                    this.Loading = false;
                    // if(response.data.count > this.winners.length){
                    //   for(let i=0; i<response.data.attendeeWinners.length; i++){
                    //       this.winners.push(response.data.attendeeWinners[i]);
                    //   }
                    //   $state.loaded();
                    // }
                    // else{
                    //   $state.complete();
                    // }
                    // controller = null;
                    this.fn_photoAuthThumbnail();
                    // this.pageOffset = this.winners.length;
                }).catch(e => {
                    console.log(e);
                    this.Loading = false;
                    if(e.response.data == "Token Expired"){
                      this.fn_debounceSearch();
                    }
                    else{
                      let toastMsg = e && e.message ? e.message : 'exception';
                      this.$root.fn_showToast(toastMsg);
                      this.error = true;
                    }
                })
            }
        })
        
      },

        fn_gotoChatPage: function(attendeeId){
          this.$refs['ref_dialogAttendeeInfo'].fn_getAttendeeInfo(attendeeId);
            // this.$router.push({path:'/platinumclub/attendee/'+attendeeId});
        },

        fn_abbreviatedName: function(str){
            if(!str){
              return ''
            }
            //let words = str.trim().split(' ');
            //words = words.replace(/ +(?= )/g,'');
            let words = str.trim().replace(/ +(?= )/g,'').split(' ');

            if(words.length > 1){
                return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
            }
            else{
                return words[0][0].toUpperCase();
            }
        },

        async fn_photoAuthThumbnail() {

            // this.loading = true;
            // this.error = false;
            let self = this;

            
            for (let i = 0; i < this.winners.length; i++) {
              if(self.winners[i].ProfilePicture && !self.winners[i].ProfilePicture.startsWith('data:image/')){
                try {
                    const token = await new Promise((resolve, reject) => {
                        this.$root.fn_getToken((token) => {
                            if (token === 'error') {
                                reject('Token error');
                            } else {
                                resolve(token);
                            }
                        });
                    });

                    console.log(token + "Token Called");

                    const headersObj = {
                        headers: { 
                            'Authorization': 'Bearer ' + token,
                        }
                    };

                    
                    console.log(self.$root.baseUrl);
                    const response = await axios.get(self.$root.baseUrl + "/" + self.winners[i].ProfilePicture, headersObj, {timeout: 30000});
                    
                    // Update album item with the retrieved data
                    console.log(response.data);
                    self.winners[i].ProfilePicture = 'data:image/png;base64,' + response.data;
                    
                    console.log(self.winners[i].ProfilePicture);
                    self.$forceUpdate();
                } catch (e) {
                    self.error = true;
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    self.$root.fn_showToast(toastMsg);
                    break; // Stop processing on error
                }
              }
            }

            this.loading = false;
            this.error = false;
        }

    },

    mounted(){
      this.$root.fn_postClickMetrics('Attendee', 'Default', null);
      // if(localStorage.getItem("pn_registered_"+this.$root.tripSiteId)){
      //     CometChat.getUnreadMessageCount().then(array => {
      //       console.log("Message count fetched", array);
      //       for(let group in array){
      //           for(let key in array[group]){
      //               this.unreadMessageCount += array[group][key];
      //           }
      //       }
      //     }, error => {
      //       console.log("Error in getting message count", error);
      //     });
      //   }
    },

    computed:{
      FilteredList() {
        // Ensure searchValue and winners are properly initialized
          if (!this.searchValue || !this.winners || this.searchValue.trim() === '') {
            return this.winners;
          }

          // Filter the winners based on the search value
          return this.winners.filter(item => {
            return item.UserName && item.UserName.toLowerCase().includes(this.searchValue.toLowerCase().trim());
          });
      },
    }
  }
</script>

<style>
.toolbar_custom_css .v-toolbar__extension{
  background-color: rgb(96, 159, 151) !important;
}
</style>