<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="168" :lazy-src="$root.imagePlaceholder" src="../assets/EMEA_HeadShotBanner_420x200.png"></v-img>

                <template v-if="headshotContent && headshotContent != null">

                    <overview :content="pageOverview.ComponentText" v-if="pageOverview && !(pageOverview.ComponentText == null || pageOverview.ComponentText == '')"></overview>
                    <!-- <overview :content="overviewContent" v-if="overviewContent"></overview>-->

                    <div class="ma-4" :class="{'mt-0' : pageOverview && !(pageOverview.ComponentText == null || pageOverview.ComponentText == '')}" v-if="headshotContent && headshotContent != null">
                        <v-card flat>
                            <div v-if="HeadShotSlotStatus && headshotContent.AttendeeThumbnailURL == null">
                                <div class="pb-3"><strong>Slot Date: </strong> {{headshotContent.SlotTime | moment("ddd, MMM Do YYYY")}}</div>
                                <div class="pb-0"><strong>Slot Time: </strong> {{headshotContent.SlotTime | moment("hh:mm A")}}</div>
                                <div class="text-center mb-3">
                                    <v-btn class="mt-4" outlined @click="fn_editHeadShot"><v-icon left>mdi-swap-horizontal</v-icon> Change your Slot</v-btn>
                                </div>
                            </div>
                            <v-card-actions v-if="headshotContent.AttendeeThumbnailURL != null" class="pa-0 pt-4 headshot_image_container">
                                <v-img height="250" :lazy-src="$root.imagePlaceholder" :src="$root.getPhotoURL(headshotContent.AttendeeThumbnailURL)"></v-img>
                                <v-spacer></v-spacer>

                                <v-btn @click="fn_getImage" icon outlined>
                                    <v-icon>mdi-download</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                </template>
                

            </template>

            <!-- <template v-if="error">
                <div style="text-align:center;" class="pa-4">
                    <p>Something went wrong. Please try again.</p>
                    <br>
                    <v-btn outlined @click="fn_getDetails"><v-icon left>refresh</v-icon> Retry</v-btn>
                </div>
            </template> -->

            <template v-if="!loading && headshotContent == null && !error">
                <div class="placeholder-wrapper content-page-placeholder pa-4">
                    <div center-align>
                        <div>
                            <v-icon class="placeholder-icon">mdi-target-account</v-icon>
                        </div>
                        <!-- <p class="pt-4 light-opacity-color">No head shot slot selected</p> -->
                        <v-btn class="mt-4" outlined @click="fn_selectSlot"><v-icon left>mdi-image-size-select-large</v-icon> Pick your Slot</v-btn>
                    </div>
                </div>
            </template>
            
        </div>

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getDetails">Refresh Now</v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="SlotSelectionDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card>
                <v-card-title :style="{ backgroundColor: $root.themeColor}" :color="$root.themeColor"  class="title pb-4 pl-4 white--text" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn :color="$root.themeColor" icon class="mr-4 white--text" @click="SlotSelectionDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    Head Slot Selection
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content pt-4">
                    <template v-if="loading">
                        <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <div>
                            <div class="subtitle-1 pt-2">Select Date<span class="red--text">*</span></div>
                            <v-radio-group class="mt-2" row v-model="slotDate" hide-details>
                                <v-radio class="mb-2" v-for="(item, index) in SlotList" :key="index" :label="item.SlotDate | moment('ddd, MMM Do YYYY')" :value="item.SlotDate"></v-radio>
                            </v-radio-group>
                        </div>
                        <div v-if="slotDate != null && slotDate != ''">
                            <div class="subtitle-1 pt-2">Select Time<span class="red--text">*</span></div>
                            <v-radio-group class="mt-2" v-model="MobileHeadShotSlotID">
                                <v-radio class="mb-2" v-for="(item, index) in SlotTimeList" :key="index" :label="item.SlotTime | moment('hh:mm A')" :value="item.MobileHeadShotSlotID"></v-radio>
                            </v-radio-group>
                        </div>
                        <!-- <v-autocomplete autocomplete="off" :items="SlotList" v-model="MobileHeadShotSlotID" item-value="MobileHeadShotSlotID" item-text="SlotTime">
                            <template v-slot:selection="data"> 
                                {{ data.item.SlotTime | moment('MMMM DD, YYYY hh:mm:ss A') }}
                            </template>
                            <template v-slot:item="data">
                                <template>
                                <v-list-item-content>{{data.item.SlotTime | moment('MMMM DD, YYYY hh:mm:ss A')}}</v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete> -->
                        <div>
                            <v-btn :disabled="MobileHeadShotSlotID == null" :color="$root.themeColor" tile class="btn-white mt-1 pl-3 pr-3 white--text confirmation_btn" @click="fn_SaveHeadShotSelection"><v-icon>save</v-icon> Save</v-btn>
                        </div>
                    </template>
                    
                </v-card-text>
                <v-divider></v-divider>
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="indigo" tile class="btn-white mt-0 pl-3 pr-3 white--text confirmation_btn" @click="fn_SaveHeadShotSelection"><v-icon>save</v-icon> Save</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>

        <v-dialog v-model="downloading" persistent width="300">
            <v-card>
                <v-card-text class="pt-4">Downloading...
                    <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
// import accordians from './sub_components/accordians'
import overview from './sub_components/overview'
import {pageMixin} from './info_page_mixin'

import axios from 'axios';

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview
    },
    data() {
        return {
            error:false,
            headshotContent:null,
            SlotSelectionDialog: false,
            SlotList: [],
            MobileHeadShotSlotID: null,
            imageName: null,
            imageUrl: null,
            downloading:false,
            pageOverview: null,
            slotDate: null,
            slotListItems: [],
            HeadShotSlotStatus: null
        }
    },
    methods:{
        fn_updateAvailable: function(){
            console.log('update available');
        },

        fn_editHeadShot: function(){
            this.slotDate = this.headshotContent.SlotDate;
            this.MobileHeadShotSlotID = this.headshotContent.MobileHeadShotSlotID;
            this.SlotSelectionDialog = true;
            this.fn_getSlotList();
        },

        fn_getDetails: function(){

            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/headshots/slot/booked/view", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.snackbarMsg = false;
                        let headshotPageInfo = response.data && response.data.MobilePage != null ? response.data.MobilePage : null;
                        this.headshotContent = response.data && response.data.AttendeeBookingSlot != null ? response.data.AttendeeBookingSlot : null;
                        this.HeadShotSlotStatus = response.data && response.data.HeadShotSlotStatus != null ? response.data.HeadShotSlotStatus : null;
                        if(headshotPageInfo){
                            this.pageName = headshotPageInfo.PageTitle;
                            let overviewInfo = JSON.parse(headshotPageInfo.PageComponents) 
                            this.pageOverview = overviewInfo && overviewInfo.length ? overviewInfo[0] : null;
                        }
                        if(this.headshotConten){
                            this.imageName = this.headshotContent.PhotoName;
                            this.imageUrl = this.headshotContent.AttendeePhotoURL;
                        }
                        
                    }).catch(e => {
                        this.loading = false;
                        // this.error = true;
                        console.log(e);
                        // let toastMsg = e && e.message ? e.message : 'exception';
                        // this.$root.fn_showToast(toastMsg);
                    })
                }
            })

        },

        fn_SaveHeadShotSelection: function(){

            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/headshots/" + this.MobileHeadShotSlotID + "/available/slot", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.snackbarMsg = false;
                        this.SlotSelectionDialog = false;
                        this.fn_getDetails();
                        
                    }).catch(e => {
                        this.loading = false;
                        // this.error = true;
                        console.log(e);
                        // let toastMsg = e && e.message ? e.message : 'exception';
                        // this.$root.fn_showToast(toastMsg);
                    })
                }
            })
        },

        fn_selectSlot: function(){
            this.slotDate = null;
            this.MobileHeadShotSlotID = null;
            this.SlotSelectionDialog = true;
            this.fn_getSlotList();
        },

        fn_getSlotList: function(){

            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/headshots/available/slot", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.snackbarMsg = false;
                        this.SlotList = response.data && response.data.SlotDateList && response.data.SlotDateList.length ? response.data.SlotDateList : [];
                    }).catch(e => {
                        this.loading = false;
                        console.log(e);
                    })
                }
            })

        },

        fn_getImage: function(){
            if(this.$root.platform === 'ios'){
                this.fn_openImage();
            }
            else{
                this.$root.fn_checkStoragePermission(response=>{
                    if(JSON.parse(response)){
                        this.fn_openImage();
                    }
                    else{
                        this.$root.fn_showToast('Please allow storage permission');
                    }
                })
            }
        },

        fn_openImage: function(){
            let self = this;
            this.getData('GetImage', this.imageName, 
            {
                onSuccess: function(response){
                    console.log(response);
                    let isDownloaded = JSON.parse(response);
                    if(isDownloaded){
                        self.fn_saveImage();
                    }
                    else{
                        self.fn_downloadImage();
                    }
                },
                onError: function(error){
                    console.log(error);
                    if(error.dataKey && !JSON.parse(error.dataKey)){
                        self.fn_downloadImage();
                    }
                }
            });
        },

        fn_downloadImage: function(){
            let self = this;
            let dataObj = {};
            dataObj.FileName = this.imageName;
            dataObj.FileUrl = this.imageUrl;
            dataObj.DisplayName = this.imageName;
            this.$root.platform === 'android' ? dataObj.IsImageFile = true : '';
            this.downloading = true;
            this.getServerData('DownloadFile', dataObj, {
                onSuccess: function(response){
                    console.log(response);
                    self.downloading = false;
                    self.$root.platform === 'android' ? self.$root.fn_showToast('Added to Photos') : '';
                    self.fn_saveImage();
                }, 
                onError: function(error){
                    console.log(error);
                    self.downloading = false;
                    self.$root.fn_showToast('exception');
                }, 
            });
        },

        fn_saveImage: function(){
            let self = this;
            this.getData('SaveImageToPhotoLibrary', this.imageName, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$root.fn_showToast('Added to Photos');
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },
    },
    mounted(){
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getDetails();
        // this.fn_refresh();
    },

    computed:{
        SlotTimeList: function() {
            let SlotTimeList = []; 
            for (var i in this.SlotList) {
                if (this.SlotList[i].SlotDate == this.slotDate) {
                    return SlotTimeList = this.SlotList[i].SlotTimeList;
                }
            }
            return SlotTimeList
        }
    }
}
</script>