<template>
    <div>

        <div class="hasBottomNav">
            <component :is="currentComponent" :ref="componentRef" :paramsObj="props"></component>
        </div>

        <div class="footer_watermark_image">
            <img src="./assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
        </div>
        <div class="footer_wave_image">
            <img src="./assets/2024/PlatinumClub_BottomWave_1120x112.png">
        </div>
        <v-bottom-navigation id="bottomnav" v-model="value" @change="fn_tabChange" fixed color="black" grow>
            <!-- <v-img alt="winnerimage" src="/assets/PlatinumClub2023_FooterImage_420x200.png"></v-img> -->
            <v-btn style="background-color: #5cbfbf !important;">
                <span>Explore</span>
                <v-icon>explore</v-icon>
            </v-btn>

            <v-btn style="background-color: #5cbfbf !important;">
                <span>My Agenda</span>
                <v-icon>timer</v-icon>
            </v-btn>

            <v-btn class="pl-6 pr-3" style="background-color: #5cbfbf !important;">
                <span>Attendees</span>
                <v-icon>stars</v-icon>
            </v-btn>

            <!-- <v-btn v-if="$root.Envirenment != 3" class="pl-6" style="background-color: #609f97 !important;">
                <span>Social Feed</span>
                <v-icon>mdi-rss</v-icon>
            </v-btn> -->

            <!-- <v-badge class="ml-0 pl-2" style="min-width: 80px;background-color: #5cbfbf; top: 0px; text-align: center; padding-top: 9px !important" :content="unreadMessageCount" :value="unreadMessageCount" color="#5cbfbf" overlap>
                <v-icon color="white" @click="$root.fn_openChatView">chat</v-icon>
                <span class="white--text chat_footer">Chat</span>
            </v-badge> -->

            <!-- <v-btn class="pr-2" style="background-color: #5cbfbf !important;">
                <span>Profile</span>
                <v-icon>person</v-icon>
            </v-btn> -->

            <v-btn @click="fn_openChat" class="pr-2" style="background-color: #5cbfbf !important;">
                <span>Chat</span>
                <v-icon>chat</v-icon>
                <span class="v-badge__badge" style="inset: auto auto calc(100% - 21px) calc(100% - 28px); background-color: #f69220; border-color:#f69220;" v-if="$root.unreadMessageCount && $root.unreadMessageCount != 0">{{ $root.unreadMessageCount }}</span>
            </v-btn>

            <v-btn class="pl-2" @click="fn_gotoInfoPage('survey')" style="background-color: #5cbfbf !important;">
                <span>Survey</span>
                <v-icon>mdi-star</v-icon>
            </v-btn>
        </v-bottom-navigation>
        

        <v-dialog v-model="contentDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card v-if="currentCardItem">
                <v-card-title class="title pb-4 pl-4" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn icon class="mr-4" @click="contentDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    {{currentCardItem.Title}}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content pt-4">
                    <div v-html="currentCardItem.Overview"></div>
                    <div class="pt-4" v-html="currentCardItem.ReadMoreContent.Details"></div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import explorePage from './home_tabs/explore'
import schedulePage from './home_tabs/schedule'
import winnersPage from './home_tabs/winners'
// import profilePage from './home_tabs/profile'
// import surveyPage from './info_pages/survey'
// import scialFeedPage from './home_tabs/socialfeed'

  export default {
    data: () => ({ 
        value: 0,
        currentComponent: null,
        componentRef:'explore',
        props:{agendaDay:null},
        currentCardItem:null,
        contentDialog:false,
        unreadMessageCount:0
    }),
    methods:{
        fn_tabChange: function(index, agendaDay){
            console.log(index);
            if(index===0){
                this.componentRef = 'explore';
                this.currentComponent = explorePage;
            }
            else if(index===1){
                this.componentRef = 'schedule';
                this.currentComponent = schedulePage;
            }
            else if(index===2){
                this.componentRef = 'winners';
                this.currentComponent = winnersPage;
            }
            // else if(index===3){
            //     this.componentRef = 'scialfeed';
            //     this.currentComponent = scialFeedPage;
            // }
            // else if(index===3){
            //     this.componentRef = 'profile';
            //     this.currentComponent = profilePage;
            // }
            // else{
            //     this.componentRef = 'survey';
            //     this.currentComponent = surveyPage;
            // }

            if(agendaDay){
                this.value = index;
                this.props.agendaDay = agendaDay;
            }
            else{
                this.props.agendaDay = null;
            }
        },

        fn_readMore: function(item){
            if(!item.ReadMoreContent){
                return
            }

            let readMoreContent = JSON.parse(item.ReadMoreContent);
            if(readMoreContent.ReadMoreType == 1){
                if(this.value == 1 && this.$refs['schedule']?.activeTabIndex != undefined){
                    localStorage.setItem('last_schedule_active_tab_'+this.$root.tripSiteId, this.$refs['schedule']?.activeTabIndex);
                }
                this.fn_openInternalPage(readMoreContent.Details);
            }
            else if(readMoreContent.ReadMoreType == 2){
                this.fn_openExternalPage(readMoreContent.Details);
            }
            else if(readMoreContent.ReadMoreType == 3){
                this.fn_openMoreContent(item);
                
            }
        },

        fn_openInternalPage: function(details){
            if(details.PageName === 'Buy-In Packages')
                this.fn_gotoInfoPage('buyininformation');
            else if(details.PageName === 'Hotel')
                this.fn_gotoInfoPage('resort');
            else if(details.PageName === 'My Activities')
                this.fn_gotoInfoPage('activities', details);
            else if(details.PageName === 'Activities')
                this.fn_gotoInfoPage('allactivities');
            else if(details.PageName === 'Travel')
                this.fn_gotoInfoPage('travel');
            else if(details.PageName === 'FAQ')
                this.fn_gotoInfoPage('faq');
            else if(details.PageName === 'Emergency')
                this.fn_gotoInfoPage('emergency');
            else if(details.PageName === 'Restaurant Reservation')
                this.fn_gotoInfoPage('restaurantreservation');
            else if(details.PageName === 'Info Booth')
                this.fn_gotoInfoPage('infobooth');
            else if(details.PageName === 'Evening Events')
                this.fn_gotoInfoPage('eveningevents');
            else if(details.PageName === 'Safety & Security')
                this.fn_gotoInfoPage('safetyandsecurity');
            else if(details.PageName === 'Gifts')
                this.fn_gotoInfoPage('gifts');
            else if(details.PageName === 'Album')
                this.fn_gotoInfoPage('photoalbum');
            else if(details.PageName === 'Gala Seating')
                this.fn_gotoInfoPage('galaseating');
            else if(details.PageName === 'Departure Info')
                this.fn_gotoInfoPage('departureinfo');
            else if(details.PageName === 'Head Shot')
                this.fn_gotoInfoPage('headshotpc');
            else if(details.PageName === 'Social Feed')
                this.fn_gotoInfoPage('socialfeed');
            else if(details.PageUrl.substring(1, details.PageUrl.lastIndexOf('/')) === 'agenda'){
                let agendaDate = details.PageUrl.substring(details.PageUrl.lastIndexOf('/')+1);
                // let agendaDay = new Date(agendaDate).getDate();
                let agendaDay = this.$moment(agendaDate).date();
                this.fn_tabChange(1, agendaDay);
            }
            else if(details.PageName === 'Agenda'){
                let agendaDay = details.AgendaDate ? new Date(details.AgendaDate).getDate() : new Date().getDate();
                this.fn_tabChange(1, agendaDay);
            }
            else if(details.PageName === 'Key Locations')
                this.fn_gotoInfoPage('keylocations');
            else if(details.PageName === 'Spa Award')
                this.fn_gotoInfoPage('spaaward');
        }, 

        fn_openExternalPage: function(pageUrl){
            this.getData('OpenBrowser', pageUrl, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_openMoreContent: function(cardItem){
            let itemObj = JSON.parse(JSON.stringify(cardItem));
            this.currentCardItem = null;
            this.currentCardItem = itemObj;
            this.currentCardItem.ReadMoreContent = JSON.parse(itemObj.ReadMoreContent);
            this.contentDialog = true;
            this.$root.fn_postClickMetrics('Hero Tiles', cardItem.Title, cardItem.MobileTopHomeTileID);
            setTimeout(() => {
                document.getElementsByClassName('scrollable-content')[0].scrollTop = 0;
            }, 300);
        },

        fn_gotoInfoPage: function(path, details){
            details?.ActivityID ? localStorage.setItem('currentActivityId', details.ActivityID) : '';
            this.$router.push({path:'/platinumclub/'+path});
        },

        fn_closeDialog: function(){
            if(this.contentDialog){
                this.contentDialog = false;
            }
            else if(this.$refs['winners']?.$refs['ref_dialogAttendeeInfo']?.contentDialog){
                this.$refs['winners'].$refs['ref_dialogAttendeeInfo'].contentDialog = false;
            }
            else{
                window.js_globals.fn_onBackPress = this.$root.fn_goBack;
                this.$root.fn_goBack();
            }
        },

        fn_openChat: function(){
            this.$root.fn_openChatView();
            this.$root.fn_postClickMetrics('Chat', 'Default', null);
            setTimeout(() => {
                this.value = 0;
                this.fn_tabChange(0);
                this.$forceUpdate();
            }, 0);
            
            
        }
    },
    mounted: function(){
        window.js_globals.fn_onBackPress = this.fn_closeDialog;
        
        if(localStorage.getItem('lastactivetab')){
            this.value = Number(localStorage.getItem('lastactivetab'));
        }

        // getting notification count and other information
        // this.$root.fn_getNotificationCount();

        // alert(this.value);
        this.fn_tabChange(this.value);

        // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) !== true){
        //     let bottomNav = document.querySelector('#bottomnav');
        //     bottomNav.style.width = '420px';
        //     bottomNav.style.marginLeft = 'calc(50% - 210px)';
        // }

        this.$root.selectedTrip = 'platinumclub'
        this.$root.themeColor = '#5cbfbf';

        this.$root.fn_pageLoadComplete();

        if(localStorage.getItem('n_id')){
            if(localStorage.getItem('p_name')){
                let detailsObj = {};
                detailsObj.PageName = localStorage.getItem('p_name');
                localStorage.removeItem('p_name');
                this.fn_openInternalPage(detailsObj);
            }
            else{
                this.$router.push('/notifications/'+localStorage.getItem('n_id'));
            }

            localStorage.removeItem('n_id');
        }

        let isCalled = false;
        this.$eventHub.$on('GetAttendees', () => {
            if(!isCalled){
                isCalled = true;
                if(this.value == 2){
                    if(this.$refs['winners'] && this.$refs['winners'].$refs['ref_dialogAttendeeInfo'] && this.$refs['winners'].$refs['ref_dialogAttendeeInfo'].contentDialog){
                        this.$refs['winners'].$refs['ref_dialogAttendeeInfo'].contentDialog = false;
                    }
                }
                else{
                    this.value = 2;
                    this.fn_tabChange(2);
                }
            }
            setTimeout(() => {
                isCalled = false;
            }, 2000);
        })
        
    },
    beforeRouteLeave(to, from, next){
        console.log(to);
        console.log(from);
        // alert(this.value);
        localStorage.setItem('lastactivetab', this.value);
        next();
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if(from.name == 'trips' || from.name == 'sendotp'){
                // getting notification count and other information
                //vm.$root.fn_postOpenMetrics('App', null, null)
                console.log(localStorage.getItem('Envirenment'));
                vm.$root.fn_getNotificationCount();
                vm.$root.fn_checkDeviceLogin();
                vm.$root.fn_postOpenMetrics('App', null, null)
            }
        });
    }
  }
</script>