<template>
  <div>
      <!-- <v-alert :color="$root.themeColor" style="border-radius:0;position:sticky;top:0;z-index:1" dense type="info" :value="true">
          Choose attendee to chat with
      </v-alert> -->
      <v-toolbar :color="$root.themeColor" flat style="color:#fff" extended extension-height="62">
          <v-toolbar-title>Winner Attendees</v-toolbar-title>
          <v-text-field dense grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
      </v-toolbar>

      <div>

        <template v-if="!error">
          <v-list class="pa-0" three-line>
            <template v-for="(item, index) in winners">
              <v-list-item :key="index" @click="fn_gotoChatPage(item.AttendeeID)">

                <v-list-item-avatar class="grey lighten-3">
                    <!-- {{fn_abbreviatedName(item.WinnerName)}} -->
                    <template v-if="item.ImageUrl"><v-img alt="winnerimage" :src="item.ImageUrl" lazy-src="../../../assets/person.svg"></v-img></template>
                    <template v-else>{{fn_abbreviatedName(item.WinnerName)}}</template>
                    
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-text="item.WinnerName"></v-list-item-title>
                    <v-list-item-subtitle>{{item.JobTitle}}</v-list-item-subtitle>
                    <!-- <v-list-item-subtitle>{{item.Segment}}</v-list-item-subtitle> -->
                    <v-list-item-subtitle>{{item.AreaName}}</v-list-item-subtitle>
                </v-list-item-content>

                  <!-- <v-list-item-icon>
                      <v-icon>
                          mdi-message-outline
                      </v-icon>
                  </v-list-item-icon> -->
                </v-list-item>

                <v-divider inset :key="index+'divider'" v-if="index != winners.length - 1"></v-divider>
            </template>
              

              <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results" class="pa-4 light-opacity-color">No attendee found!</div>
              </infinite-loading>
          </v-list>
        </template>

        <template v-else>
          <div style="text-align:center;" class="pa-4">
              <p>Something went wrong. Please try again.</p>
              <br>
              <v-btn outlined @click="fn_debounceSearch"><v-icon left>refresh</v-icon> Retry</v-btn>
          </div>
        </template>
      </div>

      
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
var controller = null;

// const controller = new AbortController();

  export default {
    data: () => ({
      winners:[],
      infiniteId: +new Date(),
      pageOffset:0,
      searchValue:'',
      timeoutid:null,
      error:false,
      // winners: [
      //   {
      //     name: 'Bob Brescia',
      //     jobTitle:'ACCOUNT DIRECTOR',
      //     organization:"Global Partner Solutions",
      //     color:'light-pink' 
      //   },
      //   {
      //     name: 'Carlos Oberreuter',
      //     jobTitle:'CLD SLTN ARCHITECT MGR - SECUR',
      //     organization:"Customer Experience & Success",
      //     color:'light-green'
      //   },
      //   {
      //     name: 'Erin Wieland',
      //     jobTitle:'BUSINESS PROGRAM MGR',
      //     organization:"Specialist Sales",
      //     color:'light-blue'
      //   },
      //   {
      //     name: 'Fernando Lopez',
      //     jobTitle:'CLOUD SPECIALIST',
      //     organization:"Customer Experience & Success",
      //     color:'light-purple'
      //   },
      //   {
      //     name: 'Laetitia Bigot',
      //     jobTitle:'CUSTOMER SUCC ACCT MGR MGMT',
      //     organization:"Industry Solutions",
      //     color:'light-pink'
      //   },
      //   {
      //     name: 'Lisa Nguyen',
      //     jobTitle:'ACCOUNT DIRECTOR',
      //     organization:"M&O",
      //     color:'light-green'
      //   },
      //   {
      //     name: 'Mark Kindree',
      //     jobTitle:'CLIENT EXECUTIVE',
      //     organization:"M&O",
      //     color:'light-blue'
      //   },
      //   {
      //     name: 'Matias Court',
      //     jobTitle:'CLIENT EXECUTIVE',
      //     organization:"Specialist Sales",
      //     color:'light-purple'
      //   },
      //   {
      //     name: 'Matthew Morgan',
      //     jobTitle:'BUSINESS PROGRAM MGR',
      //     organization:"M&O",
      //     color:'light-pink'
      //   },
      //   {
      //     name: 'Robert Buonanno',
      //     jobTitle:'CUSTOMER SUCCESS ACCT MANAGER',
      //     organization:"Customer Experience & Success",
      //     color:'light-green'
      //   },
      //   {
      //     name: 'Sarah Mohattane',
      //     jobTitle:'CUSTOMER SUCCESS ACCT MANAGER',
      //     organization:"Enterprise Commercial",
      //     color:'light-blue'
      //   },
      // ],
      previous: [{
        title: 'Travis Howard',
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
      }],
    }),
    components: {
      InfiniteLoading,
    },
    methods:{
      fn_debounceSearch: function(){
        console.log('debounce');
        if (this.timeoutid != null){
          clearTimeout(this.timeoutid);
        }

        this.timeoutid = setTimeout(() => { 
          this.error = false;
          this.pageOffset = 0;
          this.winners = [];
          this.infiniteId = +new Date();
        },500);
      },

      infiniteHandler($state) { 
        this.error = false;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
              this.error = true; 
              this.$root.fn_showToast('exception');
            }
            else{
                if(controller){
                  controller.abort();
                  console.log('Fetch aborted');
                }
                
                controller = new AbortController();

                var headersObj = {
                  headers: { 
                        'Authorization': 'bearer ' +  token,
                      }
                  };

                let postObj = {}
                postObj.keywords = this.searchValue;

                console.log(JSON.stringify(postObj));

                axios.post(this.$root.baseUrl + "/api/mobileapp/getwinnerattendees?offset="+this.pageOffset+"&limit=30", postObj, headersObj, {timeout:30000}, {signal: controller.signal})
                .then(response => {
                    console.log(response);
                    if(response.data.count > this.winners.length){
                      for(let i=0; i<response.data.attendeeWinners.length; i++){
                          this.winners.push(response.data.attendeeWinners[i]);
                      }
                      $state.loaded();
                    }
                    else{
                      $state.complete();
                    }
                    controller = null;
                    this.fn_photoAuthThumbnail();
                    this.pageOffset = this.winners.length;
                }).catch(e => {
                    console.log(e);
                    if(e.response.data == "Token Expired"){
                      this.fn_debounceSearch();
                    }
                    else{
                      let toastMsg = e && e.message ? e.message : 'exception';
                      this.$root.fn_showToast(toastMsg);
                      this.error = true;
                    }
                })
            }
        })
        
      },

        fn_gotoChatPage: function(attendeeId){
            this.$router.push({path:'/demotrip/attendee/'+attendeeId});
        },

        fn_abbreviatedName: function(str){
            let words = str.trim().split(' ');

            if(words.length > 1){
                return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
            }
            else{
                return words[0][0].toUpperCase();
            }
        },

        async fn_photoAuthThumbnail() {

            // this.loading = true;
            // this.error = false;
            let self = this;

            
            for (let i = 0; i < this.winners.length; i++) {
              if(self.winners[i].ImageUrl && !self.winners[i].ImageUrl.startsWith('data:image/')){
                try {
                    const token = await new Promise((resolve, reject) => {
                        this.$root.fn_getToken((token) => {
                            if (token === 'error') {
                                reject('Token error');
                            } else {
                                resolve(token);
                            }
                        });
                    });

                    console.log(token + "Token Called");

                    const headersObj = {
                        headers: { 
                            'Authorization': 'Bearer ' + token,
                        }
                    };

                    
                    console.log(self.$root.baseUrl);
                    const response = await axios.get(self.$root.baseUrl + self.winners[i].ImageUrl, headersObj, {timeout: 30000});
                    
                    // Update album item with the retrieved data
                    console.log(response.data);
                    self.winners[i].ImageUrl = 'data:image/png;base64,' + response.data;
                    
                    console.log(self.winners[i].ImageUrl);
                    self.$forceUpdate();
                } catch (e) {
                    self.error = true;
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    self.$root.fn_showToast(toastMsg);
                    break; // Stop processing on error
                }
              }
            }

            this.loading = false;
            this.error = false;
        },
    },
  }
</script>