<template>
    <div>
        <v-toolbar flat>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>

            <v-toolbar-title style="line-height:20px;padding-top: 6px;">
                <!-- <strong>Verify</strong>
                <div style="font-size:small">Enter OTP to verify</div> -->
                <strong>Login</strong>
                <div style="font-size:small">Enter email &amp; code to verify</div>
            </v-toolbar-title>
        </v-toolbar>

        <div class="pa-6 pt-8">

            <v-card class="pa-4">
                 <v-form>
                    <label for="email" font-small>Email</label>
                    <v-text-field :disabled="loading" hide-details id="email" name="email" type="email" v-model="emailId" class="pa-0" required @input="fn_validateEmail"></v-text-field>
                    <span font-small style="color:red" v-if="!isValid">Please enter a valid email</span>

                    <!-- <label for="email" font-small>Enter OTP</label> -->

                    <div class="mt-8">
                        <label for="email" font-small>Invitation Code</label>
                        <v-text-field hide-details :disabled="loading" type="tel" name="code" v-model="code" class="pa-0" required @input="fn_isCodeValid"></v-text-field>
                        <span font-small style="color:red" v-if="!isCodeValid">Please enter 6 digit invitation code</span>
                        <!-- <v-otp-input type="tel" length="6" v-model="otp" :disabled="loading"></v-otp-input> -->
                    </div>

                    <!-- <div font-small light-opacity-color>OTP sent to <strong>{{$route.params.email}}</strong></div> -->

                    <!-- <div font-small right-align class="pt-4 right-align">{{remainingTime}}</div> -->

                    <v-btn class="mt-8" style="color:#ffffff" block color="blue-grey darken-3" height="48" @click="fn_getDeviceDetails" :disabled="loading || !isCodeValid">
                        {{loading ? 'Please wait...' : 'Verify'}}
                    </v-btn>

                    <!-- <v-btn class="mt-4" outlined block color="blue-grey darken-3" height="48" @click="fn_resendOTP" :disabled="loading || remainingTime ? true : false">
                        {{remainingTime ? 'Resend OTP in ' + remainingTime + ' seconds' : 'Resend OTP'}}
                    </v-btn> -->

                </v-form>
            </v-card>
           
        </div>
    </div>
</template>

<script>
// let resendOTPInterval = null;

export default{
    data(){
        return{
            // requestId:null,
            // otp:null,
            code:null,
            loading:false,
            // remainingTime:null,
            emailId:null,
            isValid:true,
            isCodeValid:true
        }
    },
    methods: {

        fn_validateEmail: function(value){
            let emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            this.isValid = emailCheck.test(value);
        },

        fn_isCodeValid: function(){
            this.isCodeValid = !this.code || this.code.length != 6 ? false : true;
        },  

        // fn_setTimer: function(){
        //     this.remainingTime = 40;
        //     resendOTPInterval = setInterval(() => {
        //         this.remainingTime--;
        //         if(this.remainingTime == 0){
        //             this.fn_clearTimer();
        //         }
        //     }, 1000);
        // },

        // fn_clearTimer: function(){
        //     this.remainingTime = null;
        //     clearInterval(resendOTPInterval);           
        // },

        // fn_resendOTP: function(){
        //     if(!navigator.onLine){
        //         this.$root.fn_showToast("nointernet");
        //         return;
        //     }

        //     this.loading = true;
            
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.RequestID = this.requestId;
        //     this.postServerData('ResendOTP', dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.requestId = response.RequestID;
        //                 self.fn_setTimer();
        //                 self.loading = false;
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //                 self.loading = false;
        //                 self.fn_clearTimer();
        //                 self.$root.fn_showToast('exception');
        //             }, 
        //         }
        //     );
        // },

        fn_getDeviceDetails: function(){
            if(!this.isValid){
                this.$root.fn_showToast('Please enter a valid email');
                return;
            }

            let self = this;
            this.loading = true;
            this.getData("DeviceDetail", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        // self.fn_verifyOTP(response);
                        self.fn_verifyInvitationCode(response);
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },

        // fn_verifyOTP: function(deviceDetails){
        //     if(!navigator.onLine){
        //         this.$root.fn_showToast('nointernet');
        //         return;
        //     }

        //     this.loading = true;
            
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.RequestID = this.requestId;
        //     dataObj.OTP = this.otp;
        //     dataObj.DeviceDetails = deviceDetails;
            
        //     this.postServerData('VerifyOTP', dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.fn_loginToFirebase(response.Token);
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //                 self.loading = false;
        //                 self.otp = null;
        //                 if(error.Message && error.Message == 'OTP Verification Failed'){
        //                     self.$root.fn_showToast('Invalid OTP');
        //                 }
        //                 else{
        //                     self.$root.fn_showToast('exception');
        //                 }
        //             }, 
        //         }
        //     );
        // },

        fn_verifyInvitationCode: function(deviceDetails){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                return;
            }

            this.loading = true;
            
            let self = this;
            let dataObj = {};
            dataObj.EmailAddress = this.emailId;
            dataObj.Code = this.code;
            dataObj.DeviceDetails = deviceDetails;

            this.postServerData('VerifyInviteCode', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.requestId = response.RequestID;
                        // self.fn_setTimer();
                        self.fn_loginToFirebase(response.Token);
                        // self.loading = false;
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        // self.$root.fn_showToast(error.Message);
                        let toastMsg = error && error.Message ? error.Message : 'exception';
                        self.$root.fn_showToast(toastMsg);
                        // self.fn_clearTimer();
                        // self.$root.fn_showToast('exception');
                    }, 
                }
            );
        },

        // fn_loginToFirebase: function(token, deviceId){
        fn_loginToFirebase: function(token){
            let self = this;
            this.getData("FirebaseLoginUsingCustomToken", token, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$router.replace({name:'trips'});
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },

        // fn_getFirebaseToken: function(deviceId){
        //     let self = this;
        //     this.getData("FirebaseToken", null, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(deviceId);
        //                 console.log(response);
        //                 self.fn_confirmLogin(response, deviceId);
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },

        // fn_confirmLogin: function(token, deviceId){
        //     if(!navigator.onLine){
        //         this.showToast("Internet not available");
        //         return;
        //     }
            
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.TripSiteID = 7;
        //     dataObj.DeviceID = deviceId;
        //     dataObj.Token = token;
            
        //     this.postServerData('ConfirmLogin', dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.fn_reLoginToFirebase(response);
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //             }, 
        //         }
        //     );
        // },

        // fn_reLoginToFirebase: function(response){
        //     let dataObj = {};
        //     dataObj.TripSiteID = response.TripSiteID;
        //     dataObj.UserType = response.UserType;
        //     dataObj.DeviceID = response.DeviceID;
        //     dataObj.Token = response.Token;

        //     this.postData("FirebaseReLoginUsingCustomToken", dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },


    },
    mounted: function(){
        // this.fn_sendOTP();
        // this.requestId = this.$route.params.requestId;
        // this.fn_setTimer();
    }
}

</script>