<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>About</v-toolbar-title>
        </v-toolbar>

        <div>
            <v-list two-line>

                <v-list-item @click="fn_OpenExternalPage('https://clubtrip.app/terms', 'About-Terms & Conditions' )">
                    <v-list-item-content>
                        <v-list-item-title>Terms &amp; Conditions</v-list-item-title>
                        <v-list-item-subtitle>Check our terms &amp; conditions</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_OpenExternalPage('https://clubtrip.app/policy', 'About-Privacy & Policy')">
                    <v-list-item-content>
                        <v-list-item-title>Privacy &amp; Policy</v-list-item-title>
                        <v-list-item-subtitle>Check our privacy &amp; policy</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="pb-1">App Version</v-list-item-title>
                        <v-list-item-subtitle>{{appVersion}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_liabraries">
                    <v-list-item-content>
                        <v-list-item-title>Open Source Libraries</v-list-item-title>
                    </v-list-item-content>

                    <v-list-tile-action>
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-list-tile-action>
                </v-list-item>
                
            </v-list>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return {
                appVersion:null
            }
        },
        methods: {
            fn_getAppVersion: function(){
                let self = this;
                this.getData('AppVersion', null, {
                    onSuccess: function(response){
                        console.log(response);
                        self.appVersion = response;
                    },
                    onError: function(error){
                        console.log(error);
                    }
                })
            },

            fn_liabraries: function(){
                this.$root.fn_postClickMetrics('Profile', 'About-Open Source Libraries', null);
                this.$router.push({path:'/about/liabraries'});
            },

            fn_OpenExternalPage: function(link, type){
                this.$root.fn_openExternalPage(link)
                this.$root.fn_postClickMetrics('Profile', type, null);
            }
        },
        mounted() {
            //this.$root.fn_postClickMetrics('Profile', 'About', null);
            this.fn_getAppVersion();
        },
    }
</script>