<template>
    <div>
        <v-expansion-panels accordion focusable>
            <v-expansion-panel>
                <v-expansion-panel-header class="font-medium pa-4 pcexpensionheader1">ROUTE 1 KAPALUA</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- <div v-html="item.content" v-if="item.content"></div> -->
                    <v-card flat>
                        <div class="text-center shuttlesection_header" style="font-size: 13pt; line-height: 150%; justify-content: center;">Evening Shuttles</div>
                        <v-card-text class="pa-0">
                            <div>
                                <table class="custom_table">
                                    <tr>
                                        <th class="pl-1 pr-1">The Ritz-Carlton</th>
                                        <th class="pl-1 pr-1">Merriman’s</th>
                                        <th class="pl-1 pr-1">Cane & Canoe</th>
                                        <th class="pl-1 pr-1">Plantation House</th>
                                    </tr>
                                    <tr>
                                        <td>5:00 PM</td>
                                        <td>5:05 PM</td>
                                        <td>5:10 PM</td>
                                        <td>5:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td>5:15 PM</td>
                                        <td>5:20 PM</td>
                                        <td>5:25 PM</td>
                                        <td>5:35 PM</td>
                                    </tr>
                                    <tr>
                                        <td>5:30 PM</td>
                                        <td>5:35 PM</td>
                                        <td>5:40 PM</td>
                                        <td>5:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td>5:45 PM</td>
                                        <td>5:50 PM</td>
                                        <td>5:55 PM</td>
                                        <td>6:05 PM</td>
                                    </tr>
                                    <tr>
                                        <td>6:00 PM</td>
                                        <td>6:05 PM</td>
                                        <td>6:10 PM</td>
                                        <td>6:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td>6:15 PM</td>
                                        <td>6:20 PM</td>
                                        <td>6:25 PM</td>
                                        <td>6:35 PM</td>
                                    </tr>
                                    <tr>
                                        <td>6:30 PM</td>
                                        <td>6:35 PM</td>
                                        <td>6:40 PM</td>
                                        <td>6:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td>6:45 PM</td>
                                        <td>6:50 PM</td>
                                        <td>6:55 PM</td>
                                        <td>7:05 PM</td>
                                    </tr>
                                    <tr>
                                        <td>7:00 PM</td>
                                        <td>7:05 PM</td>
                                        <td>7:10 PM</td>
                                        <td>7:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td>7:15 PM</td>
                                        <td>7:20 PM</td>
                                        <td>7:25 PM</td>
                                        <td>7:35 PM</td>
                                    </tr>
                                    <tr>
                                        <td>7:30 PM</td>
                                        <td>7:35 PM</td>
                                        <td>7:40 PM</td>
                                        <td>7:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td>7:45 PM</td>
                                        <td>7:50 PM</td>
                                        <td>7:55 PM</td>
                                        <td>8:05 PM</td>
                                    </tr>
                                    <tr>
                                        <td>8:00 PM</td>
                                        <td>8:05 PM</td>
                                        <td>8:10 PM</td>
                                        <td>8:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td>8:15 PM</td>
                                        <td>8:20 PM</td>
                                        <td>8:25 PM</td>
                                        <td>8:35 PM</td>
                                    </tr>
                                    <tr>
                                        <td>8:30 PM</td>
                                        <td>8:35 PM</td>
                                        <td>8:40 PM</td>
                                        <td>8:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td>8:45 PM</td>
                                        <td>8:50 PM</td>
                                        <td>8:55 PM</td>
                                        <td>9:05 PM</td>
                                    </tr>
                                    <tr>
                                        <td>9:00 PM</td>
                                        <td>9:05 PM</td>
                                        <td>9:10 PM</td>
                                        <td>9:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>9:20 PM</td>
                                        <td>9:25 PM</td>
                                        <td>9:35 PM</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>9:35 PM</td>
                                        <td>9:40 PM</td>
                                        <td>9:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>9:50 PM</td>
                                        <td>9:55 PM</td>
                                        <td>10:05 PM</td>
                                    </tr>
                                </table>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header class="font-medium pa-4 pcexpensionheader2">ROUTE 2 KA’ANAPALI</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- <div v-html="item.content" v-if="item.content"></div> -->
                    <v-card flat>
                        <div class="text-center shuttlesection_header" style="font-size: 13pt; line-height: 150%; justify-content: center;">Evening Shuttles</div>
                        <v-card-text class="pa-0">
                            <div>
                                <table class="custom_table">
                                    <tr>
                                        <th>The Ritz-Carlton</th>
                                        <th>Maui Brewing</th>
                                        <th>Whalers Village</th>
                                    </tr>
                                    <tr>
                                        <td>5:00 PM</td>
                                        <td>5:15 PM</td>
                                        <td>5:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td>5:30 PM</td>
                                        <td>5:45 PM</td>
                                        <td>5:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td>6:00 PM</td>
                                        <td>6:15 PM</td>
                                        <td>6:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td>6:30 PM</td>
                                        <td>6:45 PM</td>
                                        <td>6:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td>7:00 PM</td>
                                        <td>7:15 PM</td>
                                        <td>7:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td>7:30 PM</td>
                                        <td>7:45 PM</td>
                                        <td>7:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td>8:00 PM</td>
                                        <td>8:15 PM</td>
                                        <td>8:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td>8:30 PM</td>
                                        <td>8:45 PM</td>
                                        <td>8:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td>9:00 PM</td>
                                        <td>9:15 PM</td>
                                        <td>9:20 PM</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>9:30 PM</td>
                                        <td>9:35 PM</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>9:45 PM</td>
                                        <td>9:50 PM</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>10:00 PM</td>
                                        <td>10:05 PM</td>
                                    </tr>
                                </table>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default{
        props:['content']
    }
</script>

<style>
table.custom_table{
    width: 100%;
}
table.custom_table, 
.custom_table th, 
.custom_table td{
    border:1px solid black;
    border-collapse: collapse;
}

.custom_table td{
    text-align: center;
}

.shuttlesection_header{
    font-size: 13pt;
    line-height: 150%;
    justify-content: center;
    font-weight: 500;
    letter-spacing: .0125em;
    padding: 16px;
    text-align: center;
}

.pcexpensionheader1{
    color: #db5b6b !important;
}

.pcexpensionheader2{
    color: #59bebe !important;
}

/* .custom_table th, 
.custom_table td{
    padding-left: 16px;
    padding-right: 16px;
} */
  

</style>