<template>
    <div>
        <v-card flat class="zindex2 transparentbg">
            <!-- <v-card-title>{{content.ComponentDisplayName}}</v-card-title>
            <v-card-text v-html="content.ComponentContent"></v-card-text> -->
            <v-card-text v-html="content"></v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default{
        props:['content']
    }
</script>