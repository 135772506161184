<template>
    <div>
        <v-toolbar flat>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>

            <v-toolbar-title style="line-height:20px;padding-top: 6px;">
                <!-- <strong>Verify</strong>
                <div style="font-size:small">Enter OTP to verify</div> -->
                <strong>Login</strong>
                <div style="font-size:small">Verify mobile number</div>
            </v-toolbar-title>
        </v-toolbar>

        <div class="pa-6 pt-8">

            <v-card class="pa-4">
                 <v-form>
                   

                    <!-- <label for="email" font-small>Enter OTP</label> -->

                    <div class="mt-0">
                        <!-- <label for="email" font-small>Enter Code</label> -->
                        <p class="title grey-color verifycode_header" style="line-height: 28px !important;"> Code sent on: <span dark-opacity-color class="phonenumber"><strong>+91 {{ $route.query.mobile_number }}</strong></span></p>
                        <v-text-field hide-details :disabled="loading" type="tel" name="code" v-model="code" class="pa-0" required @input="fn_isCodeValid"></v-text-field>
                        <span font-small style="color:red" v-if="!isCodeValid">Please enter 6 digit Code</span>
                        <!-- <v-otp-input type="tel" length="6" v-model="otp" :disabled="loading"></v-otp-input> -->
                    </div>

                    <!-- <div font-small light-opacity-color>OTP sent to <strong>{{$route.params.email}}</strong></div> -->

                    <!-- <div font-small right-align class="pt-4 right-align">{{remainingTime}}</div> -->

                    <v-btn class="mt-8" style="color:#ffffff" block color="blue-grey darken-3" height="48" @click="fn_getDeviceDetails" :disabled="loading || !isCodeValid">
                        {{loading ? 'Please wait...' : 'Verify'}}
                    </v-btn>

                    <v-btn class="mt-4" outlined block color="blue-grey darken-3" height="48" @click="fn_getHashKey" :disabled="loading || remainingTime ? true : false">
                        {{resendloading ? 'Resending OTP...' : remainingTime ? 'Resend OTP in ' + remainingTime + ' seconds' : 'Resend OTP'}}
                    </v-btn>

                </v-form>
            </v-card>
           
        </div>
    </div>
</template>

<script>
let resendOTPInterval = null;
// import axios from 'axios';

export default{
    data(){
        return{
            requestId:null,
            // otp:null,
            code:null,
            loading:false,
            remainingTime:null,
            emailId:null,
            isValid:true,
            isCodeValid:false,
            resendloading: false
        }
    },
    methods: {

        fn_validateEmail: function(value){
            let emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            this.isValid = emailCheck.test(value);
        },

        fn_isCodeValid: function(){
            this.isCodeValid = !this.code || this.code.length != 6 ? false : true;
        },  

        fn_setTimer: function(){
            this.remainingTime = 40;
            resendOTPInterval = setInterval(() => {
                this.remainingTime--;
                if(this.remainingTime == 0){
                    this.fn_clearTimer();
                }
            }, 1000);
        },

        fn_clearTimer: function(){
            this.remainingTime = null;
            clearInterval(resendOTPInterval);           
        },


        // fn_resendOTPOld: function(){
        //     this.resendloading = true;  
        //     var headersObj = {
        //         headers: {
        //             'MobileAppKey': '0193c477-8f0b-4a50-970a-e3be5cc6bf99'
        //         }
        //     };
        //     let self = this;
        //     let dataObj = {};
        //     this.code = null;
        //     dataObj.HashKey = "AAAAAAAAAAA";
        //     dataObj.RequestID = this.requestId;
        //     dataObj.RequestType = 1;
        //     console.log(headersObj);
        //     axios.post(this.$root.baseUrl + "/api/mobileapp/phoneotp/resend", dataObj, headersObj, {timeout:30000})
        //     .then(response => {
        //         this.resendloading = false;
        //         console.log(response);
        //         self.requestId = response.data.RequestID;
        //         self.fn_setTimer();
        //         self.$root.fn_showToast('OTP Sent');
        //         //this.$router.push({name:'verifymobileotp', query: { mobile_number: this.code }});
        //     }).catch(e => {
        //         console.log(e);
        //         this.loading = false;
        //         this.resendloading = false;
        //         self.fn_clearTimer();
        //         self.$root.fn_showToast('exception');
        //     })
        // },

        fn_getHashKey: function(){
            if(!this.isValid){
                this.$root.fn_showToast('Please enter a valid mobile number');
                return;
            }

            let self = this;
            this.resendloading = true;
            //this.loading = true;
            if(this.$root.platform === 'android'){
                this.getData("GetHashKey", null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.fn_resendOTP(response);
                        },
                        onError: function(error){
                            console.log(error);
                            self.resendloading = false;
                            self.$root.fn_showToast('exception');
                        }
                    }
                );
            }
            else
                this.fn_sendOtp('c8UbZ0dRt/l');
           
        },

        fn_resendOTP: function(hashkey){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                return;
            }

            //this.loading = true;
            
            let self = this;
            this.code = null;
            let dataObj = {};
            dataObj.HashKey = hashkey;
            dataObj.RequestID = this.requestId;
            dataObj.RequestType = 1;
            this.postServerData('ResendPhoneOTP', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.requestId = response.RequestID;
                        self.fn_setTimer();
                        self.resendloading = false;
                        self.$root.fn_showToast('OTP Sent');
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.resendloading = false;
                        self.fn_clearTimer();
                        self.$root.fn_showToast('exception');
                    }, 
                }
            );
        },

        fn_getDeviceDetails: function(){
            if(!this.isValid){
                this.$root.fn_showToast('Please enter a valid code');
                return;
            }

            let self = this;
            this.loading = true;
            this.getData("DeviceDetail", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_verifyOTP(response);
                        //self.fn_verifyInvitationCode(response);
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },


        // fn_verifyOTPold: function(deviceDetails){
        //     this.loading = true;
        //     var headersObj = {
        //         headers: {
        //             'MobileAppKey': '0193c477-8f0b-4a50-970a-e3be5cc6bf99'
        //         }
        //     };
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.RequestID = this.requestId;
        //     dataObj.OTP = this.code;
        //     dataObj.DeviceDetails = deviceDetails;
        //     console.log(headersObj);
        //     axios.post(this.$root.baseUrl + "/api/mobileapp/phoneotp/verify", dataObj, headersObj, {timeout:30000})
        //     .then(response => {
        //         this.loading = false;
        //         console.log(response);
        //         self.fn_loginToFirebase(response.data.Token);
        //     }).catch(e => {
        //         console.log(e);
        //         this.loading = false;
        //         let toastMsg = 'exception';
        //         self.$root.fn_showToast(toastMsg);
        //     })
        // },

        fn_verifyOTP: function(deviceDetails){
            if(!navigator.onLine){
                this.$root.fn_showToast('nointernet');
                return;
            }

            this.loading = true;
            
            let self = this;
            let dataObj = {};
            dataObj.RequestID = this.requestId;
            dataObj.OTP = this.code;
            dataObj.DeviceDetails = deviceDetails;
            console.log(dataObj);
            
            this.postServerData('VerifyPhoneOTP', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        //self.loading = false;
                        self.fn_loginToFirebase(response.Token);
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.code = null;
                        self.$root.fn_showToast('OTP Verification Failed');
                        // if(error.Message && error.Message == 'OTP Verification Failed'){
                        //     self.$root.fn_showToast('Invalid OTP');
                        // }
                        // else{
                        //     self.$root.fn_showToast('OTP Verification Failed');
                        // }
                    }, 
                }
            );
        },

        // fn_loginToFirebase: function(token, deviceId){
        fn_loginToFirebase: function(token){
            let self = this;
            this.getData("FirebaseLoginUsingCustomToken", token, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.loading = false
                        self.$router.replace({name:'trips'});
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },

        fn_setOtp: function(otp){
            this.code = otp;
        }

        // fn_getFirebaseToken: function(deviceId){
        //     let self = this;
        //     this.getData("FirebaseToken", null, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(deviceId);
        //                 console.log(response);
        //                 self.fn_confirmLogin(response, deviceId);
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },

        // fn_confirmLogin: function(token, deviceId){
        //     if(!navigator.onLine){
        //         this.showToast("Internet not available");
        //         return;
        //     }
            
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.TripSiteID = 7;
        //     dataObj.DeviceID = deviceId;
        //     dataObj.Token = token;
            
        //     this.postServerData('ConfirmLogin', dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.fn_reLoginToFirebase(response);
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //             }, 
        //         }
        //     );
        // },

        // fn_reLoginToFirebase: function(response){
        //     let dataObj = {};
        //     dataObj.TripSiteID = response.TripSiteID;
        //     dataObj.UserType = response.UserType;
        //     dataObj.DeviceID = response.DeviceID;
        //     dataObj.Token = response.Token;

        //     this.postData("FirebaseReLoginUsingCustomToken", dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },


    },
    mounted: function(){
        // this.fn_sendOTP();
        this.requestId = this.$route.params.requestId;
        this.fn_setTimer();
        window.js_globals.fn_setOtp = this.fn_setOtp;
    }
}

</script>

<style>
.grey-color{
    color: #999!important;
}

.verifycode_header .phonenumber{
    color: rgba(0,0,0,.87)!important;
}

</style>