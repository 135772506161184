<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div class="shuttle_customstyle" :class="$root.platform === 'ios' ? 'hideandroidelements' : 'hideioselements'">
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/Asia_ShuttleBanner_420x200.png"></v-img>

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <!-- <accordians></accordians> -->

                <!-- <div center-align light-opacity-color class="pa-8" v-if="!overviewContent && (!listContents || !listContents?.length) && !loading">
                    No Information
                </div> -->
            </template>
            
        </div>

    </div>
</template>

<script>
import overview from './sub_components/overview'
// import accordians from './sub_components/shuttle_accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        // 'accordians':accordians
    },
    methods:{
        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     if(updateObj.pageName==='buyinpackages'){
        //         if(this.overviewContent || this.accordianContent){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             this.fn_getData('buyinpackages');
        //         }
        //     }
        // },

        fn_openLocationOnMap: function(placeName, latitude, longitude){
            let dataObj = {};
            dataObj.PlaceName = placeName;
            dataObj.Latitude = latitude;
            dataObj.Longitude = longitude;
            this.fn_captureMetrics(placeName);
           
            this.postData('OpenMaps', dataObj, 
                {
                    onSuccess: function(response){
                        console.log("Success Map");
                        console.log(response);
                    },
                    onError: function(error){
                        console.log("Error Map");
                        console.log(error);
                    }
                }
            );
        },

        fn_captureMetrics: function(objecttitle){
            var tempObj = { ObjectTitle: objecttitle, ObjectType: this.$route.name, ActionType: 'Link', LinkPath: null };
            this.$root.fn_postAccordianClickLinkMetrics(tempObj);
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Shuttle Services', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getPageDataFromServer('shuttle');
    }
}
</script>

<style>
.shuttle_customstyle v-card-title{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: .0125em;
    line-height: 2rem;
    word-break: break-word;
    padding: 16px;
}

.shuttle_customstyle v-card-text{
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: .0071428571em;
}

.hideandroidelements .androidelement{
    display: none !important;
}

.hideioselements .ioselement{
    display: none !important;
}

.shuttle_customstyle .v-icon.v-icon{
    align-items: center;
    display: inline-flex;
    font-feature-settings: "liga";
    font-size: 24px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    position: relative;
    text-indent: 0;
    transition: .3s cubic-bezier(.25,.8,.5,1), visibility 0s;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: black;
}

.shuttle_customstyle .v-icon.v-icon::after {
    background-color: currentColor;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: scale(1.3);
    width: 100%;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.shuttle_customstyle .mdi-directions::before {
    content: "\F01D0";
}

.shuttle_customstyle .mdi:before, .mdi-set {
    display: inline-block;
    font: normal normal normal 24px / 1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>