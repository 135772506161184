<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div style="padding-bottom: 30px;">
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/2024/PC_ShuttleBanner_1125x450.png"></v-img>

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <accordians></accordians>

                <!-- <div center-align light-opacity-color class="pa-8" v-if="!overviewContent && (!listContents || !listContents?.length) && !loading">
                    No Information
                </div> -->
            </template>
            
        </div>

        <div class="pcfooter_image">
            <img src="../assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
        </div>
        <div class="pcfooter_wave_image">
            <img src="../assets/2024/PlatinumClub_BottomWave_1120x112.png">
        </div>

    </div>
</template>

<script>
import overview from './sub_components/overview'
import accordians from './sub_components/shuttle_accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        'accordians':accordians
    },
    // methods:{
    //     fn_updateAvailable: function(dataObj){
    //         let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
    //         if(updateObj.pageName==='buyinpackages'){
    //             if(this.overviewContent || this.accordianContent){
    //                 this.snackbarMsg = true;
    //             }
    //             else{
    //                 this.fn_getData('buyinpackages');
    //             }
    //         }
    //     }
    // },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Shuttle Services', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getPageDataFromServer('shuttle');
    }
}
</script>