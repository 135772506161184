<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title v-if="tileContent">{{tileContent.Title}}</v-toolbar-title>
        </v-toolbar>

        <div>

            <template v-if="!loading && !tileContent">
                <div class="placeholder-wrapper pa-4">
                    <div center-align>
                        <p class="pt-4 light-opacity-color">Something went wrong. Please try again.</p>
                        <v-btn class="mt-4" outlined @click="fn_getTileContent"><v-icon left>refresh</v-icon> Retry</v-btn>
                    </div>
                </div>
            </template>

            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-card flat v-if="tileContent">
                    <v-card-text class="scrollable-content pt-4">
                        <div v-html="tileContent.Overview"></div>
                        <div class="pt-4" v-html="tileContent.ReadMoreContent.Details"></div>
                    </v-card-text>
                </v-card>
            </template>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

// import {pageMixin} from './info_page_mixin'

export default{
    // mixins:[pageMixin],
    data() {
        return {
            loading:false,
            // error:false,
            tileContent:null
        }
    },
    methods: {
        fn_getTileContent: function(){
            // this.error = false;
            this.loading = true;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    // this.error = true;
                    this.fn_getTileContentFromLocal();
                    setTimeout(() => {this.loading = false}, 300);
                }
                else{
                    let headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/pwa/hometoptiles/"+this.$route.params.id, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        this.loading = false;
                        response.data.homeTopTiles.ReadMoreContent = JSON.parse(response.data.homeTopTiles.ReadMoreContent);
                        this.tileContent = response.data.homeTopTiles;

                        let contentId = 'tilecontent_'+this.$root.tripSiteId+'_'+this.$route.params.id;
                        this.$root.fn_saveLocalData(contentId, response.data.homeTopTiles);
                    }).catch(e => {
                        this.fn_getTileContentFromLocal();
                        setTimeout(() => {this.loading = false}, 300);
                        // this.error = true;
                        console.log(e);
                    })
                }
            })
        },

        fn_getTileContentFromLocal: function(){
            let contentId = 'tilecontent_'+this.$root.tripSiteId+'_'+this.$route.params.id;
            this.$root.fn_getLocalData(contentId, data => {
                this.tileContent = data;
            })
        }
    },
    mounted() {
        this.fn_getTileContent();
    },

}

</script>