<template>
  <div>
      <v-toolbar :color="$root.themeColor" flat dark>
          <v-btn icon @click="$router.go(-1)">
              <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
          </v-btn>
          <v-toolbar-title>Profile</v-toolbar-title>
      </v-toolbar>

      <div>

        <template v-if="error">
          <div style="text-align:center;" class="pa-4">
              <p>Something went wrong. Please try again.</p>
              <br>
              <v-btn outlined @click="fn_getAttendeeInfo"><v-icon left>refresh</v-icon> Retry</v-btn>
          </div>
        </template>

        <template v-if="loading">
            <div class="pa-8">
                <v-img width="120" style="margin:auto" src="../../assets/giphy.gif"></v-img>
            </div>
        </template>

        <template v-else>
          <v-card flat class="mt-4" v-if="attendeeInfo">
              <!-- <div center-align style="width:120px;height:120px;background-color: #f0f0f0;border-radius: 50%;font-size:48px;padding:">
                {{fn_abbreviatedName(attendeeInfo.WinnerName)}}
              </div> -->
              <v-img
                  :lazy-src="$root.imagePlaceholder"
                  style="border-radius:50%;margin:auto"
                  width="120"
                  height="120"
                  :src="attendeeInfo.ImageUrl"
                  v-if="attendeeInfo.ImageUrl"
              ></v-img>

              <div center-align v-else style="background:#f0f0f0;width:120px;height:120px;margin:auto;border-radius: 50%;padding:16px">
                <v-img
                  :lazy-src="$root.imagePlaceholder"
                  style="margin:auto;opacity: 0.6;"
                  width="80"
                  height="80"
                  src="../../assets/person.svg"
              ></v-img>
              </div>

              <div class="title pl-4 pt-4 pr-4 pb-1 center-align">{{attendeeInfo.WinnerName}}</div>
              <div class="center-align font-small pb-4">{{attendeeInfo.JobTitle}}</div>

              <v-divider style="margin-right:25%;margin-left:25%;"></v-divider>

              <!-- <v-card-title class="pt-5 pb-0" style="font-size: 1.125rem;word-break: break-word;">
                {{attendeeInfo.Organization}}
              </v-card-title> -->

              <div class="font-small pl-4 pr-4 pb-2 pt-1">
                <strong>
                  <!-- <span v-if="attendeeInfo.Segment">{{attendeeInfo.Segment}}, </span> -->
                  <span v-if="attendeeInfo.AreaName">{{attendeeInfo.AreaName}}, </span>
                  <span v-if="attendeeInfo.Subsidiary && attendeeInfo.Subsidiary !== attendeeInfo.AreaName">{{attendeeInfo.Subsidiary}}, </span>
                  <span v-if="attendeeInfo.Period">{{attendeeInfo.Period}}</span>
                </strong>
              </div>

              <!-- <v-card-title class="pb-4" style="font-size: 1.125rem;word-break: break-word;">
                  {{attendeeInfo.Segment}}
              </v-card-title> -->

              <v-card-text style="padding-bottom:64px">
                  <!-- <div style="font-size:1rem;word-break: break-word;" class="font-medium pb-1">Specialist Sales, US Federal, United States, FY22</div> -->
                  <div>
                    {{attendeeInfo.Achievements}}
                      <!-- This award is granted to individuals who achieve exemplary RBI/UBI Attainment and have demonstrated outstanding business impact. -->
                  </div>
              </v-card-text>
          </v-card>

          <!-- <v-footer class="pa-3" style="position: fixed;width: 100%;bottom: 0;max-width:420px;background:#fff">
              <v-btn block color="primary" dark @click="fn_gotoChatPage">Start Chat</v-btn>
          </v-footer> -->
        </template>
          
          
      </div>

      
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({
    attendeeInfo:null,
    loading:false,
    error:false
  }),
  methods:{
      
      fn_getAttendeeInfo: function(){
        this.loading = true;
        this.error = false;
        this.$root.fn_getToken(token=>{
          if(token == 'error'){
            this.loading = false;
            this.error = true;
            this.$root.fn_showToast('exception');
          }
          else{
              var headersObj = {
                headers: {
                      'Authorization': 'bearer '+token,
                    }
                };

              axios.get(this.$root.baseUrl + "/api/mobileapp/winnerattendee/"+this.$route.params.attendeeid, headersObj, {timeout:30000})
              .then(response => {
                  console.log(response);
                  this.loading = false;
                  this.attendeeInfo = response.data;
                  this.fn_photoAuth();
              }).catch(e => {
                  console.log(e);
                  this.loading = false;
                  this.error = true;
                  let toastMsg = e && e.message ? e.message : 'exception';
                  this.$root.fn_showToast(toastMsg);
              })
          }
        })
      },

      fn_gotoChatPage: function(){
          this.$router.push({path:'/demotrip/chatpage', query:{name:this.attendeeInfo.WinnerName}});
      },

      fn_abbreviatedName: function(str){
          let words = str.trim().split(' ');

          if(words.length > 1){
              return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
          }
          else{
              return words[0][0].toUpperCase();
          }
      },

      fn_photoAuth: function() {

            this.loading = true;
            this.error = false;
            let self = this;

            if (self.attendeeInfo.ImageUrl && !self.attendeeInfo.ImageUrl.startsWith('data:image/')){
                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        this.loading = false;
                        this.error = true;
                        this.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };

                        var getUrl = self.$root.baseUrl + self.attendeeInfo.ImageUrl;
                        axios.get(getUrl, headersObj, {timeout:30000})
                        .then(response => {
                            self.attendeeInfo.ImageUrl = 'data:image/png;base64,' + response.data;
                            console.log(self.attendeeInfo.ImageUrl);
                            this.loading = false;
                            self.$forceUpdate();
                        }).catch(e => {
                            this.loading = false;
                            this.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            this.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

            }
      }
  },
  mounted() {
    this.fn_getAttendeeInfo();
  },
}
</script>