<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/Asia_DepartureBanner_420x200_2023_Updated.png"></v-img>
                <overview :content="additionalContent.DepartureInfo" v-if="additionalContent && additionalContent.DepartureInfo"></overview>
            </template>

            <template v-if="!loading && (!additionalContent || (additionalContent && !additionalContent.DepartureInfo)) && !error">
                <div class="placeholder-wrapper content-page-placeholder pa-4">
                    <div center-align>
                        <v-icon class="placeholder-icon">flight_takeoff</v-icon>
                        <p class="pt-4 light-opacity-color">No departure information available</p>
                        <v-btn class="mt-4" outlined @click="fn_refresh"><v-icon left>refresh</v-icon> Reload</v-btn>
                    </div>
                </div>
            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('departureinfo')">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

    </div>
</template>

<script>
import overview from './sub_components/overview'

import {pageMixin} from './info_page_mixin'

// import axios from 'axios';

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
    },
    data() {
        return {
            error:false,
            additionalContent:null
        }
    },
    methods:{
        // fn_updateAvailable: function(dataObj){
        // fn_updateAvailable: function(){
        //     console.log('update available');
        //     // let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     // if(updateObj.pageName==='departureinfo'){
        //     //     if(this.overviewContent || this.accordianContent){
        //     //         // this.snackbarMsg = true;
        //     //         console.log('update');
        //     //     }
        //     //     else{
        //     //         // this.fn_getData('departureinfo');
        //     //         console.log('get');
        //     //     }
        //     // }
        // },

        // fn_getDetails: function(){

        //     // if(!navigator.onLine){
        //     //     this.error = true;
        //     //     this.$root.fn_showToast("nointernet");
        //     //     return;
        //     // }

        //     this.loading = true;
        //     this.error = false;
        //     this.$root.fn_getToken(token=>{
        //         if(token == 'error'){
        //             this.loading = false;
        //             // this.error = true;
        //             // this.$root.fn_showToast('exception');
        //             this.fn_refresh();
        //         }
        //         else{
        //             var headersObj = {
        //                 headers: { 
        //                     'Authorization': 'bearer '+token,
        //                     }
        //                 };

        //             axios.get(this.$root.baseUrl + "/api/mobileapp/page/components?PagePath=/departureinfo&ModifiedDate=2022-08-01", headersObj, {timeout:30000})
        //             .then(response => {
        //                 this.loading = false;
        //                 console.log(response);
        //                 this.snackbarMsg = false;
        //                 let departureInfo = response.data && response.data.Pages && response.data.Pages.length ? response.data.Pages[0] : null;
        //                 if(departureInfo){
        //                     this.pageName = departureInfo.PageTitle;
        //                     this.additionalContent = JSON.parse(departureInfo.AdditionalContent);
        //                 }
                        
        //             }).catch(e => {
        //                 this.loading = false;
        //                 // this.error = true;
        //                 console.log(e);
        //                 this.fn_refresh();
        //                 // let toastMsg = e && e.message ? e.message : 'exception';
        //                 // this.$root.fn_showToast(toastMsg);
        //             })
        //         }
        //     })

        // },

        fn_refresh: function(){
            this.fn_getPageDataFromServer('departureinfo', response => {
                if(response){
                    this.pageName = response.PageTitle;
                    if(response.AdditionalContent){
                        this.additionalContent =  response.AdditionalContent ? JSON.parse(response.AdditionalContent) : null;
                    }
                    else{
                        this.additionalContent = {};
                        this.additionalContent.DepartureInfo = response.ListContents ? response.ListContents : null;
                    }                    
                }
            });
        },
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Departure Info', null);
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        // this.fn_getDetails();
        this.fn_refresh();
    }
}
</script>