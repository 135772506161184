<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div style="padding-bottom: 30px;">
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/PlatinumClub2023_Image2Banner_420x200.png"></v-img>

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <template>
                    <v-list class="pa-0" three-line>
                        <template v-for="(item, index) in executiveAttendees">
                            <v-list-item :key="index">

                                <v-list-item-avatar class="grey lighten-3">
                                    <template v-if="item.ImageUrl"><v-img alt="winnerimage" :src="item.ImageUrl" lazy-src="../../../assets/person.svg"></v-img></template>
                                    <template v-else>{{fn_abbreviatedName(item.Name)}}</template>
                                    
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.Name"></v-list-item-title>
                                    <v-list-item-subtitle v-if="item.Title">{{item.Title}}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="item.Group">{{item.Group}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider inset :key="index+'divider'" v-if="index != executiveAttendees.length - 1"></v-divider>
                        </template>
                    </v-list>
                </template>
               
            </template>
            
        </div>

        <div class="pcfooter_image">
            <img src="../assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
        </div>
        <div class="pcfooter_wave_image">
            <img src="../assets/2024/PlatinumClub_BottomWave_1120x112.png">
        </div>

        <!-- <div class="pcfooter_image">
            <img src="../assets/PlatinumClub2023_FooterImage_420x200.png">
        </div> -->

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('eveningevents')">Refresh Now</v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import overview from './sub_components/overview'
// import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'


export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        // 'accordians':accordians
    },
    data() {
        return {
            error:false,
            additionalContent:null,
            executiveAttendees: [
                {'Name': 'Judson Althoff', 'Title': 'Executive Vice President and Chief Commercial Officer', 'Group': 'MCAPS HQ', 'ImageUrl': require('../assets/ExecutiveAttendance_JudsonAlthoff.jpg')},
                {'Name': 'Ahmed Mazhari', 'Title': 'President', 'Group': 'Microsoft Asia', 'ImageUrl': require('../assets/ExecutiveAttendance_AhmedMazhari.jpg')},
                {'Name': 'Bob Bejan', 'Title': 'Corporate Vice President', 'Group': 'Global Events, Studios & Communities', 'ImageUrl': require('../assets/ExecutiveAttendance_BobBejan.jpg')},
                {'Name': 'Bradley Hopkinson', 'Title': 'CSO WW Region Lead', 'Group': 'CSO', 'ImageUrl': require('../assets/ExecutiveAttendance_BradleyHopkinson.jpg')},
                {'Name': 'Candice Ling', 'Title': 'Vice President', 'Group': 'US Federal', 'ImageUrl': require('../assets/ExecutiveAttendance_CandiceLing.jpg')},
                {'Name': 'Caroline Sanderson', 'Title': 'Chief of Staff', 'Group': 'MCAPS HQ', 'ImageUrl': require('../assets/ExecutiveAttendance_CarolineSanderson.jpg')},
                {'Name': 'Deb Cupp', 'Title': 'President', 'Group': 'Microsoft Americas', 'ImageUrl': require('../assets/ExecutiveAttendance_DebCupp.jpg')},
                {'Name': 'Hayete Gallot', 'Title': 'Corporate Vice President', 'Group': 'Commercial Solution Areas', 'ImageUrl': require('../assets/ExecutiveAttendance_HayeteGallot.jpg')},
                {'Name': 'Kevin Peesker', 'Title': 'President', 'Group': 'Small, Medium, Corporate & Digital', 'ImageUrl': require('../assets/ExecutiveAttendance_KevinPeesker.jpg')},
                {'Name': 'Kim Akers', 'Title': 'Corporate Vice President', 'Group': 'MCAPS Enablement and Operations', 'ImageUrl': require('../assets/ExecutiveAttendance_KimAkers.jpg')},
                {'Name': 'Lynne Kjolso', 'Title': 'Vice President', 'Group': 'Global Partner & Retail Media', 'ImageUrl': require('../assets/ExecutiveAttendance_LynneKjolso.jpg')},
                {'Name': 'Mala Anand', 'Title': 'Corporate Vice President', 'Group': 'Customer Experience and Success', 'ImageUrl': require('../assets/ExecutiveAttendance_MalaAnand.jpg')},
                {'Name': 'Michelle Chang', 'Title': 'CVP/CFO', 'Group': 'Microsoft Finance Customer and Partner Solutions', 'ImageUrl': require('../assets/ExecutiveAttendance_MichelleChang.jpg')},
                {'Name': 'Nick Parker', 'Title': 'President', 'Group': 'Industry & Partner Sales', 'ImageUrl': require('../assets/ExecutiveAttendance_NickParker.jpg')},
                {'Name': 'Omar Abbosh', 'Title': 'President', 'Group': 'Industry Solutions', 'ImageUrl': require('../assets/ExecutiveAttendance_OmarAbbosh.jpg')},
                {'Name': 'Ralph Haupter', 'Title': 'President', 'Group': 'Microsoft EMEA', 'ImageUrl': require('../assets/ExecutiveAttendance_RalphHaupter.jpg')},
                {'Name': 'Stijn Nauwelaerts', 'Title': 'Corporate Vice President', 'Group': 'MCAPS HR', 'ImageUrl': require('../assets/ExecutiveAttendance_StijnNauwelaerts.jpg')},
                
            ]
        }
    },
    methods:{
        // fn_updateAvailable: function(dataObj){

        fn_updateAvailable: function(dataObj){
            let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
            if(updateObj.pageName==='executiveattendence'){
                if(this.overviewContent || this.accordianContent){
                    this.snackbarMsg = true;
                }
                else{
                    this.fn_getData('executiveattendance');
                }
            }
        }

    },
    mounted(){
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.$root.fn_postClickMetrics('Explore Tiles', 'Executive Attendance', null);
        this.fn_getPageDataFromServer('executiveattendance');
    }
}
</script>