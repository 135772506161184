<template>
    <div>
        <v-toolbar flat>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>

            <v-toolbar-title style="line-height:20px;padding-top: 6px;">
                <!-- <strong>Verify</strong>
                <div style="font-size:small">Enter OTP to verify</div> -->
                <strong>Guest Login</strong>
                <div style="font-size:small">Enter date of birth to verify</div>
            </v-toolbar-title>
        </v-toolbar>

        <div class="pa-6 pt-8">

            <v-card class="pa-4">
                 <v-form>
                    <label for="email" font-small>Date of Birth</label>
                    <!--<v-text-field :disabled="loading" hide-details id="Dateofbirth" name="Date of birth" type="date" v-model="dateofbirth" class="pa-0" required @input="fn_isCodeValid"></v-text-field>-->
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field prepend-inner-icon="event" placeholder="Select" :value="dateofbirth | moment('DD-MM-YYYY')" readonly v-on="on" required ></v-text-field>
                        </template>
                        <v-date-picker ref="picker" v-model="dateofbirth" min="1900-01-01" @change="save"></v-date-picker>
                    </v-menu>
                    <span font-small style="color:red" v-if="!isValid">Please enter a valid date of birth</span>
                    <!-- <label for="email" font-small>Enter OTP</label> -->

                    <v-btn class="mt-8" style="color:#ffffff" block color="blue-grey darken-3" height="48" @click="fn_verifyQRCode" :disabled="loading || !isCodeValid">
                        {{loading ? 'Please wait...' : 'Verify'}}
                    </v-btn>

                    <!-- <v-btn class="mt-4" outlined block color="blue-grey darken-3" height="48" @click="fn_resendOTP" :disabled="loading || remainingTime ? true : false">
                        {{remainingTime ? 'Resend OTP in ' + remainingTime + ' seconds' : 'Resend OTP'}}
                    </v-btn> -->

                </v-form>
            </v-card>

            <!-- <v-btn color="success" @click="$router.go(-1)">text</v-btn> -->
           

            
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            dateofbirth:null,
            isValid:true,
            loading:false,
            loginViaQR:false,
            isCodeValid:false,
            menu: false,
            error: false
        }
    },
    methods: {    
        
        fn_isCodeValid: function(){
            this.isCodeValid = this.dateofbirth == '' || this.dateofbirth == null ? false : true;
        }, 

        save() {
            this.fn_isCodeValid();
            this.$refs.menu.save(this.dateofbirth);
        },

        fn_verifyQRCode: function(){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                this.loading = false;
                return;
            }
            console.log(this.$route.params.itemObj.DeviceDetails);

            delete this.$route.params.itemObj.DeviceDetails.DeviceID;
            
            let self = this;
            let dataObj = {};

            dataObj.EmailAddress = this.$route.params.itemObj.EmailAddress;
            dataObj.QRCode = this.$route.params.itemObj.QRCode;
            dataObj.DOB = this.dateofbirth;
            dataObj.DeviceDetails = this.$route.params.itemObj.DeviceDetails;

            console.log(JSON.stringify(dataObj));
            
            this.postServerData('VerifyQRCode', dataObj, 
                {
                    onSuccess: function(response){
                        console.log('QR Verified');
                        console.log(response);
                        if(response && response.Message == 'Dob does not match!'){
                            let toastMsg = 'Incorrect DOB, Please enter correct DOB to verify.';
                            self.$root.fn_showToast(toastMsg);
                        }
                        else
                            self.fn_loginToFirebase(response);
                        
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        let toastMsg = error && error.Message ? error.Message : 'exception';
                        self.$root.fn_showToast(toastMsg);
                    }, 
                }
            );
        },

        fn_loginToFirebase: function(verifyResponseObj){
            let self = this;
            this.getData("FirebaseLoginUsingCustomToken", verifyResponseObj.Token, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        // self.fn_confirmLogin(verifyResponseObj);
                        self.$router.replace({name:'trips'});
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        }
    },
    mounted() {
        // window.js_globals.fn_initActions = this.fn_initActions;
        // window.js_globals.fn_QrCodeReceived = this.fn_getDeviceDetails;

        // if(localStorage.getItem('reload_trip_id')){
        //     let tripId = localStorage.getItem('reload_trip_id');
        //     localStorage.removeItem('reload_trip_id');
        //     this.fn_initActions(Number(tripId));
        // }
    },

    
}

</script>