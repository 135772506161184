<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title v-if="albumData">{{albumData.Title}}</v-toolbar-title>
        </v-toolbar>

        <div class="pa-2">

            <template v-if="error">
                <div class="placeholder-wrapper pa-4">
                    <div center-align>
                        <v-icon class="placeholder-icon">luggage</v-icon>
                        <p class="pt-4 light-opacity-color">Something went wrong. Please try again.</p>
                        <v-btn class="mt-4" outlined @click="fn_getPhotos"><v-icon left>refresh</v-icon> Retry</v-btn>
                    </div>
                </div>
            </template>

            <template v-if="loading">
                <!-- <div style="text-align:center" class="pa-4">
                    <v-progress-circular indeterminate color="grey" width="2"></v-progress-circular>
                </div> -->
                <div class="pa-8">
                    <v-img width="120" style="margin:auto" src="../../../assets/giphy.gif"></v-img>
                </div>
            </template>

            <template v-else>
            
                <v-subheader class="pl-1" v-if="albumData">Photos ({{albumData.Photos.length}})</v-subheader>
                <v-layout row wrap ma-0 v-if="albumData">
                    <v-flex xs4 sm4 md4 pa-1 v-for="(item, index) in albumData.Photos" :key="index" @click="fn_openCarousel(item, index)">
                        <v-img :src="item.ThumbnailUrl" lazy-src="https://picsum.photos/10/6?image=15" aspect-ratio="1" class="grey lighten-2" v-if="item.ThumbnailUrl">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <v-img :src="$root.imagePlaceholder" aspect-ratio="1" v-else></v-img>
                    </v-flex>
                </v-layout>
                
            </template>

            <template v-if="!loading && !error && (!albumData || !albumData.Photos.length)">
                <div class="placeholder-wrapper pa-4">
                    <div center-align>
                        <v-icon class="placeholder-icon">luggage</v-icon>
                        <p class="pt-4 light-opacity-color">No new photos</p>
                        <v-btn class="mt-4" outlined @click="fn_getPhotos"><v-icon left>refresh</v-icon> Reload</v-btn>
                    </div>
                </div>
            </template>
            

        </div>

        <v-dialog v-model="imageCarouseDialog" fullscreen hide-overlay>

            <v-card flat style="height:100%" v-if="albumData && albumData.Photos && albumData.Photos.length">
                <v-toolbar :color="$root.themeColor" flat dark>
                    <v-btn icon @click="imageCarouseDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    <v-toolbar-title style="line-height:20px;padding-top: 6px;">
                        {{imageTitle}}
                        <div style="font-size:small">{{(activeItemIndex + 1)}} of {{albumData.Photos.length}}</div>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="fn_getImage('save')">
                        <v-icon>save_alt</v-icon>
                    </v-btn>
                    <v-btn icon @click="fn_getImage('share')">
                        <v-icon>{{$root.platform === 'ios' ? 'ios_share' : 'share'}}</v-icon>
                    </v-btn>
                </v-toolbar>

                <div style="height:calc(100% - 56px);display: flex;align-items: center;">
                    <v-carousel hide-delimiters :continuous="false" height="auto" @change="fn_slideChange" v-model="activeItemIndex">
                        <v-carousel-item v-for="(item,i) in albumData.Photos" :key="i" :src="item.DisplayUrl ? item.Base64DisplayUrl : item.Base64PhotoUrl">
                            <v-img :src="item.DisplayUrl ? item.Base64DisplayUrl : item.Base64PhotoUrl" lazy-src="https://picsum.photos/10/6?image=15" aspect-ratio="1" class="grey lighten-2">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <!-- <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template> -->
                        </v-carousel-item>
                    </v-carousel>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="downloading" persistent width="300">
            <v-card>
                <v-card-text class="pt-4">Downloading...
                    <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import axios from 'axios';

export default{
    data(){
        return{
            // snackbar:true
            bottomSheet:null,
            activeItemIndex:0,
            imageTitle:null,
            imageUrl:null,
            imageName:null,
            imageCarouseDialog:false,
            loading:true,
            // totalCount:9,
            albumData:null,
            error:false,
            downloading:false,
            imageID: null
            // photos:[
            //     {
            //         title:'All Photos',
            //         imgSrc:'https://picsum.photos/500/300?image=15',
            //         lazySrc:'https://picsum.photos/10/6?image=15'
            //     },
            //     {
            //         title:'Party',
            //         imgSrc:'https://picsum.photos/500/300?image=20',
            //         lazySrc:'https://picsum.photos/10/6?image=20'
            //     },
            //     {
            //         title:'Celebration',
            //         imgSrc:'https://picsum.photos/500/300?image=25',
            //         lazySrc:'https://picsum.photos/10/6?image=25'
            //     },
            //     {
            //         title:'All Photos',
            //         imgSrc:'https://picsum.photos/500/300?image=30',
            //         lazySrc:'https://picsum.photos/10/6?image=30'
            //     },
            //     {
            //         title:'Party',
            //         imgSrc:'https://picsum.photos/500/300?image=35',
            //         lazySrc:'https://picsum.photos/10/6?image=35'
            //     },
            //     {
            //         title:'Celebration',
            //         imgSrc:'https://picsum.photos/500/300?image=40',
            //         lazySrc:'https://picsum.photos/10/6?image=40'
            //     },
            //     {
            //         title:'All Photos',
            //         imgSrc:'https://picsum.photos/500/300?image=45',
            //         lazySrc:'https://picsum.photos/10/6?image=45'
            //     },
            //     {
            //         title:'Party',
            //         imgSrc:'https://picsum.photos/500/300?image=50',
            //         lazySrc:'https://picsum.photos/10/6?image=50'
            //     },
            //     {
            //         title:'Celebration',
            //         imgSrc:'https://picsum.photos/500/300?image=55',
            //         lazySrc:'https://picsum.photos/10/6?image=55'
            //     }
            // ]
        }
    },
    methods:{
        fn_openCarousel: function(imageItem, itemIndex){
            this.activeItemIndex = itemIndex;
            this.imageTitle = imageItem.Title;
            this.imageUrl = imageItem.PhotoDownloadUrl;
            this.imageName = imageItem.PhotoName;
            this.imageID = imageItem.AlbumPhotoID;
            this.fn_photoAuth(this.activeItemIndex);
            this.$root.fn_postClickMetrics('View Photo', this.$route.params.albumId, this.imageID);
            this.imageCarouseDialog = true;
        },

        fn_slideChange: function(index){
            // alert(event);
            this.imageTitle = this.albumData.Photos[index].Title;
            this.imageUrl = this.albumData.Photos[index].PhotoDownloadUrl;
            this.imageName = this.albumData.Photos[index].PhotoName;
            this.imageID = this.albumData.Photos[index].AlbumPhotoID;
            this.$root.fn_postClickMetrics('View Photo', this.$route.params.albumId, this.imageID);
            this.fn_photoAuth(index);
        },

        fn_getPhotos: function(){
            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    this.error = true;
                    this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/albums/"+this.$route.params.albumId+"/photolist", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        // let data = JSON.parse(response);
                        console.log(response);
                        this.albumData = response.data;
                        for (var i in this.albumData.Photos) {
                            this.albumData.Photos[i].Base64DisplayUrl = null;
                            this.albumData.Photos[i].Base64PhotoUrl = null;
                        }
                        this.fn_photoAuthThumbnail();
                        this.$root.fn_postClickMetrics('Photo Gallery', this.albumData.Title, this.$route.params.albumId);
                        // this.photos = response.data.Photos;
                    }).catch(e => {
                        this.loading = false;
                        this.error = true;
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                        console.log(e);
                    })
                }
            })
            
        },

        async fn_photoAuthThumbnail() {

            // this.loading = true;
            // this.error = false;
            let self = this;

            for (let i = 0; i < this.albumData.Photos.length; i++) {
                try {
                    const token = await new Promise((resolve, reject) => {
                        this.$root.fn_getToken((token) => {
                            if (token === 'error') {
                                reject('Token error');
                            } else {
                                resolve(token);
                            }
                        });
                    });

                    console.log(token + "Token Called");

                    const headersObj = {
                        headers: { 
                            'Authorization': 'Bearer ' + token,
                        }
                    };

                    
                    console.log(self.$root.baseUrl);
                    const response = await axios.get(self.$root.baseUrl + self.albumData.Photos[i].ThumbnailUrl, headersObj, {timeout: 30000});
                    
                    // Update album item with the retrieved data
                    console.log(response.data);
                    self.albumData.Photos[i].ThumbnailUrl = 'data:image/png;base64,' + response.data;
                    
                    console.log(self.albumData.Photos[i].ThumbnailUrl);
                    self.$forceUpdate();
                } catch (e) {
                    self.error = true;
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    self.$root.fn_showToast(toastMsg);
                    break; // Stop processing on error
                }
            }

            this.loading = false;
            this.error = false;
        },

        fn_photoAuth: function(index) {

            this.loading = true;
            this.error = false;
            let self = this;

            if (!((self.albumData.Photos[index].Base64DisplayUrl && self.albumData.Photos[index].Base64DisplayUrl.startsWith('data:image/')) || 
                (self.albumData.Photos[index].Base64PhotoUrl && self.albumData.Photos[index].Base64PhotoUrl.startsWith('data:image/')))){
                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        this.loading = false;
                        this.error = true;
                        this.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };

                        console.log(self.$root.baseUrl);
                        var apiUrl = self.albumData.Photos[index].DisplayUrl ? self.albumData.Photos[index].DisplayUrl : self.albumData.Photos[index].PhotoUrl
                        var getUrl = self.$root.baseUrl + apiUrl;
                        console.log(getUrl);
                        axios.get(getUrl, headersObj, {timeout:30000})
                        .then(response => {
                            if (self.albumData.Photos[index].DisplayUrl !== null) {
                                self.albumData.Photos[index].Base64DisplayUrl = 'data:image/png;base64,' + response.data;
                            } else {
                                self.albumData[index].Base64PhotoUrl = 'data:image/png;base64,' + response.data;
                            }
                            
                            console.log(self.albumData.Photos[index].DisplayUrl);
                            self.$forceUpdate();
                        }).catch(e => {
                            this.loading = false;
                            this.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            this.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

            }

            this.loading = false;
            this.error = false;
        },

        // fn_getImage: function(type){
        //     let self = this;
        //     this.getData('GetImage', this.imageName, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 let isDownloaded = JSON.parse(response);
        //                 if(isDownloaded){
        //                     type == 'save' ? self.fn_saveImage() : self.fn_shareImage();
        //                 }
        //                 else{
        //                     self.fn_downloadImage(type);
        //                 }
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //                 if(error.dataKey && !JSON.parse(error.dataKey)){
        //                     self.fn_downloadImage(type);
        //                 }
        //             }
        //         }
        //     );
        // },

        fn_getImage: function(type){
            if(this.$root.platform === 'ios'){
                this.fn_openImage(type);
            }
            else{
                //this.fn_openImage(type);
                this.$root.fn_checkStoragePermission(response=>{
                    if(JSON.parse(response)){
                        this.fn_openImage(type);
                    }
                    else{
                        this.$root.fn_showToast('Please allow storage permission');
                    }
                })
            }
        },

        fn_openImage: function(type){
            let self = this;
            this.getData('GetImage', this.imageName, 
            {
                onSuccess: function(response){
                    console.log(response);
                    let isDownloaded = JSON.parse(response);
                    if(isDownloaded){
                        if(type=='share'){
                            self.fn_shareImage();
                            self.$root.fn_postClickMetrics('Share Photo', self.$route.params.albumId, self.imageID);
                        }
                        else{
                            self.$root.fn_showToast("Added to Photos");
                            self.$root.fn_postClickMetrics('Download Photo', self.$route.params.albumId, self.imageID);
                        }
                    }
                    else{
                        self.fn_downloadImage(type);
                    }
                },
                onError: function(error){
                    console.log(error);
                    if(error.dataKey && !JSON.parse(error.dataKey)){
                        self.fn_downloadImage(type);
                    }
                }
            });
        },

        fn_downloadImage: function(type){
            let self = this;
            let dataObj = {};
            dataObj.FileName = this.imageName;
            dataObj.FileUrl = this.$root.baseUrl + this.imageUrl;
            dataObj.DisplayName = this.imageName;
            console.log(dataObj);
            this.$root.platform === 'android' ? dataObj.IsImageFile = true : '';
            this.downloading = true;
            this.getServerData('DownloadFile', dataObj, {
                onSuccess: function(response){
                    console.log(response);
                    self.downloading = false;
                    self.$root.platform === 'android' ? self.$root.fn_showToast('Added to Photos') : '';
                    if(type == 'share'){
                        self.fn_shareImage();
                        self.$root.fn_postClickMetrics('Share Photo', self.$route.params.albumId, self.imageID);
                    }
                    else{
                        self.$root.platform === 'ios' ? self.fn_saveImage() : self.$root.fn_showToast('Added to Photos');
                        self.$root.fn_postClickMetrics('Download Photo', self.$route.params.albumId, self.imageID);
                    }
                }, 
                onError: function(error){
                    console.log(error);
                    self.downloading = false;
                    self.$root.fn_showToast('exception');
                }, 
            });
        },


        fn_saveImage: function(){
            let self = this;
            this.getData('SaveImageToPhotoLibrary', this.imageName, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$root.fn_showToast('Added to Photos');
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_shareImage: function(){
            this.getData('ShareImage', this.imageName, {
                onSuccess: function(response){
                    console.log(response);
                },
                onError: function(error){
                    console.log(error);
                }
            });
        },

        fn_closeDialog: function(){
            if(this.imageCarouseDialog){
                this.imageCarouseDialog = false;
            }
            else{
                window.js_globals.fn_onBackPress = this.$root.fn_goBack;
                this.$root.fn_goBack();
            }
        }
    },
    mounted(){
        window.js_globals.fn_onBackPress = this.fn_closeDialog;

        // window.js_globals.fn_initActions = this.fn_initActions;
        setTimeout(() => {
            this.fn_getPhotos();
        }, 100);
    }
}
</script>