<template>
    <div class="explore_page" v v-scroll="onScroll">

        <v-toolbar :height="64" :color="$root.themeColor" dark>
            <!-- <div style="height:40px;width:40px;padding:4px;background-color:#ffefe2;border-radius:10px;margin-right:16px">
                <img width="32" height="32" src="../assets/PlatinumLogo_128x128.png"/>
            </div> -->
            <v-toolbar-title>ASIA GOLD CLUB</v-toolbar-title>
            <!-- <img width="42" height="42" src="../assets/PlatinumLogo_128x128.png"/> -->
            <v-spacer></v-spacer>
            <v-badge :content="$root.tripOnLoadData.NotificationCount" :value="$root.tripOnLoadData.NotificationCount" color="green darken-1" overlap v-if="$root.tripOnLoadData">
                <v-icon @click="fn_gotoNotificationPage">notifications</v-icon>
            </v-badge>
            <v-btn @click="fn_navigatetopage" icon class="pr-1">
                <v-icon size="30px">mdi-account-circle</v-icon>
            </v-btn>
            <!-- <v-badge class="ml-4" :content="$root.unreadMessageCount" :value="$root.unreadMessageCount" color="#f69220" overlap>
                <v-icon color="white" @click="$root.fn_openChatView">chat</v-icon>
            </v-badge> -->
        </v-toolbar>

        <div>

            <template v-if="loadingTiles">
                <div class="pa-8 center-align">
                    <v-progress-circular color="blue-grey" width="3" indeterminate></v-progress-circular>
                </div>
            </template>

            

            <template v-else>
            
                <div class="slider-card-wrapper" v-if="topTiles && topTiles.length">

                    <template v-for="(item, index) in topTiles">

                        <v-card :color="item.Color" :class="{'full-width':topTiles.length == 1, 'mr-4':topTiles.length-1 != index}" height="160" dark :key="index" v-if="item.Status" @click="$parent.fn_readMore(item)">
                            <div class="d-flex flex-no-wrap justify-space-between">
                                <div>
                                    <v-card-title class="text-h5">{{item.Title}}</v-card-title>

                                    <v-card-subtitle>{{item.Overview}}</v-card-subtitle>

                                    <v-card-actions style="width:100%">
                                        <v-btn class="ml-2 mr-2" fab icon height="40px" right width="40px">
                                            <v-icon>{{item.Icon}}</v-icon>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn capitalize text v-if="item.ReadMoreContent">
                                            View More
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                            </div>
                        </v-card>        
                        
                    </template>

                </div>

                <div class="more-tiles-wrapper mr-4 ml-4 mt-6">
                    <h3 class="title ml-4 mr-4 center-align">Discover more about this trip</h3>
                    <v-layout class="tile-section" row wrap ma-0>


                        <template v-for="(item, index) in infoPages">
                            <v-flex xs6 sm6 md6 mt-4 :key="index" v-if="!item.AppearOn || (item.AppearOn && new Date() >= new Date(item.AppearOn))">
                                <v-card flat class="tile-wrapper pa-0 mr-2" :class="(index+1)%2 !== 0 ? 'pr-1' : 'pl-1'" @click="$parent.fn_gotoInfoPage(item.Path)">
                                    <v-img :lazy-src="$root.imagePlaceholder" height="120" :src="item.ImageName"></v-img>
                                    <div class="tile-title">{{item.Title}}</div>
                                </v-card>
                            </v-flex>   
                        </template>
                        
                    </v-layout>
                </div>
                
            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getTopTilesData">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->
        
    </div>
</template>

<script>
import axios from 'axios'
//import { CometChat } from "@cometchat-pro/chat";

  export default {
    data () {
      return {
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
          'deep-purple accent-4',
        ],
        offsetTop: 0,
        topTiles:null,
        // snackbarMsg:false,
        loadingTiles:false,
        infoPages:[
            {'Title':'Photo Gallery', 'Path':'photoalbum', 'ImageName':require('../assets/2024/Asia_PhotosBanner_240x175.png')},
            {'Title':'Hotel Info', 'Path':'resort', 'ImageName':require('../assets/2024/Asia_ResortTile_240x175.png')},
            {'Title':'Info Booth', 'Path':'infobooth', 'ImageName':require('../assets/2024/Asia_InfoBoothBanner_240x175.png')},
            {'Title':'Evening Events', 'Path':'eveningevents', 'ImageName':require('../assets/2024/Asia_EveningEventBanner_240x175.png')},
            {'Title':'My Activities', 'Path':'activities', 'ImageName':require('../assets/2024/Asia_MyActivitiesTile_240x175.png')},
            {'Title':'Activities', 'Path':'allactivities', 'ImageName':require('../assets/2024/Asia_ActivitiesTile_240x175.png')},
            {'Title':'Meals at Leisure', 'Path':'restaurantreservation', 'ImageName':require('../assets/2024/Asia_RestaurantsTile_240x175.png')},
            {'Title':'Key Locations', 'Path':'keylocations', 'ImageName':require('../assets/2024/Asia_KeyLocationsTile_240x175.png')},
            // {'Title':'Gifts', 'Path':'gifts', 'ImageName':require('../assets/2024/Asia_GiftsBanner_240x175.png')},
            {'Title':'Buy-In Package', 'Path':'buyininformation', 'ImageName':require('../assets/2024/Asia_BuyInInformationTile_240x175.png')},
            {'Title':'Safety & Security', 'Path':'safetyandsecurity', 'ImageName':require('../assets/2024/Asia_SafetySecurityTile_240x175.png')},
            // {'Title':'Shuttle Services', 'Path':'shuttle', 'ImageName':require('../assets/Asia_ShuttleTile_200x200.png')},
            // {'Title':'Departure Info', 'Path':'departureinfo', 'ImageName':require('../assets/Asia_DepartureTile_200x200_2023_Updated.png'), 'AppearOn':'2022-10-30'},
            //{'Title':'Head Shot', 'PageName':'Head Shot', 'Path':'headshotasia', 'ImageName':require('../assets/Asia_HeadShotBanner_200x200.png')}
            // {'Title':'Departure Info', 'Path':'departureinfo', 'ImageName':require('../assets/Asia_DepartureTile_200x200_2023_Updated.png')},
        ],
        //unreadMessageCount:0
        // slides: [
        //     {'src':require('../assets/carousel_image1.png')},
        //     {'src':require('../assets/carousel_image2.jpg')},
        //     {'src':require('../assets/carousel_image3.jpg')},
        //     {'src':require('../assets/carousel_image4.jpg')},
        //     {'src':require('../assets/carousel_image5.jpg')},
        //     {'src':require('../assets/carousel_image6.jpg')},
        // ],
      }
    },
    methods: {
        onScroll () {
            this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
        },

        // fn_readMore: function(item){
        //     let readMoreContent = JSON.parse(item.ReadMoreContent);
        //     if(readMoreContent.ReadMoreType == 1){
        //         this.fn_openInternalPage(readMoreContent.Details);
        //     }
        //     else if(readMoreContent.ReadMoreType == 2){
        //         this.fn_openExternalPage(readMoreContent.Details);
        //     }
        //     else if(readMoreContent.ReadMoreType == 3){
        //         this.fn_openMoreContent(item);
        //     }
        // },

        // fn_openInternalPage: function(details){
        //     if(details.PageName === 'Buy-In Packages')
        //         this.$parent.fn_gotoInfoPage('buyininformation');
        //     else if(details.PageName === 'Hotel')
        //         this.$parent.fn_gotoInfoPage('resort');
        //     else if(details.PageName === 'My Activities')
        //         this.$parent.fn_gotoInfoPage('activities');
        //     else if(details.PageName === 'Activities')
        //         this.$parent.fn_gotoInfoPage('allactivities');
        //     else if(details.PageName === 'Travel')
        //         this.$parent.fn_gotoInfoPage('travel');
        //     else if(details.PageName === 'FAQ')
        //         this.$parent.fn_gotoInfoPage('faq');
        //     else if(details.PageName === 'Emergency')
        //         this.$parent.fn_gotoInfoPage('emergency');
        //     else if(details.PageName === 'Agenda'){
        //         let agendaDay = new Date(details.AgendaDate).getDate();
        //         this.$parent.fn_tabChange(1, agendaDay);
        //     }
        // }, 

        // fn_openExternalPage: function(pageUrl){
        //     this.getData('OpenBrowser', pageUrl, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },

        // fn_openMoreContent: function(cardItem){
        //     this.currentCardItem = null;
        //     this.currentCardItem = cardItem;
        //     this.currentCardItem.ReadMoreContent = JSON.parse(this.currentCardItem.ReadMoreContent);
        //     this.contentDialog = true;
        // },

        // fn_getToolbarImage: function(){
        //     let toolbarImage = '';
        //     if(window.screen.width<=600){
        //         toolbarImage = require("../assets/PlatinumClub_TripBanners_Mobile_640x80.png");
        //     }
        //     else{
        //         toolbarImage = require("../assets/TripBanners_Mobile_PC_1080x80.png");
        //     }
        //     return toolbarImage;
        // },

        fn_gotoNotificationPage: function(){
            this.$router.push('/notifications');
        },

        fn_getTopTilesData: function(type){
            let self = this;
            this.loadingTiles = true;
            this.getData('Hero', '/Hero', 
                {
                    onSuccess: function(response){
                        console.log(JSON.stringify(response));
                        self.topTiles = response.homeTopTiles;
                        self.topTiles && self.topTiles.length >= 0 ? self.loadingTiles = false : ''; 
                        !type ? self.fn_getTopTilesFromServer(response.ModifiedDate) : '';
                        // self.loadingTiles = false;
                        // self.snackbarMsg = false;
                    },
                    onError: function(error){
                        console.log(error);
                        self.loadingTiles = false;
                        // self.snackbarMsg = false;
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },

        fn_getTopTilesFromServer: function(modifiedDate){
            // let self = this;
            // this.loadingTiles = true;
            
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    // this.fn_getTopTilesData('error');
                }
                else{
                    let headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                        }
                    };

                    let queries = modifiedDate ? "&ModifiedDate="+modifiedDate : '';

                    axios.get(this.$root.baseUrl + "/api/mobileapp/pwa/hometoptiles?excludecontent=true"+queries, headersObj, {timeout:30000})
                    .then(response => {
                        // this.loading = false;
                        console.log(response);
                        if(response.data.Message !== 'No Update Found!'){
                            this.fn_postHeroTilesData(response.data);
                        }
                        
                        // this.topTiles = response.data.homeTopTiles;
                        // this.topTiles && this.topTiles.length >= 0 ? this.loadingTiles = false : ''; 
                        
                    }).catch(e => {
                        // this.loading = false;
                        console.log(e);
                        // this.fn_getTopTilesData('error');
                    })
                }
            })
        },

        fn_postHeroTilesData: function(HeroTilesData){
            let self = this;
            this.postData("Hero", HeroTilesData, {
                onSuccess: function(response){
                    console.log(response);
                    self.fn_getTopTilesData('saved');
                },
                onError: function(error){
                    console.log(error);
                }
            });
        },

        fn_navigatetopage: function(){
            this.$router.push({path:'/gcasia/profile'});
        },

        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     console.log(updateObj)
        //     if(updateObj.pageName==='Hero'){
        //         if(this.topTiles && this.topTiles.length >= 0){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             this.fn_getTopTilesData();
        //         }
        //     }
        // }
    },
    mounted() {
        this.$root.fn_postClickMetrics('Explore', 'Default', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getTopTilesData();
        this.$root.fn_getUnreadMessageCount();
        // if(localStorage.getItem("pn_registered_"+this.$root.tripSiteId)){
        //   CometChat.getUnreadMessageCount().then(array => {
        //     console.log("Message count fetched", array);
        //     for(let group in array){
        //         for(let key in array[group]){
        //             this.unreadMessageCount += array[group][key];
        //         }
        //     }
        //   }, error => {
        //     console.log("Error in getting message count", error);
        //   });
        // }
        // this.$root.fn_getNotificationCount();
    },
  }
</script>

<style>
.gcasia .slider-card-wrapper::-webkit-scrollbar {
  height: 5px !important;
  /* -webkit-overflow-scrolling: auto !important; */
}
  
.gcasia .slider-card-wrapper::-webkit-scrollbar-thumb {
  background-color: #004987 !important;
  border-radius: 5px !important;
}

.gcasia .slider-card-wrapper::-webkit-scrollbar-track-piece:end {
  margin-right: 128px !important; 
  background-color: #eee !important;
}

.gcasia .slider-card-wrapper::-webkit-scrollbar-track-piece:start {
  margin-left: 128px !important;
  background-color: #eee !important;
}
</style>