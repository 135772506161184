<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/Demo_HealthSafetyBanner_420x200.png"></v-img>

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <accordians :content="accordianContent" v-if="accordianContent"></accordians>
            </template>
            
        </div>

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('safetyandsecurity')">Refresh Now</v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import overview from './sub_components/overview'
import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        'accordians':accordians
    },
    methods:{
        fn_updateAvailable: function(dataObj){
            let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
            if(updateObj.pageName==='safetyandsecurity'){
                if(this.overviewContent || this.accordianContent){
                    this.snackbarMsg = true;
                }
                else{
                    this.fn_getData('safetyandsecurity');
                }
            }
        }
    },
    mounted(){
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getData('safetyandsecurity');
    }
}
</script>