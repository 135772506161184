<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/2024/Asia_RestaurantsBanner_1125x450.png"></v-img>

                <!-- <accordians :content="accordianContent" v-if="accordianContent"></accordians> -->
                <template v-if="listContents">
                    <!-- <v-card class="ma-4" v-for="(item, index) in listContents" :key="index"> -->
                    <div v-for="(item, index) in listContents" :key="index">
                        <v-card class="ma-4">

                            <v-list>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon class="pink lighten-1" dark>dinner_dining</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="title">{{item.RestaurantName}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.RestaurantAreaName}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <v-divider class="mx-4"></v-divider>

                            <v-card-text :class="{'pb-0':item.Description}">
                                <v-layout row wrap ma-0>
                                    <v-flex xs6 sm6 md6>
                                        <div><strong>Reserved For</strong></div>
                                        <div>{{item.AttendeeName}}</div>
                                    </v-flex>
                                    <v-flex xs6 sm6 md6>
                                        <div><strong>Number of Party</strong></div>
                                        <div>{{item.NumbersofParty}}</div>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12 class="pt-4">
                                        <div><strong>Reservation Time</strong></div>
                                        <div>{{item.ReservationTime | moment("ddd, MMM Do YYYY, hh:mm a")}}</div>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>

                            <v-card-actions @click="show = !show" v-if="item.Description">
                                <v-btn color="primary" text>{{show ? 'Less' : 'More'}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn icon>
                                    <v-icon>{{ show ? 'expand_less' : 'expand_more' }}</v-icon>
                                </v-btn>
                            </v-card-actions>

                            <!-- <v-divider class="mx-4"></v-divider> -->

                            <v-expand-transition>
                                <div v-show="show">
                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text>
                                        <v-layout row wrap ma-0>
                                            <v-flex xs12 sm12 md12>
                                                <div><strong>Description</strong></div>
                                                <div v-html="item.Description"></div>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </div>
                            </v-expand-transition>

                            <!-- <v-card-text>
                                <v-layout row wrap ma-0>
                                    <v-flex xs12 sm12 md12>
                                        <div><strong>Description</strong></div>
                                        <div>{{item.Description}}</div>
                                    </v-flex>
                                </v-layout>
                            </v-card-text> -->

                            <!-- <v-divider class="mx-4"></v-divider> -->

                            </v-card>

                            <v-card class="ma-4">
                                <!-- <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/GCAmercasResortMap_400x220.png"></v-img>
                                <div class="right-align">
                                    <a type="maplink" class="pr-2" href="/assets/gcasia/resort_map_9.pdf" target="_blank">View Map</a>
                                </div> -->
                                <v-card-text :class="{'pb-0':item.BusRouteDescription}">
                                    <!-- <div class="pb-2 text-subtitle-1">
                                        Route Information
                                    </div> -->
                                    <v-layout row wrap ma-0>
                                        <v-flex xs6 sm6 md6>
                                            <div><strong>Bus Route</strong></div>
                                            <div>{{item.BusRouteName}}</div>
                                        </v-flex>
                                        <v-flex xs6 sm6 md6 v-if="item.BusStopName">
                                            <div><strong>Bus Stop</strong></div>
                                            <div>{{item.BusStopName}}</div>
                                        </v-flex>

                                        <!-- <v-flex xs12 sm12 md12 pt-4 right-align>
                                            <v-btn class="" color="white" small text style="background:rgba(0,0,0,0.4)" capitalize>Download Map</v-btn>
                                        </v-flex> -->

                                        <!-- <v-flex xs12 sm12 md12 class="pt-4">
                                            <div><strong>Route Description</strong></div>
                                            <div v-html="item.BusRouteDescription"></div>
                                        </v-flex> -->
                                    </v-layout>
                                </v-card-text>

                                <v-card-actions @click="showMapDescription = !showMapDescription" v-if="item.BusRouteDescription">
                                    <v-btn color="primary" text>{{showMapDescription ? 'Less' : 'More'}}</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn icon>
                                        <v-icon>{{ showMapDescription ? 'expand_less' : 'expand_more' }}</v-icon>
                                    </v-btn>
                                </v-card-actions>

                                <v-expand-transition>
                                    <div v-show="showMapDescription">
                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-text>
                                            <v-layout row wrap ma-0>
                                                <v-flex xs12 sm12 md12>
                                                    <div><strong>Description</strong></div>
                                                    <div v-html="item.BusRouteDescription"></div>
                                                </v-flex>
                                            </v-layout>
                                        </v-card-text>
                                    </div>
                                </v-expand-transition>
                            </v-card>

                            <v-divider class="mx-8" v-if="listContents.length - 1 != index"></v-divider>
                    </div>
                </template>

                <v-card :class="{'ma-4':listContents && listContents.length}" v-if="overviewContent" id="overview_content" :flat="!listContents || (listContents && !listContents.length) ? true : false">
                    <v-card-actions @click="fn_toggleOverview" v-if="listContents && listContents.length">
                        <v-btn color="primary" text>Overview</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon>
                            <v-icon>{{ showOverview ? 'expand_less' : 'expand_more' }}</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                        <div v-show="showOverview">
                            <v-card-text class="pa-0">
                                <overview :content="overviewContent" v-if="overviewContent"></overview>
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </v-card>
                

                <div v-if="!overviewContent && (!listContents || listContents && !listContents.length)" class="pa-4 center-align light-opacity-color">You have no dinner reservation yet</div>
            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_refresh">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

    </div>
</template>

<script>
import overview from './sub_components/overview'
// import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        // 'accordians':accordians
    },
    data() {
        return {
            show:false,
            showMapDescription:false,
            showOverview:false
        }
    },
    methods:{
        fn_openExternalPage: function(pageUrl){
            this.getData('OpenBrowser', pageUrl, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_toggleOverview: function(){
            this.showOverview = !this.showOverview
            if(this.showOverview){
                setTimeout(() => {
                    document.getElementById("overview_content").scrollIntoView({behavior: 'smooth'});
                }, 300);
            }
        },

        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     if(updateObj.pageName==='restaurantreservation'){
        //         if(this.overviewContent || (this.listContents && this.listContents.length)){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             // this.fn_getData('restaurantreservation');
        //             // this.fn_getData('restaurantreservation', () => {
        //             //     this.showOverview = !this.listContents || (this.listContents && !this.listContents.length) ? true : false;
        //             //     console.log(this.showOverview);
        //             // });
        //             this.fn_refresh();
        //         }
        //     }
        // },

        fn_refresh: function(){
            this.fn_getPageDataFromServer('restaurantreservation', () => {
                this.showOverview = !this.listContents || (this.listContents && !this.listContents.length) ? true : false;
                console.log(this.showOverview);
            });
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Meals at Leisure', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        // this.fn_getData('restaurantreservation', () => {
        //     this.showOverview = !this.listContents || (this.listContents && !this.listContents.length) ? true : false;
        // });
        this.fn_refresh();
    }
}
</script>