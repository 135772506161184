<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img :lazy-src="$root.imagePlaceholder" height="168" src="../assets/2024/EMEA_KeyLocationsBanner_1125x450.png"></v-img>

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <!-- <template v-if="listContents && listContents.length">
                
                    <v-card class="ma-4" v-for="(item, index) in listContents" :key="index">
                        <div class="title-wrapper pl-4 pr-4 pt-2 pb-2">
                            <h3 class="title">{{item.Title}}</h3>
                        </div>
                        <v-divider class="mr-4 ml-4"></v-divider>
                        <v-expand-transition>
                            <v-card-text class="pb-4" style="max-height:200px;overflow:hidden">
                                <div v-html="item.Content"></div>
                            </v-card-text>
                        </v-expand-transition>

                        <v-card-actions @click="fn_openMoreDetails(item)">
                            <v-btn color="primary" text>View More</v-btn>
                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-card-actions>
                        
                    </v-card>             
                    
                </template> -->
                

                <accordians :content="listContents" v-if="listContents && listContents.length"></accordians>
            </template>
            
        </div>

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('activities')">Refresh Now</v-btn>
            </template>
        </v-snackbar>

        <!-- <v-dialog v-model="contentDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card v-if="currentItem">
                <v-card-title class="text-h5">{{currentItem.Title}}</v-card-title>
                <v-card-text class="pt-4">
                    <v-tabs v-model="tabIndex" fixed-tabs background-color="#f0f0f0" :slider-color="$root.themeColor">
                        <v-tab>Details</v-tab>
                        <v-tab>Attendees</v-tab>

                        <v-tab-item>
                            <div class="pt-4" v-html="currentItem.Content"></div>
                        </v-tab-item>

                        <v-tab-item>
                            <div class="pt-4">

                                <div v-for="(item, index) in currentItem.Attendees" :key="index">

                                    <v-subheader color="grey">{{item.AttendeeName}}</v-subheader>

                                    <v-card v-for="(question, questionIndex) in item.Questions" :key="questionIndex">
                                        <v-card-text>
                                            <p><strong>Q.  {{question.Question}}</strong></p>
                                            <p>A. {{question.Response}}</p>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="contentDialog = false">close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->

    </div>
</template>

<script>

import overview from './sub_components/overview'
import accordians from './sub_components/listcontents'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        'accordians':accordians
    },
    data(){
        return{
            // contentDialog:false,
            // currentItem:null,
            // tabIndex:0
        }
    },
    methods:{
        // fn_openMoreDetails: function(item){
        //     this.currentItem = item;
        //     this.tabIndex = 0;
        //     this.contentDialog = true;
        // },

        fn_updateAvailable: function(dataObj){
            let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
            if(updateObj.pageName==='activities'){
                if(this.overviewContent || (this.listContents && this.listContents.length)){
                    this.snackbarMsg = true;
                }
                else{
                    this.fn_getData('activities');
                }
            }
        },

    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Activities', null);
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        // this.fn_getData('activities');
        this.fn_getPageDataFromServer('activities');
    }
}
</script>