<template>
    <div>
        <template v-for="(item, index) in content">
            
            <v-card class="ma-4" :key="index">
                <div class="title-wrapper">
                    <!-- <div class="title-icon" :class="colorClasses[index]"> -->
                    <div class="title-icon" :class="colorClasses[(index % colorClasses.length)]">
                        <v-icon color="white" class="mr-4">{{item.Icon}}</v-icon>
                    </div>
                    <h3 class="title">{{item.Title}}</h3>
                    <!-- <v-btn color="success" @click="addToCalendar(item)">Add to Calendar</v-btn> -->
                </div>
                <v-divider class="mr-4 ml-4"></v-divider>
                <v-card-text class="pb-4" :class="{'short-card-text':item.ReadMoreContent}">
                    <!-- <p><strong>Time:</strong> {{item.DisplayTime}}</p> -->
                    <div class="pb-3" v-if="item.DisplayTime"><strong>Time: </strong> {{item.DisplayTime | moment("ddd, MMM Do YYYY")}}</div>
                    <div v-if="item.Attendees.length">
                        <div><strong>Attendees</strong></div>
                        <v-layout row wrap ma-0>
                            <v-flex xs6 sm6 md6 pt-2 v-for="(attendee, attendeeIndex) in item.Attendees" :key="attendeeIndex">
                                <div>{{attendee.AttendeeName}}</div>
                                <div light-opacity-color>{{attendee.AttendeeType}}</div>
                            </v-flex>
                        </v-layout>

                        <v-divider class="mt-4 mb-4"></v-divider>
                    </div>
                    <div v-html="item.Content"></div>
                </v-card-text>

                <v-card-actions>

                    <v-btn color="primary" @click="readMore(item)" capitalize text v-if="item.ReadMoreContent">
                        <v-icon left>mdi-chevron-down</v-icon>
                        View More
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn capitalize color="success" text @click="addToCalendar(item)">
                        <v-icon left>event</v-icon> Add to Calendar
                    </v-btn>
                </v-card-actions>
            </v-card>        
            
        </template>
        
    </div>
</template>

<script>
    export default{
        props:['content', 'readMore', 'addToCalendar'],
        data(){
            return{
                colorClasses:['light-blue', 'light-pink', 'light-purple', 'light-green']
            }
        }
    }
</script>