<template>
    <div>
        <v-expansion-panels accordion focusable>
            <v-expansion-panel v-for="(item, index) in content" :key="index">
                <v-expansion-panel-header class="font-medium pa-4">{{item.title}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- <div v-html="item.content" v-if="item.content"></div> -->
                    <v-card flat>
                        <v-card-text class="pa-0">
                            <div v-html="item.content" v-if="item.content"></div>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default{
        props:['content']
    }
</script>