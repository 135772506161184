<template>
    <div>
        <v-btn @click="$router.go(-1)">Back</v-btn>
        <div class="pa-4" style="">

            <v-text-field
                :value="fireToken"
                rows="10"
            ></v-text-field>
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                fireToken:null
            }
        },
        mounted() {
            this.$root.fn_getToken(token=>{
                console.log(token);
                if(token == 'error'){
                    this.$root.fn_showToast('exception');
                }
                else{
                    this.fireToken = token;
                }
            })
        },
    }
</script>