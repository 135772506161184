<template>
  <div>
      <!-- <v-alert :color="$root.themeColor" style="border-radius:0;position:sticky;top:0;z-index:1" dense type="info" :value="true">
          Choose attendee to chat with
      </v-alert> -->
      <v-toolbar :color="$root.themeColor" flat dark>
          <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
          </v-btn>
          <v-toolbar-title>Social Feed</v-toolbar-title>
          <!-- <div slot="extension" solo><v-switch @change="fn_isShowGuest" v-model="IsShowGuest" label="Include Guest"></v-switch></div> -->
          <!-- <v-text-field dense grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field> -->
          
      </v-toolbar>

      <div>

        <template>
          <v-list class="pa-0 socialfeed_topsection" two-line>
            <template>
              <v-list-item class="zindex2">

                <v-list-item-avatar class="grey lighten-3">
                    <!-- <template v-if="item.ImageUrl"><v-img alt="winnerimage" :src="item.ImageUrl" lazy-src="../../../assets/person.svg"></v-img></template>
                    <template v-else>{{fn_abbreviatedName(item.WinnerName)}}</template> -->

                    <template>{{fn_abbreviatedName($root.tripOnLoadData.AttendeeInfo.AttendeeName)}}</template>
                    
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>
                      <!-- <v-text-field class="addcaptiontextbox" hide-details placeholder="Write something here..." outlined></v-text-field> -->
                      <div class="addcaptiontextbox_custom" @click="fn_gotoAddTextPage">
                        <div>What's on your mind?</div>
                      </div>
                    </v-list-item-title>
                </v-list-item-content>

                <!-- <v-list-item-action>
                  <v-btn icon @click="fn_gotoAddImagePage">
                    <v-icon size="28" style="color: #609f97;">mdi-image-multiple</v-icon>
                  </v-btn>
                </v-list-item-action> -->
              </v-list-item>

                <v-divider class="customdivider" ></v-divider>
            </template>
          </v-list>
        </template>

        <template v-if="linearLoading">
          <v-progress-linear indeterminate :color="$root.themeColor"></v-progress-linear>
        </template>

        <template v-if="!error">
          <div class="pull-to-refresh" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
            <!-- <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
              <div slot="no-more"></div>
              <div slot="no-results" class="pa-4 light-opacity-color">No post found!</div>
            </infinite-loading> -->
            <div class="pull-to-refresh-indicator" style="max-height: 50px !important;" :style="{ height: `${pullHeight}px` }">
              <span v-if="pullHeight > 0 && !isRefreshing">Pull down to refresh...</span>
              <span v-if="isRefreshing"><img style="max-width:30px" src="../assets/2024/loading-gif.gif" /></span>
            </div>
            <div>
              <template v-for="(item, index) in Items">
                  <div :key="index" class="zindex2">
                    
                    <v-list class="pa-0 zindex2">
                      <template >
                        <v-list-item style="background-color: white;" class="zindex2">
                          <v-list-item-avatar style="align-self: baseline; margin-top: 10px;" class="grey lighten-3">
                            <template v-if="item.IsAdminPost">
                              <v-img alt="winnerimage" src="../assets/2024/GoldClub_ChatLogo_Final.jpg" lazy-src="../../../assets/person.svg"></v-img>
                            </template>
                            <template v-else-if="item.ProfilePicture"><v-img alt="winnerimage" :src="item.ProfilePicture" lazy-src="../../../assets/person.svg"></v-img></template>
                            <template v-else>{{fn_abbreviatedName(item.PostedBy ? item.PostedBy : item.PostedByAlias)}}</template>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title class="usertitle">
                              <span class="font-weight-medium">{{item.PostedBy ? item.PostedBy : item.PostedByAlias}}</span>
                              <span v-if="item.PostUserTags && item.PostUserTags.length>0"> is with 
                                <span class="font-weight-medium">{{item.PostUserTags[0].Name != null ? item.PostUserTags[0].Name : item.PostUserTags[0].Alias}}</span>
                                <span v-if="item.PostUserTags && item.PostUserTags.length>1"> and <span class="font-weight-medium" @click="fn_showTaggedList(item.PostID)">{{item.PostUserTags && item.PostUserTags.length - 1}} others.</span></span>
                              </span>
                            </v-list-item-title>
                            
                            <v-list-item-subtitle>{{formatDate(item.CreatedAt)}}</v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <!-- <v-btn icon>
                              <v-icon :color="$root.themeColor">mdi-dots-vertical</v-icon>
                            </v-btn> -->

                            <v-menu bottom right :offset-y="true" content-class="viewactns_actionbtns viewactns fixedcolumnactions" min-width="200px">
                              <template v-slot:activator="{ on, attrs }">
                                  <!-- <v-btn aria-label="Open Action menu" text icon><v-icon style="font-size:18px" v-bind="attrs" v-on="on">more_vert</v-icon></v-btn> -->
                                  <v-btn v-if="item.CanDelete || item.CanEdit" aria-label="Open Action menu" icon>
                                    <v-icon v-bind="attrs" v-on="on" :color="$root.themeColor">mdi-dots-vertical</v-icon>
                                  </v-btn>
                              </template>
                              <v-list dense class="pt-0 pb-0">
                                  <v-list-item v-if="item.CanEdit" @click="fn_editPost(index)">
                                      <v-list-item-title aria-label="Click to delete post">Edit Post</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item v-if="item.CanDelete" @click="fn_deletePost(index)">
                                      <v-list-item-title aria-label="Click to delete post">Delete Post</v-list-item-title>
                                  </v-list-item>
                              </v-list>
                          </v-menu>

                          </v-list-item-action>
                        
                        <!-- <div style="width: 100%;">
                          <img width="100%" src="../assets/PlatinumClub_ResortInfoTile_200x200_2023_Updated.jpg"/>
                        </div> -->
                    
                        </v-list-item>
                      </template>
                    </v-list>

                    <!-- <v-card flat class="zindex2">
                        <div>
                          <div v-if="item.Caption != null" class="image_caption pl-4 pr-4 pb-2" v-html="item.Caption"></div>
                        </div>
                        <v-card-actions class="pa-0y headshot_image_container">
                          <v-img contain :src="'data:image/png;base64, ' + item.MediaUrl" lazy-src="https://picsum.photos/10/6?image=15" aspect-ratio="1" class="grey lighten-2" v-if="item.MediaUrl">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                          </v-img>
                          <v-img :src="$root.imagePlaceholder" aspect-ratio="1" v-else></v-img>
                        </v-card-actions>
                    </v-card> -->

                    <div class="posted_content_section" style="width: 100%;">
                      <div class="postedcontentsection">
                        <div v-if="item.MediaUrl != null" class="imagedatatype">
                          <div v-if="item.Caption != null" class="image_caption pl-4 pr-4 pb-2" v-html="item.Caption"></div>
                          <img v-if="item.MediaUrl && item.MediaUrl.includes('base64')"  style="display: block;" width="100%" :src="item.MediaUrl"/>
                          
                          <v-img v-else-if="item.MediaUrl && !item.MediaUrl.includes('base64')" :src="item.MediaUrl" lazy-src="../assets/2024/FeedPost_PlaceholderImage.png" aspect-ratio="1" class="grey lighten-2">
                              <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <img style="max-width:30px" src="../assets/2024/loading-gif.gif" />
                                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                              </template>
                          </v-img>
                          <!-- <v-img :src="$root.imagePlaceholder" aspect-ratio="1" v-else></v-img> -->
                        </div>
                        <div v-else class="textdtatype" :class="{'longtext' : item.Content && item.Content.length> 100}">
                          <div class="text_data pl-4 pr-4 pb-3" v-html="item.Content"></div>
                        </div>
                        
                      </div>
                      <!-- <div class="likeandcommentsection" v-if="(item.LikeCount != null && item.LikeCount>0) || (item.CommentCount != null && item.CommentCount >1)">
                        <v-layout row wrap ma-0>
                          <v-flex xs6 sm6 md6 pa-2>
                            <div class="likesection pl-2">
                              <div v-if="item.LikeCount != null && item.LikeCount>0">
                                <v-icon :color="$root.themeColor">mdi-thumb-up</v-icon>
                                <span v-if="item.IsLiked" class="pl-2">you and 10 others</span>
                                <span v-else class="pl-2">10</span>
                              </div>
                            </div>
                          </v-flex>
                          <v-flex xs6 sm6 md6 pa-2 class="text-right">
                            <div class="commentsection pr-2">
                              <span>{{ item.CommentCount }} <span v-if="item.CommentCount >1">Comments</span><span v-else>Comment</span></span>
                            </div>
                          </v-flex>
                        </v-layout>
                        <v-divider class="ml-4 mr-4"></v-divider>
                      </div> -->
                      <div class="likeandcommentsection">
                        <v-layout row wrap ma-0>
                          <v-flex xs6 sm6 md6 class="text-left pa-2 pl-3">
                              <v-btn v-if="item.IsLikedByUser" icon class="mr-0 action_btn width_20" @click="fn_unLikePost(index)">
                                <v-icon :color="$root.themeColor">mdi-heart</v-icon>
                                <!-- <span @click="fn_showLikeList(item.PostID)" v-if="item.LikeCount != null && item.LikeCount>1" class="pl-2 btn_text">{{item.LikeCount}}</span> -->
                              </v-btn>
                              <v-btn v-if="item.IsLikedByUser && item.LikeCount != null && item.LikeCount>1" icon class="mr-0 action_btn width_20" @click="fn_showLikeList(item.PostID)">
                                <!-- <v-icon @click="fn_unLikePost(index)" :color="$root.themeColor">mdi-heart</v-icon> -->
                                <span class="pl-0 btn_text">{{item.LikeCount}}</span>
                              </v-btn>
                              <v-btn v-if="!item.IsLikedByUser" icon class="mr-0 action_btn width_20" @click="fn_likePost(index)">
                                <v-icon>mdi-heart-outline</v-icon>
                                <!-- <span @click="fn_showLikeList(item.PostID)" v-if="item.LikeCount != null && item.LikeCount>0" class="pl-2 btn_text">{{item.LikeCount}}</span> -->
                              </v-btn>
                              <v-btn v-if="!item.IsLikedByUser && item.LikeCount != null && item.LikeCount>0" icon class="mr-0 action_btn width_20">
                                <!-- <v-icon @click="fn_likePost(index)">mdi-heart-outline</v-icon> -->
                                <span @click="fn_showLikeList(item.PostID)" class="pl-0 btn_text">{{item.LikeCount}}</span>
                              </v-btn>
                              <v-btn icon class="ml-2 action_btn width_24" @click="fn_comment(index)">
                                <!-- <v-icon>mdi-chat-outline</v-icon> -->
                                <img class="comment_icon" src="../assets/2024/instagram-comment-icon.svg"/>
                              </v-btn>
                              <v-btn v-if="item.CommentCount != null && item.CommentCount >0" icon class="mr-0 action_btn width_20" @click="fn_comment(index)">
                                <span style="padding-top: 2px;" class="btn_text">{{item.CommentCount}}</span>
                              </v-btn>
                            
                          </v-flex>
                          <v-flex xs6 sm6 md6 class="text-right pa-2 pr-3">
                            <v-btn v-if="item.IsUserTagged" icon class="mr-0 action_btn" @click="fn_removeTag(index)">
                              <v-icon>mdi-tag</v-icon>
                              <!-- <span v-if="item.CommentCount != null && item.CommentCount >1" class="pl-3 btn_text">{{item.CommentCount}}</span> -->
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </div>
                      
                    </div>
                  </div>
                  <v-divider class="customdivider" :key="index+'divider'" v-if="index != Items.length - 1"></v-divider>
              </template>
              <infinite-loading ref="infiniteLoading" :identifier="infiniteIdv2" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results" class="pa-4 light-opacity-color">No post found!</div>
              </infinite-loading>
            </div>
          </div>
        </template>

        <template v-else>
          <div style="text-align:center;" class="pa-4">
              <p>Something went wrong. Please try again.</p>
              <br>
              <v-btn outlined @click="fn_debounceSearch"><v-icon left>refresh</v-icon> Retry</v-btn>
          </div>
        </template>
      </div>

      <dialog-attendee-info ref="ref_dialogAttendeeInfo"></dialog-attendee-info>
      <dialog-addpost ref="ref_dialogAddPost" :fn_addcontent="fn_addTextContent" :fn_updateContentPostID="fn_UpdateItemID" :fn_updateData="fn_updateItemData"></dialog-addpost>
      <!-- <dialog-addimage ref="ref_dialogAddImage" :fn_addcontent="fn_addTextContent"></dialog-addimage> -->
      <dialog-attendeelikelist ref="ref_dialogAttendeeLikeList"></dialog-attendeelikelist>
      <dialog-attendeetagedlist ref="ref_dialogAttendeeTaggedList"></dialog-attendeetagedlist>


      <v-dialog v-model="contentDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card v-if="postDataObj.ImageBase64">
                <!-- <v-card-title class="text-h5">{{currentCardItem.Title}}</v-card-title> -->
                <v-card-title class="title pb-4 pl-4" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn icon class="mr-4" @click="contentDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    Create Post
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content pt-4">
                    <div>
                      <v-textarea rows="1" outlined auto-grow placeholder="Enter text here..." v-model="postDataObj.Caption"></v-textarea>
                    </div>
                    <div>
                      <img style="display: block;" width="100%" :src="'data:image/png;base64, ' + postDataObj.ImageBase64"/>
                      <!-- <v-img :src="'data:image/png;base64, ' + postDataObj.ImageBase64" lazy-src="https://picsum.photos/10/6?image=15" aspect-ratio="1" class="grey lighten-2">
                          <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                          </template>
                      </v-img> -->
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn block :color="$root.themeColor" dark @click="fn_uploadfile()">Post</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-bottom-sheet scrollable inset v-model="bottomSheet" style="border-radius:24px" content-class="addcomment_dialog">
            
                <v-card style="border-top-left-radius:24px;border-top-right-radius:24px" height="100%">
                    <v-card-title primary-title class="pl-6">
                      <v-btn icon class="mr-4" @click="bottomSheet=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                      </v-btn>
                      Comments

                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pl-0 pr-0" left-align style="height: 80%; overflow: auto;">
                      <template v-for="(item, index) in CommentItems">
                        <div :key="index" class="zindex2">
                          <v-list class="pa-0 zindex2">
                            <template >
                              <v-list-item class="zindex2 pl-4 pr-4">
                                <v-list-item-avatar class="grey lighten-3 align_top">
                                  <template v-if="item.ProfilePicture"><v-img alt="winnerimage" :src="item.ProfilePicture" lazy-src="../../../assets/person.svg"></v-img></template>
                                  <template v-else>{{fn_abbreviatedName(item.UserName ? item.UserName : item.UserAlias)}}</template>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title v-text="item.UserName ? item.UserName : item.UserAlias"></v-list-item-title>
                                  <v-list-item-subtitle>{{formatDate(item.CreatedAt)}}</v-list-item-subtitle>
                                  <v-list-item-subtitle class="comment_text pt-2">{{item.Content}}</v-list-item-subtitle>
                                  <v-layout row wrap ma-0>
                                    <v-flex xs6 sm6 md6 class="text-left pa-0 pl-0">
                                      <!-- <div class="d-inline-block reply_text pr-0 pt-0">
                                        <v-btn v-if="item.IsLikedByUser" icon class="mr-4 action_btn" @click="fn_unLikeComment(index)">
                                          <v-icon :color="$root.themeColor" size="18px">mdi-heart</v-icon>
                                          <span v-if="item.LikeCount != null && item.LikeCount>1" class="pl-2 btn_text">{{item.LikeCount}}</span>
                                        </v-btn>
                                        <v-btn v-else icon class="mr-4 action_btn" @click="fn_likeComment(index)">
                                          <v-icon size="18px">mdi-heart-outline</v-icon>
                                          <span v-if="item.LikeCount != null && item.LikeCount>0" class="pl-2 btn_text">{{item.LikeCount}}</span>
                                        </v-btn>
                                      </div> -->
                                      <div style="color: rgba(0, 0, 0, 0.6);" @click="fn_openReplyDialog(index)" class="d-inline-block reply_text pl-0 pt-2">Reply</div>
                                    </v-flex>
                                  
                                  </v-layout>
                                  <v-list-item-subtitle v-if="item.ReplyCount > 0 && item.ReplyList && item.ReplyList.length == 0" @click="fn_showReplyList(index)" style="font-size: 10.5pt !important;" class="comment_text pt-2 pl-6">View {{item.ReplyCount}} reply</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action style="align-self: baseline; align-items: normal;">
                                  <div>
                                    <v-btn style="width: 20px" v-if="item.IsLikedByUser" icon class="mr-0 action_btn" @click="fn_unLikeComment(index)">
                                      <v-icon :color="$root.themeColor" size="18px">mdi-heart</v-icon>
                                      <!-- <span v-if="item.LikeCount != null && item.LikeCount>1" class="pl-2 btn_text">{{item.LikeCount}}</span> -->
                                    </v-btn>
                                    <v-btn style="width: 20px" v-else-if="!item.IsLikedByUser" icon class="mr-0 action_btn" @click="fn_likeComment(index)">
                                      <v-icon size="18px">mdi-heart-outline</v-icon>
                                      <!-- <span v-if="item.LikeCount != null && item.LikeCount>0" class="pl-2 btn_text">{{item.LikeCount}}</span> -->
                                    </v-btn>

                                    <v-menu bottom right :offset-y="true" content-class="viewactns_actionbtns viewactns fixedcolumnactions" min-width="200px">
                                      <template v-slot:activator="{ on, attrs }">
                                          <!-- <v-btn aria-label="Open Action menu" text icon><v-icon style="font-size:18px" v-bind="attrs" v-on="on">more_vert</v-icon></v-btn> -->
                                          <v-btn v-if="item.CanDelete || item.CanEdit" aria-label="Open Action menu" icon>
                                            <v-icon v-bind="attrs" v-on="on" :color="$root.themeColor" size="20px">mdi-dots-vertical</v-icon>
                                          </v-btn>
                                      </template>
                                      <v-list dense class="pt-0 pb-0">
                                          <v-list-item v-if="item.CanEdit" @click="fn_editComment(index)">
                                              <v-list-item-title aria-label="Click to delete post">Edit Comment</v-list-item-title>
                                          </v-list-item>
                                          <v-list-item v-if="item.CanDelete" @click="fn_deleteComment(index)">
                                              <v-list-item-title aria-label="Click to delete post">Delete Comment</v-list-item-title>
                                          </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </div>
                                  <v-btn style="width: 20px; height: auto;" v-if="item.LikeCount != null && item.LikeCount>0" icon class="mr-0 action_btn">
                                    <span class="pl-0 btn_text">{{item.LikeCount}}</span>
                                  </v-btn>
                                 
                                  <!-- <div class="reply_text pr-0 pt-0">
                                    <v-btn v-if="item.IsLikedByUser" icon class="mr-0 action_btn" @click="fn_unLikeComment(index)">
                                      <v-icon :color="$root.themeColor" size="18px">mdi-heart</v-icon>
                                    </v-btn>
                                    <v-btn v-else-if="!item.IsLikedByUser" icon class="mr-0 action_btn" @click="fn_likeComment(index)">
                                      <v-icon size="18px">mdi-heart-outline</v-icon>
                                    </v-btn>
                                    <v-btn v-if="item.LikeCount != null && item.LikeCount>0" icon class="mr-0 action_btn">
                                      <span class="pl-0 btn_text">{{item.LikeCount}}</span>
                                    </v-btn>
                                  </div> -->
                                    
                                </v-list-item-action>

                              </v-list-item>
                            </template>
                          </v-list>
                          <div v-if="item.IsShowReplyList" class="text-center pb-2">
                            <v-progress-circular width="2" indeterminate color="grey"></v-progress-circular>
                          </div>
                          <div v-if="item.ReplyList && item.ReplyList.length > 0 ">
                              <template v-for="(replyitem, replyindex) in item.ReplyList">
                                <div :key="replyindex" style="padding-left: 56px;" class="zindex2">
                                  <v-list class="pa-0 zindex2">
                                    <template >
                                      <v-list-item class="zindex2 pl-0 pr-4">
                                        <v-list-item-avatar class="grey lighten-3 align_top" width="30px" height="30px" min-width="30px">
                                          <template v-if="replyitem.ProfilePicture"><v-img alt="winnerimage" :src="replyitem.ProfilePicture" lazy-src="../../../assets/person.svg"></v-img></template>
                                          <template v-else>{{fn_abbreviatedName(replyitem.UserName ? replyitem.UserName : replyitem.UserAlias)}}</template>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                          <v-list-item-title v-text="replyitem.UserName ? replyitem.UserName : replyitem.UserAlias"></v-list-item-title>
                                          <v-list-item-subtitle>{{formatDate(replyitem.CreatedAt)}}</v-list-item-subtitle>
                                          <v-list-item-subtitle class="comment_text pt-2">{{replyitem.Content}}</v-list-item-subtitle>
                                          <!-- <v-layout row wrap ma-0>
                                            <v-flex xs6 sm6 md6 class="text-left pa-0 pl-0">
                                              <div class="d-inline-block reply_text pr-0 pt-0">
                                                <v-btn v-if="item.IsLikedByUser" icon class="mr-4 action_btn" @click="fn_unLikeComment(index)">
                                                  <v-icon :color="$root.themeColor" size="18px">mdi-heart</v-icon>
                                                  <span v-if="item.LikeCount != null && item.LikeCount>1" class="pl-2 btn_text">{{item.LikeCount}}</span>
                                                </v-btn>
                                                <v-btn v-else icon class="mr-4 action_btn" @click="fn_likeComment(index)">
                                                  <v-icon size="18px">mdi-heart-outline</v-icon>
                                                  <span v-if="item.LikeCount != null && item.LikeCount>0" class="pl-2 btn_text">{{item.LikeCount}}</span>
                                                </v-btn>
                                              </div>
                                              <div @click="fn_openReplyDialog()" class="d-inline-block reply_text pl-2 pt-2">Reply</div>
                                            </v-flex>
                                          </v-layout> -->
                                        </v-list-item-content>

                                        <v-list-item-action style="align-self: baseline;">
                                          <v-menu bottom right :offset-y="true" content-class="viewactns_actionbtns viewactns fixedcolumnactions" min-width="200px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-if="replyitem.CanDelete || replyitem.CanEdit" aria-label="Open Action menu" icon>
                                                  <v-icon v-bind="attrs" v-on="on" :color="$root.themeColor" size="20px">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list dense class="pt-0 pb-0">
                                                <v-list-item v-if="replyitem.CanEdit" @click="fn_editCommentReply(replyindex, index)">
                                                    <v-list-item-title aria-label="Click to delete post">Edit Comment</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-if="replyitem.CanDelete" @click="fn_deleteCommentReply(replyindex, index)">
                                                    <v-list-item-title aria-label="Click to delete post">Delete Comment</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                          </v-menu>
                                        </v-list-item-action>

                                      </v-list-item>
                                    </template>
                                  </v-list>
                                  
                                </div>
                                <!-- <v-divider style="margin-left: 56px;" class="mr-0" :key="replyindex+'divider'" v-if="replyindex != item.ReplyList.length - 1"></v-divider> -->
                              </template>
                              <!-- <infinite-loading ref="commentreplyinfiniteLoading" :identifier="infiniteIdv3" @infinite="CmmentinReplyfiniteHandler">
                                <div slot="no-more"></div>
                                <div slot="no-results" class="pa-4 light-opacity-color"></div>
                            </infinite-loading> -->

                          </div>
                          
                          <!-- <div @click="fn_hideReplyTextbox()" v-if="isShowReplyTextbox" class="custom-backdrop"></div> -->
                        </div>
                        <!-- <v-divider class="ml-0 mr-0" :key="index+'divider'" v-if="index != CommentItems.length - 1"></v-divider> -->
                      </template>
                      
                      <infinite-loading ref="commentinfiniteLoading" :identifier="infiniteId" @infinite="CmmentinfiniteHandler">
                          <div slot="no-more"></div>
                          <div slot="no-results" class="pa-4 light-opacity-color"></div>
                      </infinite-loading>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <!-- <v-tooltip v-model="show" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="grey lighten-1">mdi-cart</v-icon>
                          </v-btn>
                        </template>
                        <span>Replying to Waseem Khan</span>
                      </v-tooltip> -->
                      <v-layout row wrap ma-0>
                        <v-flex xs12 sm12 md12 class="text-left pt-0 pa-1 pl-3">
                          <div v-if="isShowReplyTextbox" style="">
                            Replying to {{ReplyTo}}. <span @click="fn_hideReplyTextbox()">Cancel</span>
                          </div>
                        </v-flex>
                        <v-flex xs12 sm12 md12 class="text-left pa-0 pl-0">
                          <v-text-field ref="commentField" v-model="CommentObj.Comment" class="addcaptiontextbox" @click:append-outer="fn_postComment" :append-outer-icon="CommentObj.Comment != null ? 'mdi-send' : ''" hide-details :placeholder="'Comment as ' + $root.tripOnLoadData.AttendeeInfo.AttendeeName" outlined></v-text-field>
                        </v-flex>
                      </v-layout>
                     
                      <!-- <v-text-field v-if="isShowReplyTextbox" ref="commentreplyField" v-model="CommentObj.Comment" class="addcaptiontextbox" @click:append-outer="fn_postCommentReply" :append-outer-icon="CommentObj.Comment != null ? 'mdi-send' : ''" hide-details :placeholder="'Comment as ' + $root.tripOnLoadData.AttendeeInfo.AttendeeName" outlined></v-text-field> -->
                      
                    </v-card-actions>
                </v-card>
        </v-bottom-sheet>

        <v-snackbar v-model="snackbar">
          <div>Replying to Waseem Khan <span>Cancel</span></div>

          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
import dialogAttendeeInfo from '../winner_attendee_profile'
import addpostDialog from '../socialfeed/addpost'
import attendeeLikeListDialog from '../socialfeed/likedattndeelist'
import attendeeTaggedListDialog from '../socialfeed/taggedattndeelist'
import { CometChat } from "@cometchat-pro/chat";
import moment from 'moment';
import 'moment/locale/en-gb'; // Import the locale if needed
var controller = null;

// const controller = new AbortController();

  export default {
    data: () => ({
    Items:[
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 1, Timestamp : null, PostedBy : 'Waseem Khan' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 2, Timestamp : null, PostedBy : 'Irfan Ansari' },
        // { PhotoUrl : null, IsLiked: true, Caption : null, LikeCount : 10, CommentCount : 8, Timestamp : null, PostedBy : 'Dheeraj Kumar' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 5, Timestamp : null, PostedBy : 'Prashant Jain' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : null, CommentCount : 0, Timestamp : null, PostedBy : 'Gaurav Jain' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 0, CommentCount : null, Timestamp : null, PostedBy : 'Harish Rawat' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 12, Timestamp : null, PostedBy : 'Intekhab Khan' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 12, Timestamp : null, PostedBy : 'Waseem Khan' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 12, Timestamp : null, PostedBy : 'Puran Chand' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 12, Timestamp : null, PostedBy : 'Dheeraj Kumar' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 12, Timestamp : null, PostedBy : 'Irfan Ansari' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 12, Timestamp : null, PostedBy : 'Santosh Dass' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 12, Timestamp : null, PostedBy : 'Prashant Jain' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 12, Timestamp : null, PostedBy : 'Irfan Ansari' },
        // { PhotoUrl : null, IsLiked: null, Caption : null, LikeCount : 10, CommentCount : 12, Timestamp : null, PostedBy : 'Dheeraj Kumar' }

      ],
      infiniteId: +new Date(),
      infiniteIdv2: +new Date(),
      infiniteIdv3: +new Date(),
      pageOffset:0,
      CommentpageOffset: 0,
      searchValue:'',
      timeoutid:null,
      error:false,
      unreadMessageCount:0,
      IsShowGuest: false,
      dialogImageViewer:false,
      selectedFileDetails:null,
      dialogFileViewer:false,
      contentDialog: false,
      bottomSheet: false,
      comment:null,
      isShow: false,
      postDataObj: {
        ImageBase64: null,
        MobileAttendeePostID : null,
        Caption : null,
        LikeCount : null,
        CommentCount : null,
        Timestamp : null,
        PostedBy : null,
        ImagePath : null,
        Text : null,
        ImageName : null,
        IsPhotoLiked : null
      },
      linearLoading: false,
      startY: 0,
      pullHeight: 0,
      isRefreshing: false,
      items: [],
      currentPage: 1,
      CommentItems: [],
      PostID: null,
      ItemIndex: null,
      CommentObj: { PostID: null, CommentID: null, Comment: null },
      CommentReplyList: [],
      CommentReplypageOffset: 0,
      snackbar: false,
      isShowReplyTextbox: false,
      show: false,
      CommentID: null,
      CommentReplyObj: {},
      CommentIndex: null,
      ReplyTo:null,
    }),
    components: {
      InfiniteLoading,
      'dialog-attendee-info':dialogAttendeeInfo,
      'dialog-addpost' : addpostDialog,
      'dialog-attendeelikelist': attendeeLikeListDialog,
      'dialog-attendeetagedlist': attendeeTaggedListDialog
    },
    methods:{
      formatDate(date) {
        return moment.utc(date).local().fromNow();
      },

      fn_debounceSearch: function(){
        console.log('debounce');
        if (this.timeoutid != null){
          clearTimeout(this.timeoutid);
        }

        this.timeoutid = setTimeout(() => { 
          this.error = false;
          this.pageOffset = 0;
          this.Items = [];
          this.infiniteId = +new Date();
        },500);
      },

      infiniteHandler($state) { 
        this.error = false;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
              this.error = true; 
              this.$root.fn_showToast('exception');
            }
            else{
                if(controller){
                  controller.abort();
                  console.log('Fetch aborted');
                }
                
                controller = new AbortController();

                var headersObj = {
                  headers: { 
                        'Authorization': 'bearer ' +  token,
                      }
                  };

                let postObj = {}
                postObj.keywords = this.searchValue;
                postObj.IsGuestSearchEnable = this.IsShowGuest;

                console.log(JSON.stringify(postObj));

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/post/list?offset="+this.pageOffset+"&limit=30", headersObj, {timeout:30000}, {signal: controller.signal})
                .then(response => {
                    console.log(response);
                    if(response.data.TotalCount > this.Items.length){
                      for(let i=0; i<response.data.Items.length; i++){
                          this.Items.push(response.data.Items[i]);
                      }
                      $state.loaded();
                    }
                    else{
                      $state.complete();
                    }
                    controller = null;
                    this.fn_photoAuth();
                    this.pageOffset = this.Items.length;
                }).catch(e => {
                    console.log(e);
                    if(e.response.data == "Token Expired"){
                      this.fn_debounceSearch();
                    }
                    else{
                      let toastMsg = e && e.message ? e.message : 'exception';
                      this.$root.fn_showToast(toastMsg);
                      this.error = true;
                    }
                })
            }
        })
        
      },

      fn_gotoChatPage: function(attendeeId){
        this.$refs['ref_dialogAttendeeInfo'].fn_getAttendeeInfo(attendeeId);
          // this.$router.push({path:'/platinumclub/attendee/'+attendeeId});
      },

      fn_gotoAddTextPage: function(){
        let tempObj = {
          CanDelete: true,
          CanEdit: true,
          Caption : null,
          CommentCount : null,
          Content: null,
          CreatedAt: null,
          IsLikedByUser: null,
          IsUserTagged: null,
          LikeCount : null,
          LikeID: null,
          MediaUrl: null,
          PostID : null,
          PostType: 1,
          PostedBy : null,
          PostedByAlias: null,
          Tags: [],
          PostUserTags: [],
          ImageBase64: null,
          IsAdminPost: null
          
        };
        
        this.$refs['ref_dialogAddPost'].fn_showDialog(tempObj);
          // this.$router.push({path:'/platinumclub/attendee/'+attendeeId});
      },

      fn_gotoAddImagePage: function(){
        this.$refs['ref_dialogAddImage'].fn_checkStorage();
          // this.$router.push({path:'/platinumclub/attendee/'+attendeeId});
      },

      fn_abbreviatedName: function(str){
          if(!str){
            return ''
          }
          //let words = str.trim().split(' ');
          //words = words.replace(/ +(?= )/g,'');
          let words = str.trim().replace(/ +(?= )/g,'').split(' ');

          if(words.length > 1){
              return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
          }
          else{
              return words[0][0].toUpperCase();
          }
      },

      fn_isShowGuest: function(){
        this.fn_debounceSearch();
      },

      pickFile() {
          this.$refs.image.click();
      },

      fn_likePost: function(index){
        this.Items[index].IsLikedByUser = true;
        this.Items[index].LikeCount = this.Items[index].LikeCount + 1;
        let postid = this.Items[index].PostID;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
            }
            else{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/post/" + postid + "/likes", headersObj, {timeout:30000})
                .then(response => {
                    // let data = JSON.parse(response);
                    this.Items[index].LikeID = response.data.LikeID;
                    console.log(response);
                }).catch(e => {
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    this.$root.fn_showToast(toastMsg);
                })
            }
        })
      },

      fn_unLikePost: function(index){
        this.Items[index].IsLikedByUser = false;
        this.Items[index].LikeCount = this.Items[index].LikeCount - 1;
        this.$forceUpdate();
        let postid = this.Items[index].PostID;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
            }
            else{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/post/" + postid + "/unlike", headersObj, {timeout:30000})
                .then(response => {
                    // let data = JSON.parse(response);
                    console.log(response);
                }).catch(e => {
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    this.$root.fn_showToast(toastMsg);
                })
            }
        })
      },

      async fn_photoAuth() {
        let self = this;

        for (let i = 0; i < this.Items.length; i++) {
            let urlsToCheck = ['MediaUrl', 'ProfilePicture']; // Keys to process

            for (let urlKey of urlsToCheck) {
                if (self.Items[i][urlKey] && !self.Items[i][urlKey].startsWith('data:image/')) {
                    try {
                        const token = await new Promise((resolve, reject) => {
                            this.$root.fn_getToken((token) => {
                                if (token === 'error') {
                                    reject('Token error');
                                } else {
                                    resolve(token);
                                }
                            });
                        });

                        console.log(token + " Token Called");

                        const headersObj = {
                            headers: {
                                'Authorization': 'Bearer ' + token,
                            }
                        };

                        console.log(self.$root.baseUrl);
                        const response = await axios.get(self.$root.baseUrl + "/" + self.Items[i][urlKey], headersObj, { timeout: 30000 });

                        // Update item with the retrieved data
                        // console.log(response.data);
                        if(self.Items && self.Items.length>0){
                          self.Items[i][urlKey] = 'data:image/png;base64, ' + response.data;
                        }

                        // console.log(self.Items[i][urlKey]);
                        self.$forceUpdate();
                    } catch (e) {
                        // self.error = true;
                        console.log(e);
                        // let toastMsg = e && e.message ? e.message : 'exception';
                        // self.$root.fn_showToast(toastMsg);
                        break; // Stop processing on error
                    }
                }
            }
        }

        this.loading = false;
        this.error = false;
      },

      async fn_commentPhotoAuth() {
        let self = this;

        for (let i = 0; i < this.CommentItems.length; i++) {
            let urlsToCheck = ['ProfilePicture']; // Keys to process

            for (let urlKey of urlsToCheck) {
                if (self.CommentItems[i][urlKey] && !self.CommentItems[i][urlKey].startsWith('data:image/')) {
                    try {
                        const token = await new Promise((resolve, reject) => {
                            this.$root.fn_getToken((token) => {
                                if (token === 'error') {
                                    reject('Token error');
                                } else {
                                    resolve(token);
                                }
                            });
                        });

                        console.log(token + " Token Called");

                        const headersObj = {
                            headers: {
                                'Authorization': 'Bearer ' + token,
                            }
                        };

                        console.log(self.$root.baseUrl);
                        const response = await axios.get(self.$root.baseUrl + "/" + self.CommentItems[i][urlKey], headersObj, { timeout: 30000 });

                        // Update item with the retrieved data
                        // console.log(response.data);
                        if(self.CommentItems && self.CommentItems.length>0){
                          self.CommentItems[i][urlKey] = 'data:image/png;base64, ' + response.data;
                        }

                        // console.log(self.Items[i][urlKey]);
                        self.$forceUpdate();
                    } catch (e) {
                        // self.error = true;
                        console.log(e);
                        // let toastMsg = e && e.message ? e.message : 'exception';
                        // self.$root.fn_showToast(toastMsg);
                        break; // Stop processing on error
                    }
                }
            }
        }

        this.loading = false;
        this.error = false;
      },

      async fn_commentReplyPhotoAuth(index) {
        let self = this;

        for (let i = 0; i < this.CommentItems[index].ReplyList.length; i++) {
            let urlsToCheck = ['ProfilePicture']; // Keys to process

            for (let urlKey of urlsToCheck) {
                if (self.CommentItems[index].ReplyList[i][urlKey] && !self.CommentItems[index].ReplyList[i][urlKey].startsWith('data:image/')) {
                    try {
                        const token = await new Promise((resolve, reject) => {
                            this.$root.fn_getToken((token) => {
                                if (token === 'error') {
                                    reject('Token error');
                                } else {
                                    resolve(token);
                                }
                            });
                        });

                        console.log(token + " Token Called");

                        const headersObj = {
                            headers: {
                                'Authorization': 'Bearer ' + token,
                            }
                        };

                        console.log(self.$root.baseUrl);
                        const response = await axios.get(self.$root.baseUrl + "/" + self.CommentItems[index].ReplyList[i][urlKey], headersObj, { timeout: 30000 });

                        // Update item with the retrieved data
                        // console.log(response.data);
                        if(self.CommentItems[index].ReplyList && self.CommentItems[index].ReplyList.length>0){
                          self.CommentItems[index].ReplyList[i][urlKey] = 'data:image/png;base64, ' + response.data;
                        }

                        // console.log(self.Items[i][urlKey]);
                        self.$forceUpdate();
                    } catch (e) {
                        // self.error = true;
                        console.log(e);
                        // let toastMsg = e && e.message ? e.message : 'exception';
                        // self.$root.fn_showToast(toastMsg);
                        break; // Stop processing on error
                    }
                }
            }
        }

        this.loading = false;
        this.error = false;
      },

      fn_addTextContent: function(tempdata){
        console.log(tempdata);
        this.Items.unshift(tempdata);
        if(this.Items && this.Items.length === 1){
          this.infiniteHandler({
              loaded: () => {
                  // Callback to indicate that more items were loaded successfully.
                  console.log('Manual load completed');
              },
              complete: () => {
                  // Callback to indicate that all items have been loaded.
                  console.log('Manual load completed');
              }
          });
          // this.infiniteHandler();
        }
      },

      handleTouchStart(event) {
        if (window.scrollY === 0) {
          this.startY = event.touches[0].clientY;
        }
      },

      handleTouchMove(event) {
        const moveY = event.touches[0].clientY;
        if (window.scrollY === 0 && moveY > this.startY && !this.isRefreshing) {
          this.pullHeight = moveY - this.startY;
        }
      },

      handleTouchEnd() {
        if (this.pullHeight > 50) {
          this.isRefreshing = true;
          this.onRefresh();
        } else {
          this.pullHeight = 0;
        }
      },

      onRefresh() {
      /// Simulate a refresh by resetting the content
      this.pageOffset = 0;
        // this.Type = 'Refresh';
        // Simulate a refresh by resetting the content
        setTimeout(() => {
          this.Items = [];
          // this.infiniteHandler(null); // Load new content
          this.pullHeight = 0;
          this.isRefreshing = false;
          this.$refs.infiniteLoading.stateChanger.reset(); // Reset infinite loading state
        }, 1500);
      },

      fn_deletePost: function(index){
        // this.Items[index].IsLikedByUser = true;
        // this.Items[index].LikeCount = this.Items[index].LikeCount + 1;
        let postid = this.Items[index].PostID;
        this.Items.splice(index, 1);
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
            }
            else{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/post/" + postid + "/delete", headersObj, {timeout:30000})
                .then(response => {
                    // let data = JSON.parse(response);
                    console.log(response);
                }).catch(e => {
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    this.$root.fn_showToast(toastMsg);
                })
            }
        })
      },

      fn_UpdateItemID: function(postid){
        this.Items[0].PostID = postid;
      },

      fn_editPost: function(index){
        let item = this.Items[index];
        item.ImageBase64 = null;
        this.$refs['ref_dialogAddPost'].fn_showDialog(item);
      },

      fn_updateItemData: function(tempdata){
        console.log(tempdata);
        let item = this.Items.find(item => item.PostID === tempdata.PostID);
        if (item) {
            Object.assign(item, tempdata);
        }
      },

      fn_removeTag: function(index){
        this.Items[index].IsUserTagged = false;
        //this.Items[index].LikeCount = this.Items[index].LikeCount + 1;
        let postid = this.Items[index].PostID;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
            }
            else{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/post/" + postid + "/Tag/Remove?userId=", headersObj, {timeout:30000})
                .then(response => {
                    // let data = JSON.parse(response);
                    console.log(response);
                }).catch(e => {
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    this.$root.fn_showToast(toastMsg);
                })
            }
        })
      },

      fn_comment: function(index){
        this.bottomSheet = true;
        this.PostID = this.Items[index].PostID;
        this.ItemIndex = index;
        this.$refs.commentinfiniteLoading.stateChanger.reset(); // Reset infinite loading state
        this.CommentItems = [];
        this.CommentpageOffset = 0;
        this.CommentObj = { PostID: null, CommentID: null, Comment: null, ParentPostCommentID: null }
        this.fn_hideReplyTextbox();
      },

      CmmentinfiniteHandler($state) { 
        this.error = false;
        let self = this;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
              this.error = true; 
              this.$root.fn_showToast('exception');
            }
            else{
                if(controller){
                  controller.abort();
                  console.log('Fetch aborted');
                }
                
                controller = new AbortController();

                var headersObj = {
                  headers: { 
                        'Authorization': 'bearer ' +  token,
                      }
                  };

                let postObj = {}
                postObj.keywords = this.searchValue;
                postObj.IsGuestSearchEnable = this.IsShowGuest;

                console.log(JSON.stringify(postObj));

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/post/" + this.PostID + "/details?offset="+this.CommentpageOffset+"&limit=30", headersObj, {timeout:30000}, {signal: controller.signal})
                .then(response => {
                    console.log(response);
                    if(response.data.CommentCount > this.CommentItems.length){
                      for(let i=0; i<response.data.Comments.length; i++){
                          response.data.Comments[i].ReplyList = [];
                          response.data.Comments[i].IsShowReplyList = false;
                          this.CommentItems.push(response.data.Comments[i]);
                      }
                      $state.loaded();
                    }
                    else{
                      $state.complete();
                    }
                    controller = null;
                    this.fn_commentPhotoAuth();
                    this.CommentpageOffset = this.CommentItems.length;
                }).catch(e => {
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    self.$root.fn_showToast(toastMsg);
                    // self.error = true;
                    // if(e.response.data == "Token Expired"){
                    //   this.fn_debounceSearch();
                    // }
                    // else{
                    //   let toastMsg = e && e.message ? e.message : 'exception';
                    //   this.$root.fn_showToast(toastMsg);
                    //   this.error = true;
                    // }
                })
            }
        })
        
      },

      fn_postComment: function(){

          if(!navigator.onLine){
              this.showToast("nointernet");
              return;
          }

          //this.ItemIndex
          // this.PostID = this.Items[index].PostID;
          var tempdata = {};
          var currentDate = new Date();
          tempdata.CanDelete = true;
          tempdata.CanEdit = true;
          tempdata.CommentID = this.CommentObj.CommentID;
          tempdata.CommentLikeID = this.CommentObj.CommentLikeID;
          tempdata.Content = this.CommentObj.Comment;
          tempdata.CreatedAt = currentDate.toISOString();
          tempdata.IsCommentedByUser = this.CommentObj.IsCommentedByUser;
          tempdata.IsLikedByUser = this.CommentObj.IsLikedByUser;
          tempdata.LikeCount = this.CommentObj.LikeCount;
          tempdata.ReplyCount = this.CommentObj.ReplyCount;
          tempdata.UserName = this.$root.tripOnLoadData.AttendeeInfo.AttendeeName;
          tempdata.UserAlias = null;
          tempdata.ParentPostCommentID = this.CommentObj.ParentPostCommentID;
          tempdata.UserAlias = null;
          tempdata.ReplyList = [];
          console.log(tempdata);
          if(this.CommentObj.ParentPostCommentID == null){
            tempdata.IsShowReplyList = false;
            if(this.CommentObj.CommentID == null)
              this.fn_updateCommentCount('add')
            this.fn_addComment(tempdata);
          }
          else{
            console.log('Called Reply');
            tempdata.IsShowReplyList = true;
            //tempdata.CommentID = null;
            if(this.CommentObj.CommentID == null)
              this.fn_updateCommentReplyCount('add')
            this.fn_addCommentReply(tempdata);
          }
          
          this.$root.fn_getToken(token=>{
              if(token == 'error'){
                  this.loading = false;
                  this.error = true;
                  this.$root.fn_showToast('exception');
              }
              else{
                  var headersObj = {
                      headers: {
                          'Authorization': 'bearer '+token,
                          }
                      };

                  let dataObj = {};
                  dataObj.CommentText = this.CommentObj.Comment;
                  dataObj.PostID = this.PostID;
                  dataObj.CommentID = this.CommentObj.CommentID;
                  dataObj.ParentPostCommentID = this.CommentObj.ParentPostCommentID;

                  axios.post(this.$root.baseUrl + "/api/mobileapp/attendee/post/comments", dataObj, headersObj, {timeout:30000})
                  .then(response => {
                      this.loading = false;
                      if(this.CommentObj.ParentPostCommentID == null){
                        this.CommentItems[0].CommentID = response.data.CommentID;
                      }
                      else{
                        this.CommentItems[this.CommentIndex].ReplyList[0].CommentID = response.data.CommentID;
                      }
                      
                      this.CommentObj.CommentID = null;
                      this.CommentObj.CommentText = null;
                      this.CommentObj.Comment = null;
                      this.isShowReplyTextbox = null;
                      this.ReplyTo = null;
                      this.fn_hideReplyTextbox();
                      this.contentDialog = false;
                      console.log(response);
                      // this.giftItems = response.data.gifts;
                  }).catch(e => {
                      // this.loading = false;
                      // this.error = true;
                      console.log(e);
                      let toastMsg = e && e.message ? e.message : 'exception';
                      this.$root.fn_showToast(toastMsg);
                  })
              }
          })
      },

      fn_addComment: function(tempdata){
        if(tempdata.CommentID == null)
          this.CommentItems.unshift(tempdata);
        else{
          let item = this.CommentItems.find(item => item.CommentID === tempdata.CommentID);
          if (item) {
              Object.assign(item, tempdata);
          }
        }
      },

      fn_updateCommentCount: function(type){
        for(var i in this.Items){
          if(this.Items[i].PostID == this.PostID){
            if(type == 'add')
              this.Items[i].CommentCount = this.Items[i].CommentCount + 1;
            else{
              this.Items[i].CommentCount = this.Items[i].CommentCount - 1;
            }
            break;
          }
        }
      },

      fn_editComment: function(index){
        var tempObj = {};
        tempObj = this.CommentItems[index];
        this.CommentObj.Comment = tempObj.Content;
        this.CommentObj.CommentID = tempObj.CommentID;
        this.CommentObj.PostID = this.PostID;
        this.CommentObj.CommentLikeID = tempObj.CommentLikeID;
        this.CommentObj.IsCommentedByUser = tempObj.IsCommentedByUser;
        this.CommentObj.IsLikedByUser = tempObj.IsLikedByUser;
        this.CommentObj.LikeCount = tempObj.LikeCount;
        this.CommentObj.ReplyCount = tempObj.ReplyCount;
        this.CommentObj.ParentPostCommentID = null;
        this.CommentObj.ReplyList = [];
        // this.CommentObj.IsShowReplyList = false;
        this.isShowReplyTextbox = null;
        this.ReplyTo = null;
        // this.CommentObj.UserName = this.$root.tripOnLoadData.AttendeeInfo.AttendeeName;
        // this.CommentObj.UserAlias = null;
        this.$nextTick(() => {
            this.$refs.commentField.focus();
        });
      },

      fn_deleteComment: function(index){
        this.fn_updateCommentCount('delete');
        let commentid = this.CommentItems[index].CommentID;
        this.CommentItems.splice(index, 1);
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
            }
            else{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/post/comments/" + commentid + "/delete", headersObj, {timeout:30000})
                .then(response => {
                    // let data = JSON.parse(response);
                    console.log(response);
                }).catch(e => {
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    this.$root.fn_showToast(toastMsg);
                })
            }
        })
      },

      fn_likeComment: function(index){
        this.CommentItems[index].IsLikedByUser = true;
        this.CommentItems[index].LikeCount = this.CommentItems[index].LikeCount + 1;
        let commentid = this.CommentItems[index].CommentID;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
            }
            else{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/comments/" + commentid + "/like", headersObj, {timeout:30000})
                .then(response => {
                    // let data = JSON.parse(response);
                    this.CommentItems[index].CommentLikeID = response.data.CommentLikeID;
                    console.log(response);
                }).catch(e => {
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    this.$root.fn_showToast(toastMsg);
                })
            }
        })
      },

      fn_unLikeComment: function(index){
        this.CommentItems[index].IsLikedByUser = false;
        this.CommentItems[index].LikeCount = this.CommentItems[index].LikeCount - 1;
        let commentid = this.CommentItems[index].CommentID;
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
            }
            else{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/comments/" + commentid + "/unlike", headersObj, {timeout:30000})
                .then(response => {
                    // let data = JSON.parse(response);
                    console.log(response);
                }).catch(e => {
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    this.$root.fn_showToast(toastMsg);
                })
            }
        })
      },

      fn_showReplyList: function(index){
        this.CommentIndex = index;
        console.log(index);
        this.CommentItems[index].IsShowReplyList = true;
        this.CommentID = this.CommentItems[index].CommentID;
        this.CommentItems[index].ReplyList = [];
        this.fn_loadCommentReply(this.CommentIndex);
        //this.$refs.commentreplyinfiniteLoading.stateChanger.reset();
        // setTimeout(() => {
        //   this.$refs.commentreplyinfiniteLoading.stateChanger.reset();
        // }, 100);
      },

      fn_openReplyDialog: function(index){
        this.show = true;
        this.isShowReplyTextbox = true;
        this.CommentReplyObj = {};
        this.CommentID = this.CommentItems[index].CommentID;
        this.CommentObj.ParentPostCommentID = this.CommentID;
        this.CommentObj.ParentPostCommentID = this.CommentID;
        this.ReplyTo = this.CommentItems[index].UserName;
        this.CommentIndex = index;
        this.CommentObj.CommentID = null;
        this.$nextTick(() => {
            this.$refs.commentField.focus();
        });
      },

      fn_hideReplyTextbox: function(){
        this.show = false;
        this.isShowReplyTextbox = false;
        this.CommentObj.ParentPostCommentID = null;
        this.ReplyTo = null;
      },

      fn_addCommentReply: function(tempdata){
        console.log('Called Add Reply');
        if(tempdata.CommentID == null){
          this.CommentItems[this.CommentIndex].ReplyList.unshift(tempdata);
          this.$forceUpdate();
        }
        else{
          let item = this.CommentItems[this.CommentIndex].ReplyList.find(item => item.CommentID === tempdata.CommentID);
          if (item) {
              Object.assign(item, tempdata);
          }
        }
        console.log(this.CommentItems[this.CommentIndex]);
      },

      fn_editCommentReply: function(replyindex, index){
        var tempObj = {};
        tempObj = this.CommentItems[index].ReplyList[replyindex];
        this.CommentIndex = index;
        this.CommentObj.Comment = tempObj.Content;
        this.CommentObj.CommentID = tempObj.CommentID;
        this.CommentObj.PostID = this.PostID;
        this.CommentObj.CommentLikeID = tempObj.CommentLikeID;
        this.CommentObj.IsCommentedByUser = tempObj.IsCommentedByUser;
        this.CommentObj.IsLikedByUser = tempObj.IsLikedByUser;
        this.CommentObj.LikeCount = tempObj.LikeCount;
        this.CommentObj.ReplyCount = tempObj.ReplyCount;
        this.isShowReplyTextbox = null;
        this.CommentObj.ParentPostCommentID = tempObj.CommentID;
        this.ReplyTo = null;
        // this.CommentObj.UserName = this.$root.tripOnLoadData.AttendeeInfo.AttendeeName;
        // this.CommentObj.UserAlias = null;
        this.$nextTick(() => {
            this.$refs.commentField.focus();
        });
      },

      fn_deleteCommentReply: function(replyindex, index){
        this.CommentIndex = index;
        this.fn_updateCommentReplyCount('delete');
        let commentid = this.CommentItems[index].ReplyList[replyindex].CommentID;
        this.CommentItems[index].ReplyList.splice(replyindex, 1);
        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
            }
            else{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/post/comments/" + commentid + "/delete", headersObj, {timeout:30000})
                .then(response => {
                    // let data = JSON.parse(response);
                    console.log(response);
                }).catch(e => {
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    this.$root.fn_showToast(toastMsg);
                })
            }
        })
      },

      fn_updateCommentReplyCount: function(type){
        console.log('Called Update Reply Count');
        console.log(this.CommentID);
        console.log(this.CommentItems);
        for(var i in this.CommentItems){
          if(this.CommentItems[i].CommentID == this.CommentID){
            if(type == 'add')
              this.CommentItems[i].ReplyCount = this.CommentItems[i].ReplyCount + 1;
            else{
              this.CommentItems[i].ReplyCount = this.CommentItems[i].ReplyCount - 1;
            }
            break;
          }
        }
      },

      async fn_loadCommentReply(index) {
        console.log(index);
        try {
            // Retrieve the token asynchronously
            const token = await new Promise((resolve, reject) => {
                this.$root.fn_getToken((token) => {
                    if (token === 'error') {
                        reject('Token error');
                    } else {
                        resolve(token);
                    }
                });
            });

            console.log(token + " Token Called");

            // Prepare the headers object
            const headersObj = {
                headers: { 
                    'Authorization': 'Bearer ' + token,
                }
            };

            // Log the base URL
            console.log(this.$root.baseUrl);

            // Perform the API call to retrieve comment replies
            const response = await axios.get(
                `${this.$root.baseUrl}/api/mobileapp/attendee/post/comments/${this.CommentID}/replies?offset=${this.CommentReplypageOffset}&limit=1000`,
                headersObj,
                { timeout: 30000 }
            );

            // Log the response data
            console.log(response.data);

            let matchingIndex = this.CommentItems.findIndex(item => item.CommentID === response.data.Items[0].ParentCommentID);
            if (matchingIndex !== -1) {
              //this.CommentItems[matchingIndex].IsShowReplyList = false;
              this.CommentItems[matchingIndex].IsShowReplyList = false;
              this.CommentItems[matchingIndex].ReplyList = response.data.Items;
              this.fn_commentReplyPhotoAuth(matchingIndex);
            }

            // Force Vue.js to re-render the component
            this.$forceUpdate();

        } catch (e) {
            // Handle any errors that occur
            this.error = true;
            console.log(e);
            let toastMsg = e && e.message ? e.message : 'exception';
            this.$root.fn_showToast(toastMsg);
        }
      },

      fn_showLikeList: function(postid){
        this.$refs['ref_dialogAttendeeLikeList'].fn_showDialog(postid);
      },

      fn_showTaggedList: function(postid){
        this.$refs['ref_dialogAttendeeTaggedList'].fn_showDialog(postid);
      }
      
    },

    mounted(){
      this.$root.fn_postClickMetrics('Hero Tiles', 'Social Feed', null);
      if(this.$root.Envirenment != 3){
        CometChat.getUnreadMessageCount().then(
        array => {
          console.log("Message count fetched", array);
          for(let group in array){
              for(let key in array[group]){
                  this.unreadMessageCount += array[group][key];
              }
          }
          }, error => {
            console.log("Error in getting message count", error);
          }
        );
      }
    }
  }
</script>

<style>
.pull-to-refresh {
  overflow: hidden;
}

.pull-to-refresh-indicator {
  background-color: #e0e0e0;
  text-align: center;
  transition: height 0.3s;
}

.content {
  padding: 20px;
}

.item {
  margin-bottom: 10px;
}
.commentliked{
  position: absolute;
  bottom: 18px;
}
.custom-backdrop{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}

.usertitle{
  overflow: visible;
  white-space: break-spaces;
}

.width_20{
  width: auto !important;
}

.comment_text{
  color: #333333 !important;
}

.comment_icon{
  max-width: 18px;
}

.width_24{
  width: 24px !important;
}

.longtext .text_data{
  font-size: 12pt;
}
</style>