<template>
    <div grey-back style="height:100vh;overflow;auto">
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Notifications</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="error">
                <div style="text-align:center;" class="pa-4">
                    <p>Something went wrong. Please try again.</p>
                    <br>
                    <v-btn outlined @click="fn_getNotification"><v-icon left>refresh</v-icon> Retry</v-btn>
                </div>
            </template>

            <template v-if="loading">
                <div class="pa-8">
                    <v-img width="120" style="margin:auto" src="../../assets/giphy.gif"></v-img>
                </div>
            </template>

            <template v-else>
                <v-card flat v-if="notificationData">
                    <v-card-title primary-title>{{notificationData.NotificationTitle}}</v-card-title>
                    <v-card-text>
                        <!-- <p v-html="notificationData.NotificationShortDescription"></p> -->
                        <p class="pt-1" v-html="notificationData.NotificationDescription"></p>
                        <p class="pt-1">{{notificationData.NotificationSentOn | moment("MMM Do YYYY, hh:mm a")}}</p>
                    </v-card-text>
                </v-card>
            </template>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios';

    export default{
        data(){
            return{
                loading:false,
                error:false,
                notificationData:null
            }
        },
        methods:{

            fn_getNotification: function(){
                if(!navigator.onLine){
                    this.$root.fn_showToast("nointernet");
                    return;
                }

                this.loading = true;
                this.error = false;
                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        this.loading = false;
                        this.error = true;
                        this.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: {
                                'Authorization': 'bearer '+token,
                                }
                            };

                        axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/pushNotifications/"+this.$route.params.notificationid, headersObj, {timeout:30000})
                        .then(response => {
                            this.loading = false;
                            // let data = JSON.parse(response);
                            console.log(response);
                            this.notificationData = response.data;
                            this.$root.fn_postClickMetrics('Notification', this.notificationData.NotificationTitle, this.$route.params.notificationid);
                            !this.notificationData.isRead ? this.$root.tripOnLoadData.NotificationCount-- : '';
                        }).catch(e => {
                            this.loading = false;
                            this.error = true;
                            console.log(e);
                            let toastMsg = e && e.message ? e.message : 'exception';
                            this.$root.fn_showToast(toastMsg);
                        })
                    }
                })
                
            },

            // fn_getNotification: function(){
            //     if(!navigator.onLine){
            //         this.$root.fn_showToast("nointernet");
            //         return;
            //     }

            //     this.loading = true;
            //     this.error = false;
                
            //     // let self = this;
            //     let dataObj = {};
            //     dataObj.SendPushNotificationID = this.$route.params.notificationid;
                
            //     this.getServerData('NotificationList', dataObj, 
            //         {
            //             onSuccess: function(response){
            //                 console.log(response);
            //                 self.loading = false;
            //                 self.notificationData = response;
            //             }, 
            //             onError: function(error){
            //                 console.log(error);
            //                 self.loading = false;
            //                 self.error = true;
            //                 self.$root.fn_showToast("exception");
            //             }, 
            //         }
            //     );
            // },
        },
        mounted() {
            localStorage.getItem('n_id') ? localStorage.removeItem('n_id') : '';
            this.fn_getNotification();
        },
    }
</script>