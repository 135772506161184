<template>
    <div>

      <v-dialog v-model="contentDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>

        <v-card>
          <!-- <v-toolbar :color="$root.themeColor" height="56" flat dark>
            <v-btn icon @click="contentDialog=false">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Create Post</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn dark :class="{'disable_btn': (formObj.Content == null || formObj.Content == '')}" :disabled="formObj.Content == null || formObj.Content == ''" @click="fn_postCaption">Post</v-btn>
          </v-toolbar> -->

          <v-card-title class="title pb-4 pl-4" style="flex-wrap:nowrap;word-break: break-word;">
              <v-btn icon class="mr-4" @click="contentDialog=false">
                  <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
              </v-btn>
              {{formObj.PostID == null ? 'Create post' : 'Edit post'}}
              <v-spacer></v-spacer>
              <v-btn class="white--text" v-if="formObj.PostID == null" :disabled="fn_diableBtn()" :color="$root.themeColor" @click="fn_postCaption()">Post</v-btn>
              <v-btn class="white--text" v-else :disabled="fn_diableBtn()" :color="$root.themeColor" @click="fn_editPost()">Post</v-btn>
          </v-card-title>
          <v-divider></v-divider>

          <!-- <div style="min-height: calc(100% - 56px)">
            <template> -->
              <!-- <v-card flat class="mt-4"> -->
                <v-card-text class="scrollable-content override_scroll pt-0 pl-0 pr-0">
                  
                  <template>
                    <v-list class="pa-0 socialfeed_topsection" two-line>
                      <template>
                        <v-list-item class="zindex2">

                          <v-list-item-avatar class="grey lighten-3">
                            <template v-if="formObj.PostID == null">{{fn_abbreviatedName($root.tripOnLoadData.AttendeeInfo.AttendeeName)}}</template>
                            <template v-else-if="formObj.ProfilePicture"><v-img alt="winnerimage" :src="formObj.ProfilePicture" lazy-src="../../../assets/person.svg"></v-img></template>
                            <template v-else-if="formObj.IsAdminPost">{{fn_abbreviatedName($root.tripOnLoadData.AttendeeInfo.AttendeeName)}}</template>
                            <template v-else>{{fn_abbreviatedName(formObj.PostedBy ? formObj.PostedBy : formObj.PostedByAlias)}}</template>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title class="usertitle">
                              <span class="font-weight-medium">{{$root.tripOnLoadData.AttendeeInfo.AttendeeName}}</span>
                              <span @click="fn_addTags()" v-if="formObj.PostUserTags && formObj.PostUserTags.length>0"> is with 
                                <span class="font-weight-medium">{{formObj.PostUserTags[0].Name != null ? formObj.PostUserTags[0].Name : formObj.PostUserTags[0].Alias}}</span>
                                <span v-if="formObj.PostUserTags && formObj.PostUserTags.length>1"> and <span class="font-weight-medium">{{formObj.PostUserTags && formObj.PostUserTags.length - 1}} others.</span></span>
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <!-- <v-divider></v-divider> -->
                      </template>
                    </v-list>
                  </template>

                  <!-- <div class="tagged_Section" v-if="formObj.PostUserTags.length>0" @click="fn_addTags()">
                    Tagged with <span>{{formObj.PostUserTags[0].Name}} <span v-if="formObj.PostUserTags.length>1">and {{formObj.PostUserTags.length-1}} others.</span></span>
                  </div> -->

                  <!-- <div>
                    <label id="comment" >Comments</label>
                    <div id="mentionElement" placeholder = "Type @ and tag user"></div>
                    <ejs-mention id='defaultMention' :target='mentionTarget'></ejs-mention>
                  </div> -->

                  <div v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.PermissionList && ($root.tripOnLoadData.AttendeeInfo.PermissionList.includes(1) || $root.tripOnLoadData.AttendeeInfo.PermissionList.includes(29))">
                    <v-checkbox class="mt-0 pl-12 ml-5 pt-0 pb-2" v-model="formObj.IsAdminPost" :label="'Post as ' + $root.tripOnLoadData.AttendeeInfo.MobileAdminPostName" :value="true" hide-details></v-checkbox>
                  </div>

                  <div style="height: 100%; position: relative;" v-if="formObj.PostType == 1">
                    <quill-editor :class="{'showoptionsatbottomopened': showList, 'adminview' : $root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.PermissionList && ($root.tripOnLoadData.AttendeeInfo.PermissionList.includes(1) || $root.tripOnLoadData.AttendeeInfo.PermissionList.includes(29))}" class="richetxtcustom_style addtext_richtexteditor showoptionsatbottomclosed" style="position: relative;" @click.native="fn_hideList()" @focus="fn_hideList()" ref="myQuillEditor" v-model="formObj.Content" @ready="onEditorReady" :options="editorOptions">
                    </quill-editor>
                    <!-- <div v-if="showList" class="mention-list" :style="{ top: listPosition.top + 'px', left: listPosition.left + 'px' }">
                      <ul>
                        <li v-for="(user, index) in filteredUserList" :key="index" @click="selectUser(user)">
                          {{ user.userName }}
                        </li>
                      </ul>
                    </div> -->
                  </div>

                  <div style="height: 100%;" v-if="formObj.PostType == 2">
                    <!-- <div class="pl-4 pr-4">
                      <v-textarea class="photocaption_textarea" @focus="fn_hideList()" rows="1" auto-grow placeholder="Say something about this photo..." v-model="formObj.Caption"></v-textarea>
                    </div> -->
                    <div>
                      <quill-editor class="richetxtcustom_style addimage_richtexteditor" @click.native="fn_hideList()" @focus="fn_hideList()" ref="myQuillEditor" v-model="formObj.Caption" @ready="onEditorReady" :options="editorOptions">
                      </quill-editor>
                      <div v-if="mentionListVisible" class="mention-list">
                        <div v-for="user in filteredUsers" :key="user.id" @click="selectMention(user)">
                          {{ user.name }}
                        </div>
                      </div>
                    </div>
                    <div v-if="formObj.ImageBase64" style="position: relative;">
                      <v-btn @click="fn_removeImage()" class="remove_btn" color="white" icon >
                        <v-icon size="28px">mdi-close</v-icon>
                      </v-btn>
                      <v-btn @click="fn_rotateImage()" class="rotate_btn" color="white" icon >
                        <v-icon size="28px">mdi-rotate-left</v-icon>
                      </v-btn>
                      <img style="display: block;" width="100%" :src="'data:image/png;base64, ' + formObj.ImageBase64"/>
                    </div>
                  </div>
                  

                </v-card-text>
           
        </v-card>

        

        <v-footer class="pa-0" style="position: fixed;width: 100%;bottom: 0;background:#fff">

          <div style="position: absolute; bottom: 0px; width: 100%;" v-if="showList">
            <v-list class="pt-0">
              <v-divider></v-divider>
              <v-list-item v-if="formObj.PostType == 1" @click="fn_captureImage(1)">
                <v-list-item-avatar>
                  <v-avatar size="32px" tile>
                    <v-icon size="28" :color="$root.themeColor">mdi-camera</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>Camera</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item v-if="formObj.PostType == 1" @click="fn_gotoAddImagePage">
                <v-list-item-avatar>
                  <v-avatar size="32px" tile>
                    <v-icon size="28" :color="$root.themeColor">mdi-image-multiple</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>Gallery</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="fn_addTags()">
                <v-list-item-avatar>
                  <v-avatar size="32px" tile>
                    <v-icon size="28" :color="$root.themeColor">mdi-account-tag</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>Tag People</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>

          <v-bottom-navigation v-if="!showList" v-model="value" :input-value="active" color="indigo">

            <v-btn v-if="formObj.PostType == 1" @click="fn_captureImage(1)">
              <v-icon :color="$root.themeColor" size="28">mdi-camera</v-icon>
            </v-btn>
            
            <v-btn v-if="formObj.PostType == 1" @click="fn_gotoAddImagePage">
              <v-icon :color="$root.themeColor" size="28">mdi-image-multiple</v-icon>
            </v-btn>

            <v-btn @click="fn_addTags()">
              <v-icon :color="$root.themeColor" size="28">mdi-account-tag</v-icon>
            </v-btn>

            <v-btn @click="fn_openList()">
              <v-icon :color="$root.themeColor" size="28">mdi-dots-horizontal-circle</v-icon>
            </v-btn>
          </v-bottom-navigation>
        </v-footer>

      </v-dialog>
      <dialog-addtag ref="ref_dialogAddTags" :fn_updateTags="fn_UpdateTagsItems"></dialog-addtag>
    </div>
</template>

<script>
import axios from 'axios';
import addAttendeeDialog from './attendees'
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// import Quill from 'quill';
// import QuillMention from 'quill-mention';
// import 'quill/dist/quill.snow.css';
// import 'quill-mention/dist/quill.mention.css';

// Quill.register('modules/mention', QuillMention);


// import { MentionComponent } from "@syncfusion/ej2-vue-dropdowns";
// import Mention from '@tiptap/extension-mention'
// import Document from '@tiptap/extension-document'
// import { Editor, EditorContent } from '@tiptap/vue-2'
// import StarterKit from '@tiptap/starter-kit'
// import Paragraph from '@tiptap/extension-paragraph'
// import Text from '@tiptap/extension-text'
// import Bold from '@tiptap/extension-bold';
// import Italic from '@tiptap/extension-italic';
// import Mention from '@tiptap/extension-mention';
// import Link from '@tiptap/extension-link';

// import suggestion from './suggestion.js'



  export default {
    props:['fn_addcontent', 'fn_updateContentPostID', 'fn_updateData'],
    data: () => ({
      attendeeInfo:null,
      loading:false,
      error:false,
      contentDialog:false,
      text: null,
      formObj: {Content: null, PostType: null, IsAdminPost: null},
      sheet: false,
      tiles: [
        { icon: 'mdi-image-multiple', title: 'Photo' },
        { icon: 'mdi-account-tag', title: 'Tag People' },
        // { img: 'hangouts.png', title: 'Hangouts' },
        // { img: 'messenger.png', title: 'Messenger' },
        // { img: 'google.png', title: 'Google+' },
      ],
      value: 0,
      showList: true,
      active: false,
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],  // Include the options for bold, italic, and underline
          ]
        }
      },
      footerHeight: 0,
      listPosition: { top: 0, left: 0 },
      UserList: [
        { AttendeeID: 23, userAlias: 'v-waseemkhan', userName: 'Waseem Khan' },
        { AttendeeID: 24, userAlias: 'v-iansari', userName: 'Irfan Ansari' },
        // Add more users here...
      ],
      searchValue: '',
      filteredUserList: [],
      cursorPosition: 0,
      dialogLoading: false,
      ProgramAdminName: 'EMEA Gold Club',
      rotatedImage: null,
      rotationAngle: 0,
      rotatedImageBase64: null,
      IsRotateClicked: false,
      mentionListVisible: null
    }),
    components: {
      // EditorContent,
      quillEditor,
      'dialog-addtag' : addAttendeeDialog,
      //'editor-content' : Editor
      // 'ejs-mention': MentionComponent
    },
    methods:{

        fn_showDialog: function(item){
          this.contentDialog = true;
          this.text = null;
          if(item == undefined){
            this.formObj = { 
              CanDelete: true,
              CanEdit: true,
              Caption : null,
              CommentCount : null,
              Content: null,
              CreatedAt: null,
              IsLikedByUser: null,
              IsUserTagged: null,
              LikeCount : null,
              LikeID: null,
              MediaUrl: null,
              PostID : null,
              PostType: null,
              PostedBy : null,
              PostedByAlias: null,
              Tags: [],
              PostUserTags: [],
              ImageBase64: null,
              IsAdminPost: null
            };
          }
          else{
            this.formObj = JSON.parse(JSON.stringify(item));
            if(this.formObj.PostType == 2 && this.formObj.MediaUrl != null){
              this.formObj.ImageBase64 = this.formObj.MediaUrl.replace('data:image/png;base64, ', '');
            }
            else{
              this.formObj.ImageBase64 = null;
            }
          }
          this.sheet = true;
          this.showList = true;
          this.filteredUserList = [];
        },

        onEditorReady(editor) {
          this.editor = editor;
        },
        applyBold() {
          this.editor.quill.format('bold', true);
        },
        applyItalic() {
          this.editor.quill.format('italic', true);
        },
        applyUnderline() {
          this.editor.quill.format('underline', true);
        },

        // renderItems(items) {
        //   return items
        //     .map(item => `<div class="mention-item">${item.label}</div>`)
        //     .join('');
        // },

        // toggleBold() {
        //   this.editor.chain().focus().toggleBold().run();
        // },
        // toggleItalic() {
        //   this.editor.chain().focus().toggleItalic().run();
        // },

        fn_gotoChatPage: function(){
            this.$router.push({path:'/platinumclub/chatpage', query:{name:this.attendeeInfo.WinnerName}});
        },

        fn_abbreviatedName: function(str){
            let words = str.trim().split(' ');

            if(words.length > 1){
                return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
            }
            else{
                return words[0][0].toUpperCase();
            }
        },

        fn_postCaption: function(){

          if(!navigator.onLine){
              this.showToast("nointernet");
              return;
          }

          var tempdata = {};
          var currentDate = new Date();
          tempdata.CanDelete = true;
          tempdata.CanEdit = true;
          tempdata.Caption = this.formObj.Caption;
          tempdata.CommentCount = null;
          tempdata.Content = this.formObj.Content;
          tempdata.CreatedAt = currentDate.toISOString();
          tempdata.IsLikedByUser = false;
          tempdata.IsUserTagged = false;
          tempdata.LikeCount = null;
          tempdata.LikeID = null;
          tempdata.MediaUrl =  this.formObj.ImageBase64 == null? null : 'data:image/png;base64, ' + this.formObj.ImageBase64;
          tempdata.PostID = null;
          tempdata.PostType = this.formObj.PostType;
          tempdata.PostedBy = this.formObj.IsAdminPost ? this.$root.tripOnLoadData.AttendeeInfo.MobileAdminPostName : this.$root.tripOnLoadData.AttendeeInfo.AttendeeName;
          tempdata.PostedByAlias = null;
          tempdata.Tags = [];
          tempdata.PostUserTags = this.formObj.PostUserTags;
          tempdata.ImageBase64 = this.formObj.ImageBase64;
          tempdata.IsAdminPost = this.formObj.IsAdminPost;
          tempdata.ImagePath = null;
          tempdata.ImageName = null;
          tempdata.IsPhotoLiked = null;
          
          this.fn_addcontent(tempdata);
          this.contentDialog = false;
          //this.$parent.Items.unshift(tempdata);

          this.loading = true;
          this.error = false;
          this.$root.fn_getToken(token=>{
              if(token == 'error'){
                  this.loading = false;
                  this.error = true;
                  this.$root.fn_showToast('exception');
              }
              else{
                  var headersObj = {
                      headers: {
                          'Authorization': 'bearer '+token,
                          }
                      };

                  // let base64String = this.formObj.ImageBase64;
                  // if(this.formObj.PostType == 2 && this.formObj.ImageBase64 && this.formObj.ImageBase64 != ''){
                  //   base64String = base64String.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
                  // }
                  let dataObj = {};
                  dataObj.PostType = this.formObj.PostType;
                  dataObj.Content = this.formObj.PostType == 2 ? null : this.formObj.Content;
                  dataObj.ImageBase64 = this.formObj.PostType == 1 ? null : this.formObj.ImageBase64;
                  dataObj.Caption = this.formObj.PostType == 1? null : this.formObj.Caption;
                  dataObj.PostUserTags = this.formObj.PostUserTags;
                  dataObj.IsAdminPost = this.formObj.IsAdminPost;

                  axios.post(this.$root.baseUrl + "/api/mobileapp/attendee/post", dataObj, headersObj, {timeout:30000})
                  .then(response => {
                      this.loading = false;
                      this.fn_addItemId(response.data.PostID);
                      this.contentDialog = false;
                      console.log(response);
                      // this.giftItems = response.data.gifts;
                  }).catch(e => {
                      this.loading = false;
                      this.error = true;
                      console.log(e);
                      let toastMsg = e && e.message ? e.message : 'exception';
                      this.$root.fn_showToast(toastMsg);
                  })
              }
          })
        },

        fn_openList: function(){
          this.active = !this.active
          setTimeout(() => {
            this.showList = true;
          }, 100);
        },

        fn_hideList: function(){
          this.active = true;
          this.showList = false;
          
        },

        fn_gotoAddImagePage: function(){
          this.fn_checkStorage();
            // this.$router.push({path:'/platinumclub/attendee/'+attendeeId});
        },

        fn_addImageContentData: function(tempdata){
          this.contentDialog = false;
          this.fn_addcontent(tempdata)
          //this.Items.unshift(tempdata);
        },

        fn_addItemId: function(postid){
          this.fn_updateContentPostID(postid);
        },

        fn_checkStorage: function(){
          this.attachmentSheet = false;
          this.formObj.ImageBase64 = null;
          this.formObj.ImageBase64 = null; 
          this.formObj.Caption = null;
          //this.formObj.Content = null;
          if(this.$root.platform === 'ios'){
              this.fn_selectFile(1);
          }
          else{
              this.$root.fn_checkStoragePermission(()=>{
                  this.fn_selectFile(1)
              })
          }
        },

        fn_selectFile: function(fileType){
            let self = this;
            let dataObj = {};
            dataObj.fileType = fileType;
            // dataObj.fileExtensions = ['PDF','DOC','DOCX','TEXT','XLX','XLSX','CSV'];
            dataObj.imageExtension = ['JPG','JPEG','PNG','GIF'];
            // this.dialogLoading = true;
            this.postData("SelectLocalDocument", dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        console.log('Called File');
                        // self.fn_uploadfile(response, fileType)
                        if(self.$root.platform === 'ios'){
                            // self.selectedFileDetails = response;
                            // self.selectedFileDetails.fileType = fileType;
                            // self.dialogFileViewer = true;
                            // self.selectedFileDetails.captionText = null;
                            // self.fn_uploadfile(response.base64StringData, response, fileType);

                            //self.formObj.ImageBase64 = response.ImageBase64;
                            self.formObj.fileName = response.fileName;
                            self.formObj.filePath = response.filePath;
                            self.formObj.fileExtension = response.fileExtension;
                            self.formObj.fileType = fileType;
                            self.formObj.ImageBase64 = response.base64StringData;
                            self.formObj.Caption = self.formObj.Content;
                            //self.dialogFileViewer = true;
                            // self.formObj.captionText = null;
                            self.formObj.PostType = 2;
                            // self.fn_uploadfile(response.base64StringData, response, fileType);
                        }
                        else{
                            self.fn_openFile(response, fileType);
                        }
                    },
                    onError: function(error){
                        self.dialogLoading = false;
                        console.log(error);
                    }
                }
            );
        },

        fn_openFile: function(fileDetails, fileType){
            let self = this;
            this.getFilterData('OpenDocumentFile', fileDetails.filePath, null,
                {
                    onSuccess: function(response){
                        console.log(response);
                        // self.formObj = fileDetails;
                        // self.formObj.fileType = fileType;
                        // self.formObj.ImageBase64 = response.ImageBase64;
                        self.formObj.fileName = response.fileName;
                        self.formObj.filePath = response.filePath;
                        self.formObj.fileExtension = response.fileExtension;
                        self.formObj.fileType = fileType;
                        self.formObj.ImageBase64 = response;
                        self.formObj.PostType = 2;
                        self.formObj.Caption = self.formObj.Content;
                        //self.selectedFileDetails.captionText = null;
                        //self.dialogFileViewer = true;
                        //self.imageDialog = true;
                        //self.fn_uploadfile(response, fileDetails, fileType)
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.dialogLoading = false;
                    }
                }
            );
        },

        fn_uploadThumbnail: function (fileList) {
          // this.LoadingMessage = 'Uploading File...';
          // this.loadingdialog = true;
          var formData = new FormData();
          var file = fileList.target.files[0];
          formData.append("UploadedDocument", file);
          // var postUrl = "/api/" + _tripsiteid + "/tripregistration/mobile/admin/photogallery/uploadalbum";
          // axios.post(postUrl, formData).then(response => {
          //     console.log(response);
          //     response.status;
          //     response.statusText;
          //     this.FormObj.ThumbnailUrl = response.data.Url;
          //     this.loadingdialog = false;
          // })
          //     .catch(error => {
          //         console.log(error);
          //         this.loadingdialog = false;
          //     })
        },

        fn_removeImage: function(){
          this.formObj.ImageBase64 = null;
          this.formObj.Content = this.formObj.Caption;
          this.formObj.PostType = 1;
          this.$forceUpdate();
        },

        fn_diableBtn: function(){
          if(this.formObj.PostType == 1 && (this.formObj.Content == null || this.formObj.Content == '')){
            return true
          }
          else if(this.formObj.PostType == 2 && this.formObj.ImageBase64 == null){
            return true
          }
          else
            return false
        },

        fn_editPost: function(){

          if(!navigator.onLine){
              this.showToast("nointernet");
              return;
          }

          var tempdata = {};
          var currentDate = new Date();
          tempdata.CanDelete = this.formObj.PostType;
          tempdata.CanEdit = this.formObj.PostType;
          tempdata.Caption = this.formObj.Caption;
          tempdata.CommentCount = this.formObj.CommentCount;
          tempdata.Content = this.formObj.Content;
          tempdata.CreatedAt = currentDate.toISOString();
          tempdata.IsLikedByUser = this.formObj.IsLikedByUser;
          tempdata.IsUserTagged = this.formObj.IsUserTagged;
          tempdata.LikeCount = this.formObj.LikeCount;
          tempdata.LikeID = this.formObj.LikeID;
          tempdata.MediaUrl = this.formObj.ImageBase64 == null? null : 'data:image/png;base64, ' + this.formObj.ImageBase64;
          tempdata.PostID = this.formObj.PostID;
          tempdata.PostType = this.formObj.PostType;
          tempdata.PostedBy = this.formObj.IsAdminPost ? this.$root.tripOnLoadData.AttendeeInfo.MobileAdminPostName : this.$root.tripOnLoadData.AttendeeInfo.AttendeeName;
          tempdata.PostedByAlias = this.formObj.PostedByAlias;
          tempdata.Tags = [];
          tempdata.PostUserTags = this.formObj.PostUserTags;
          tempdata.ImageBase64 = this.formObj.ImageBase64;
          tempdata.IsPhotoLiked = null;
          //this.Items.unshift(tempdata);
          this.fn_updateData(tempdata);

          this.contentDialog = false;


          this.linearLoading = true;
          this.error = false;
          this.$root.fn_getToken(token=>{
              if(token == 'error'){
                  this.loading = false;
                  this.error = true;
                  this.$root.fn_showToast('exception');
              }
              else{
                  var headersObj = {
                      headers: {
                          'Authorization': 'bearer '+token,
                          }
                      };

                  let dataObj = {};
                  dataObj.PostType = this.formObj.PostType;
                  dataObj.PostID = this.formObj.PostID;
                  dataObj.Content = this.formObj.PostType == 2 ? null : this.formObj.Content;
                  dataObj.ImageBase64 = this.formObj.PostType == 1 ? null : this.formObj.ImageBase64;
                  dataObj.Caption = this.formObj.PostType == 1? null : this.formObj.Caption;
                  dataObj.PostUserTags = this.formObj.PostUserTags;

                  axios.post(this.$root.baseUrl + "/api/mobileapp/attendee/post", dataObj, headersObj, {timeout:30000})
                  .then(response => {
                      this.linearLoading = false;
                      //this.contentDialog = false;
                      //this.fn_updatePostId(response.data.PostID);
                      //this.$parent.Items[0].PostID = response.data.PostID;
                      console.log(response);
                      // this.giftItems = response.data.gifts;
                  }).catch(e => {
                      this.linearLoading = false;
                      this.error = true;
                      console.log(e);
                      let toastMsg = e && e.message ? e.message : 'exception';
                      this.$root.fn_showToast(toastMsg);
                  })
              }
          })
        },

        fn_addTags: function(){
          let tags = [];
            if (this.formObj.PostUserTags && this.formObj.PostUserTags.length > 0) {
                for (let i = 0; i < this.formObj.PostUserTags.length; i++) {
                    tags.push(this.formObj.PostUserTags[i].AttendeeID);
                }
            }
            this.$refs['ref_dialogAddTags'].fn_showDialog(tags);
        },

        fn_UpdateTagsItems: function(tags){
          this.formObj.PostUserTags = JSON.parse(JSON.stringify(tags));
        },

        fn_captureImage: function(fileType){
            let self = this;
            // this.attachmentSheet = false;
            this.$root.fn_checkCameraPermission(()=>{
                // this.dialogLoading = true;
                this.getData('CaptureImage', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        if(self.$root.platform === 'ios'){
                            // self.formObj.fileName = response.fileName;
                            self.formObj.filePath = response.filePath;
                            self.formObj.fileExtension = response.fileExtension;
                            self.formObj.fileType = fileType;
                            self.formObj.ImageBase64 = response.base64StringData;
                            self.formObj.PostType = 2;
                        }
                        else{
                            self.fn_openFile(response, fileType);
                        }
                        // self.fn_openFile(response, fileType);
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.dialogLoading = false;
                        if(error && error.toLowerCase() == "request cancel"){
                            console.log('request cancel');
                        }
                        else if(error == "file size exceeds"){
                            self.$root.showToast("File size exceeds");   
                        }
                        else{
                            self.$root.showToast("Something went wrong. Try again");    
                        }
                    }
                });
            })
        },

        onTextChange(delta, oldDelta, source) {
          console.log(delta);
          console.log(oldDelta);
          console.log(source);
          const editor = this.$refs.myQuillEditor.quill;
          const range = editor.getSelection();
          // console.log(range);

          if (range) {
            this.cursorPosition = range.index;
            const textBeforeCursor = editor.getText(0, range.index);
            // console.log(textBeforeCursor);

            const atIndex = textBeforeCursor.lastIndexOf('@');
            console.log(atIndex);
            if (atIndex !== -1) {
              // alert(atIndex);
              this.searchValue = textBeforeCursor.slice(atIndex + 1);
              // alert(this.searchValue);
              if (this.searchValue) {
                this.filteredUserList = this.UserList.filter(user =>
                  user.userName.toLowerCase().startsWith(this.searchValue.toLowerCase())
                );
                this.showList = this.filteredUserList.length > 0;
                console.log(this.showList);
                this.calculateListPosition();
              } else {
                this.showList = false;
              }
            } else {
              this.showList = false;
            }
          }
        },

        calculateListPosition() {
          const editor = this.$refs.myQuillEditor.quill;
          const bounds = editor.getBounds(this.cursorPosition);
          this.listPosition.top = bounds.top + bounds.height;
          this.listPosition.left = bounds.left;
        },

        selectUser(user) {
          const editor = this.$refs.myQuillEditor.quill;
          editor.deleteText(this.cursorPosition - this.searchValue.length - 1, this.searchValue.length + 1);
         
          editor.insertText(this.cursorPosition - this.searchValue.length - 1,
          `@${user.userName} `,
            {
              'color': '#007bff',
              'underline': true,
              'link': `/platinumclub/${user.AttendeeID}`
            }
          );
          this.showList = false;
        },

        fn_cancelCaptureImage: function(){
            this.dialogLoading = false;
        },

        fn_rotateImage() {
          // Rotate the image by 90 degrees counterclockwise
          this.rotationAngle -= 90;
          this.rotateImageUsingCanvas(this.formObj.ImageBase64);
          this.IsRotateClicked = true;
        },

        resetImageUploadState() {
          // Reset the base64 image and any other relevant state
          this.formObj.ImageBase64 = ''; // Clear the image base64
          this.rotationAngle = 0; // Reset rotation angle if needed
          this.IsRotateClicked = false;
        },

        rotateImageUsingCanvas(base64Image) {
          const img = new Image();
          img.src = 'data:image/png;base64,' + base64Image;

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set canvas dimensions depending on rotation
            if (this.rotationAngle % 180 === 0) {
              canvas.width = img.width;
              canvas.height = img.height;
            } else {
              canvas.width = img.height;
              canvas.height = img.width;
            }

            // Rotate the canvas
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((this.rotationAngle * Math.PI) / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);

            // Convert the canvas to base64 string and update formObj.ImageBase64
            this.formObj.ImageBase64 = canvas.toDataURL('image/png').replace('data:image/png;base64,', '');

            // After rotation, upload the updated base64 string
            // this.uploadRotatedImage(this.formObj.ImageBase64);
          };
        },

        // fn_hideList() {
        //   this.showList = false;
        // }
    },
    mounted() {
      this.loading = true;
      window.js_globals.fn_cancelCaptureImage = this.fn_cancelCaptureImage;
     
      // this.editor = new Editor({
      //   extensions: [
      //     StarterKit,
      //     Document,
      //     Paragraph,
      //     Text,
      //     Bold,
      //     Italic,
      //     Mention.configure({
      //       suggestion: {
      //         items: (query) => {
      //           return [
      //             { id: 1, label: 'John Doe' },
      //             { id: 2, label: 'Jane Smith' },
      //           ].filter(item =>
      //             item.label.toLowerCase().includes(query.toLowerCase())
      //           );
      //         },
      //       },
      //       HTMLAttributes: {
      //         class: 'mention',
      //       },
      //     }),
      //   ],
      //   content: ``,
      // })
    },
    // beforeDestroy() {
    //   this.editor.destroy()
    // },
  }
</script>
<style>
/* @import '~quill/dist/quill.snow.css'; */
/* @import '~quill-mention/dist/quill.mention.css'; */

.mention-list {
  position: absolute;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 1000;
}

.mention-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mention-list li {
  padding: 5px 10px;
  cursor: pointer;
}

.mention-list li:hover {
  background-color: #f0f0f0;
}



.quill-editor .ql-container .ql-editor{
  font-size: 12pt;
}

.ql-container.ql-snow{
  border: none !important;
}

.richetxtcustom_style .ql-toolbar{
  position: absolute;
  width: 100%;
  z-index: 2;
  padding-left: 18px;
  bottom: 0px;
}

.richetxtcustom_style .ql-container{
  padding-bottom: 44px;
}

.showoptionsatbottomclosed{
  height: calc(100% - 109px);
}

.showoptionsatbottomopened {
  height: calc(100% - 230px);
}

/* .showoptionsatbottomclosed .ql-toolbar{
  bottom: 36px;
} */

.addimage_richtexteditor{
  min-height: 100px;
  padding-bottom: 30px;
  position: relative;
}

.addimage_richtexteditor .ql-toolbar{
  position: absolute;
  bottom: 0px;
}

/* .showoptionsatbottomopened .ql-toolbar{
  bottom: 100px;
} */

.image-showoptionsatbottomopened{
  bottom: 100px;
}

.richetxtcustom_style .ql-editor.ql-blank::before{
  content: "What's on your mind?" !important;
  font-size: 16pt;
  font-weight: 300;
  font-style: normal;
}

.addimage_richtexteditor .ql-editor.ql-blank::before{
  content: "Say something about this photo..." !important;
  font-size: 14pt;
  font-weight: 300;
  font-style: normal;
}

.postdata_textare{
  height: 100%;
}

.postdata_textare .v-input__control{
  height: 100%;
}


.postdata_textare .v-input__control .v-input__slot,
.postdata_textare .v-input__control .v-input__slot .v-text-field__slot,
.postdata_textare .v-input__control .v-input__slot .v-text-field__slot textarea{
  height: 100%;
}

.postdata_textare .v-input__control .v-input__slot .v-text-field__slot textarea::placeholder{
  font-size: 16pt;
  font-weight: 300;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.disable_btn.theme--dark.v-btn.v-btn--disabled,
.disable_btn{
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, .38) !important;
}

.postdata_textare .v-input__slot::after,
.postdata_textare .v-input__slot::before,
.photocaption_textarea .v-input__slot::after,
.photocaption_textarea .v-input__slot::before{
  display: none;
}

.remove_btn{
  position: absolute;
  right: 10px;
  top: 10px;
}

.tagged_Section{
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14pt;
  font-weight: 500;
}

.override_scroll{
  overflow: hidden !important;
}

</style>

<!-- <style lang="scss">
.tiptap {
  :first-child {
    margin-top: 0;
  }

  .mention {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    box-decoration-break: clone;
    color: var(--purple);
    padding: 0.1rem 0.3rem;
  }
}
</style> -->