<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div style="padding-bottom: 30px;">
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/PlatinumClub_RestaurantsBanner_420x200_2023.png"></v-img>

                <!-- <accordians :content="accordianContent" v-if="accordianContent"></accordians> -->
                <template v-if="listContents">
                    <!-- <v-card class="ma-4" v-for="(item, index) in listContents" :key="index"> -->
                    <div v-for="(item, index) in listContents" :key="index">
                        <v-card class="ma-4">

                            <v-list>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon class="pink lighten-1" dark>dinner_dining</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="title">{{item.RestaurantName}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.RestaurantAreaName}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <v-divider class="mx-4"></v-divider>

                            <v-card-text :class="{'pb-0':item.Description}">
                                <v-layout row wrap ma-0>
                                    <v-flex xs6 sm6 md6>
                                        <div><strong>Reserved For</strong></div>
                                        <div>{{item.AttendeeName}}</div>
                                    </v-flex>
                                    <v-flex xs6 sm6 md6>
                                        <div><strong>Number of Party</strong></div>
                                        <div>{{item.NumbersofParty}}</div>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12 class="pt-4">
                                        <div><strong>Reservation Time</strong></div>
                                        <div>{{item.ReservationTime | moment("ddd, MMM Do YYYY, hh:mm a")}}</div>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>

                            <v-card-actions @click="show = !show" v-if="item.Description">
                                <v-btn color="primary" text>{{show ? 'Less' : 'More'}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn icon>
                                    <v-icon>{{ show ? 'expand_less' : 'expand_more' }}</v-icon>
                                </v-btn>
                            </v-card-actions>

                            <!-- <v-divider class="mx-4"></v-divider> -->

                            <v-expand-transition>
                                <div v-show="show">
                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text>
                                        <v-layout row wrap ma-0>
                                            <v-flex xs12 sm12 md12>
                                                <div><strong>Description</strong></div>
                                                <div v-html="item.Description"></div>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </div>
                            </v-expand-transition>

                            </v-card>

                        <v-card class="ma-4">
                            <!-- <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/TripMobile_RestaurantReservationMap_420x200.png">
                            </v-img>
                            <div class="right-align">
                                <a type="maplink" class="pr-2" href="/assets/platinumclub/resort_map_7.pdf" target="_blank">View Map</a>
                            </div> -->
                            <!-- <v-list class="pa-0">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.RestaurantName}}</v-list-item-title>
                                        <v-list-item-subtitle>Route Information</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <v-divider class="mx-4"></v-divider> -->
                            <v-card-text :class="{'pb-0':item.BusRouteDescription}">
                                <!-- <div class="pb-2 text-subtitle-1">
                                    Route Information
                                </div> -->
                                <v-layout row wrap ma-0>
                                    <v-flex xs6 sm6 md6>
                                        <div><strong>Bus Route</strong></div>
                                        <div>{{item.BusRouteName}}</div>
                                    </v-flex>
                                    <v-flex xs6 sm6 md6 v-if="item.BusStopName">
                                        <div><strong>Bus Stop</strong></div>
                                        <div>{{item.BusStopName}}</div>
                                    </v-flex>

                                    <!-- <v-flex xs12 sm12 md12 pt-4 right-align>
                                        <v-btn class="" color="white" small text style="background:rgba(0,0,0,0.4)" capitalize>Download Map</v-btn>
                                    </v-flex> -->

                                    <!-- <v-flex xs12 sm12 md12 class="pt-4">
                                        <div><strong>Route Description</strong></div>
                                        <div v-html="item.BusRouteDescription"></div>
                                    </v-flex> -->
                                </v-layout>
                            </v-card-text>

                            <v-card-actions @click="showMapDescription = !showMapDescription" v-if="item.BusRouteDescription">
                                <v-btn color="primary" text>{{showMapDescription ? 'Less' : 'More'}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn icon>
                                    <v-icon>{{ showMapDescription ? 'expand_less' : 'expand_more' }}</v-icon>
                                </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                                <div v-show="showMapDescription">
                                    <v-divider class="mx-4"></v-divider>

                                    <v-card-text>
                                        <v-layout row wrap ma-0>
                                            <v-flex xs12 sm12 md12>
                                                <div><strong>Description</strong></div>
                                                <div v-html="item.BusRouteDescription"></div>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </div>
                            </v-expand-transition>
                        </v-card>

                        <v-divider class="mx-8" v-if="listContents.length - 1 != index"></v-divider>
                    </div>
                </template>

                <v-divider class="mx-8"></v-divider>

                <v-card class="ma-4" v-if="listContents && listContents.length">
                    <v-subheader>Resort Map</v-subheader>
                    <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/TripMobile_RestaurantReservationMap_420x200.png"></v-img>
                    <div class="right-align pa-3 pr-4">
                        <a type="maplink" href="/assets/platinumclub/resort_map_7.pdf" target="_blank">View Map</a>
                    </div>
                </v-card>

                <v-card :class="{'ma-4':listContents && listContents.length}" v-if="overviewContent" id="overview_content" :flat="!listContents || (listContents && !listContents.length) ? true : false">
                    <v-card-actions @click="fn_toggleOverview" v-if="listContents && listContents.length">
                        <v-btn color="primary" text>Overview</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon>
                            <v-icon>{{ showOverview ? 'expand_less' : 'expand_more' }}</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                        <div v-show="showOverview">
                            <v-card-text class="pa-0">
                                <overview :content="overviewContent" v-if="overviewContent"></overview>
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </v-card>
                

                <!-- <div v-if="!overviewContent && (!listContents || listContents && !listContents.length)" class="pa-4 center-align light-opacity-color">You have no dinner reservation yet</div> -->

                <!-- <template v-if="error">
                    <div style="text-align:center;" class="pa-4">
                        <p>Something went wrong. Please try again.</p>
                        <br>
                        <v-btn outlined @click="fn_getDetails"><v-icon left>refresh</v-icon> Retry</v-btn>
                    </div>
                </template> -->

                <template v-if="!loading && (!overviewContent && (!listContents || listContents && !listContents.length)) && !error">
                    <div class="pa-4">
                        <div center-align>
                            <!-- <v-icon class="placeholder-icon">flight_takeoff</v-icon> -->
                            <p class="pt-4 light-opacity-color">You have no dinner reservation yet</p>
                            <v-btn class="mt-4" outlined @click="fn_getDetails"><v-icon left>refresh</v-icon> Reload</v-btn>
                        </div>
                    </div>
                </template>

            </template>
            
        </div>

        <div class="pcfooter_image">
            <img src="../assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
        </div>
        <div class="pcfooter_wave_image">
            <img src="../assets/2024/PlatinumClub_BottomWave_1120x112.png">
        </div>
        <!-- <div class="pcfooter_image">
            <img v-show="0" src="../assets/PlatinumClub2023_FooterImage_420x200.png">
        </div> -->

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_refresh">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

    </div>
</template>

<script>
import overview from './sub_components/overview'
// import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'
import axios from 'axios'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        // 'accordians':accordians
    },
    data() {
        return {
            show:false,
            showMapDescription:false,
            showOverview:false,
            error:false,
            loading:false
        }
    },
    methods:{
        fn_openExternalPage: function(pageUrl){
            this.getData('OpenBrowser', pageUrl, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_toggleOverview: function(){
            this.showOverview = !this.showOverview
            if(this.showOverview){
                setTimeout(() => {
                    document.getElementById("overview_content").scrollIntoView({behavior: 'smooth'});
                }, 300);
            }
        },

        // fn_updateAvailable: function(dataObj){
        fn_updateAvailable: function(){
            console.log('update available');
            // let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
            // if(updateObj.pageName==='restaurantreservation'){
            //     if(this.overviewContent || (this.listContents && this.listContents.length)){
            //         this.snackbarMsg = true;
            //     }
            //     else{
            //         this.fn_refresh();
            //     }
            // }
        },

        fn_refresh: function(){
            this.fn_getData('restaurantreservation', () => {
                this.showOverview = !this.listContents || (this.listContents && !this.listContents.length) ? true : false;
                console.log(this.showOverview);
            });
        },

        fn_getDetails: function(){

            // if(!navigator.onLine){
            //     this.error = true;
            //     this.$root.fn_showToast("nointernet");
            //     return;
            // }

            this.loading = true;
            // this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    // this.error = true;
                    this.fn_refresh();
                    // this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/page/components?PagePath=/restaurantreservation&ModifiedDate=2022-08-01", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.snackbarMsg = false;
                        let responseData = response.data.Pages[0];
                        this.pageName = responseData.PageTitle;
                        if(responseData.PageComponents){
                            this.winnerContent = this.$root.platform === 'ios' ? JSON.parse(responseData.PageComponents) : JSON.parse(responseData.PageComponents);
                        }
                        if(responseData.ListContents){
                            this.listContents = this.$root.platform === 'ios' ? JSON.parse(responseData.ListContents) : JSON.parse(responseData.ListContents);
                            this.showOverview = !this.listContents || (this.listContents && !this.listContents.length) ? true : false;
                        }
                    }).catch(e => {
                        this.loading = false;
                        // this.error = true;
                        console.log(e);
                        this.fn_refresh();
                        // let toastMsg = e && e.message ? e.message : 'exception';
                        // this.$root.fn_showToast(toastMsg);
                    })
                }
            })

        },
    },
    mounted(){
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        // this.fn_refresh();
        this.fn_getDetails();
    }
}
</script>