<template>
    <div>
        <v-toolbar :color="$root.themeColor ? $root.themeColor : '#395b83'" flat dark>
            <v-btn icon @click="fn_goBack" v-if="$route.query.goback">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Club Trips</v-toolbar-title>
        </v-toolbar>
        
        <template v-if="loading && !tripsData">
            <div class="pa-8">
                <v-img width="120" style="margin:auto" src="../assets/giphy.gif"></v-img>
            </div>
        </template>

        <template v-if="error">
            <div style="text-align:center;" class="pa-4">
                <p>Something went wrong. Please try again.</p>
                <br>
                <v-btn outlined @click="fn_getFirebaseToken"><v-icon left>refresh</v-icon> Retry</v-btn>
            </div>
        </template>

        <template>
        
            <div class="ml-4 mr-4 pb-4" v-if="tripsData">
                <div class="mt-4" v-for="(item, index) in tripsData.trips" :key="index">
                    <div class="title pt-2">
                        {{item.Name}}
                        <v-progress-circular class="ml-4" indeterminate color="grey" width="3" size="20" v-if="loading && selectedTripId == item.TripSiteID"></v-progress-circular>
                    </div>
                    <v-subheader dense class="pa-0">
                        {{item.Schedule}}
                        <v-spacer></v-spacer>
                        <v-chip small label color="green" text-color="white" v-if="!loading && item.TripSiteID == $root.tripSiteId">Active</v-chip>
                    </v-subheader>
                    <v-card :disabled="loading" @click="fn_getToken(item.TripSiteID)">
                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/PlatinumClub_TripSelection_420x200.png"
                            v-if="item.TripSiteID == 12">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/GCAmericas_TripSelection_420x200.png"
                            v-else-if="item.TripSiteID == 13">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/GCAsia_TripSelection_420x200.png"
                            v-else-if="item.TripSiteID == 14">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/GCEMEA_TripSelection_420x200.png"
                            v-else-if="item.TripSiteID == 15">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/TripMobileBanners_Pinnacle_420x200.png"
                            v-else-if="item.TripSiteID == 16">
                        </v-img>
                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/PlatinumClub_TripSelection_420x200.png"
                            v-if="item.TripSiteID == 20">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/GCAmericas_TripSelection_420x200.png"
                            v-else-if="item.TripSiteID == 17">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/GCAsia_TripSelection_420x200.png"
                            v-else-if="item.TripSiteID == 18">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/GCEMEA_TripSelection_420x200.png"
                            v-else-if="item.TripSiteID == 19">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/TripMobileBanners_Pinnacle_420x200.png"
                            v-else-if="item.TripSiteID == 16">
                        </v-img>

                        <v-img
                           :lazy-src="$root.imagePlaceholder"
                            height="180"
                            src="../assets/DemoTripBanner.png"
                            v-else-if="item.TripSiteID == 11">
                        </v-img>
                    </v-card>
                </div>
            </div>

            <template v-if="!loading && !error && (!tripsData || (tripsData && !tripsData.trips.length))">
                <div class="placeholder-wrapper pa-4">
                    <div center-align>
                        <v-icon class="placeholder-icon">luggage</v-icon>
                        <p class="pt-4 light-opacity-color">No trip found</p>
                        <v-btn class="mt-4" outlined @click="fn_getFirebaseToken"><v-icon left>refresh</v-icon> Reload</v-btn>
                    </div>
                </div>
            </template>

            <!-- <div class="pa-4 light-opacity-color center-align" v-if="!loading && (!tripsData || (tripsData && !tripsData.trips.length))">You are not registered in any trip</div> -->
            
        </template>

    </div>
</template>

<script>

import {cometchatLogin} from '../cometchat_login.js'

export default{
    data(){
        return{
            snackbar:true,
            tripsData:null,
            loading:false,
            error:false,
            selectedTripId:null
        }
    },
    methods:{
        // fn_initActions: function(tripId){
        //     console.log('Im init');
        //     if(tripId == 8){
        //         this.fn_enterTrip('gcamericas', tripId);
        //     }
        //     else if(tripId == 7){
        //         this.fn_enterTrip('platinumclub', tripId)
        //     }
        //     else{
        //         if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosMessageInterface) {
        //             window.webkit.messageHandlers.iosMessageInterface.onPageLoadCompleted('1', JSON.stringify({}));
        //         }
        //         else if (typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null) {
        //             /*global AndroidRoutingTest*/
        //             AndroidRoutingTest != undefined ? AndroidRoutingTest.onPageLoadCompleted('1', JSON.stringify({})) : '';
        //         }
        //     }
        // },

        fn_getFirebaseToken: function(){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                return;
            }

            let self = this;
            this.loading = true;
            this.error = false;
            this.getData("FirebaseToken", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_getTrips(response)
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.error = true;
                        self.$root.fn_showToast("exception");
                    }
                }
            );
        },

        fn_getTrips: function(token){
            if(!navigator.onLine){
                this.loading = false;
                this.$root.fn_showToast("nointernet");
                return;
            }

            this.loading = true;
            this.error = false;
            
            let self = this;
            let dataObj = {};
            dataObj.Token = token;
            
            this.getServerData('GetTrips', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.loading = false;
                        self.tripsData = response;
                        localStorage.setItem('DeviceID', response.DeviceID);
                        //self.$root.DeviceID = response.DeviceID;
                        self.$route.query.tsid ? self.fn_getToken(self.$route.query.tsid) : '';
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.error = true;
                        // self.$root.fn_showToast(error.Message);
                        let toastMsg = error && error.Message ? error.Message : 'exception';
                        self.$root.fn_showToast(toastMsg);
                        // self.$root.fn_showToast("exception");
                    }, 
                }
            );
        },

        fn_getToken: function(tripSiteId){

            if(this.$root.tripSiteId == tripSiteId){
                this.$root.fn_showToast("You are already logged in to this trip");
                return;
            }

            this.loading = true;

            this.selectedTripId = tripSiteId;

            let self = this;
            this.getData("FirebaseToken", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_confirmLogin(response, tripSiteId)
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast("exception");
                    }
                }
            );
        },

        // fn_confirmLogin: function(token){
        fn_confirmLogin: function(token, tripSiteId){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                return;
            }

            this.loading = true;
            
            let self = this;
            let dataObj = {};
            dataObj.TripSiteID = Number(tripSiteId);
            dataObj.DeviceID = this.tripsData.DeviceID;
            dataObj.Token = token;
            // dataObj.UserType = 1 //static for now
            
            this.postServerData('ConfirmLogin', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_reLoginToFirebase(response);
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        // self.$root.fn_showToast("exception");
                        let toastMsg = error && error.Message ? error.Message : 'exception';
                        self.$root.fn_showToast(toastMsg);
                    }, 
                }
            );
        },

        fn_reLoginToFirebase: function(confirmationObj){
            let self = this;
            let dataObj = {};
            dataObj.TripSiteID = confirmationObj.TripSiteID;
            dataObj.UserType = confirmationObj.UserType ? confirmationObj.UserType : 0;
            dataObj.DeviceID = confirmationObj.DeviceID;
            dataObj.Token = confirmationObj.Token;

            this.postData("FirebaseReLoginUsingCustomToken", dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        console.log(self.$root.Envirenment);
                        self.fn_enterTrip(confirmationObj.TripSiteID);
                        if(confirmationObj.TripSiteID == 17 || confirmationObj.TripSiteID == 20 || confirmationObj.TripSiteID == 18 || confirmationObj.TripSiteID == 19){
                            cometchatLogin.fn_initCometChat(self, confirmationObj.TripSiteID, (response)=>{
                                if(response == 'error'){
                                    self.loading = false;
                                }
                            });
                        }
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast("exception");
                    }
                }
            );
        },

        fn_enterTrip: function(tripId){
            let self = this;
            let pcgcTripId = tripId.toString();
            this.getData('SwitchTrip', pcgcTripId, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        localStorage.removeItem('lastactivetab');
                        if(self.$root.tripSiteId && localStorage.getItem("pn_registered_"+self.$root.tripSiteId)){
                            localStorage.removeItem("pn_registered_"+self.$root.tripSiteId);
                        }
                        self.$root.tripSiteId = tripId;

                        // let pageQuery = {};
                        // this.$route.query.nid ? pageQuery.nid = this.$route.query.nid : '';
                        // this.$route.query.pname ? pageQuery.pname = this.$route.query.pname : '';
                        self.$route.query.nid ? localStorage.setItem('n_id',self.$route.query.nid) : '';
                        self.$route.query.pname ? localStorage.setItem('p_name',self.$route.query.pname) : '';
                        // if(self.$root.Envirenment != 3){
                        //     self.fn_saveChatUrl(tripId);
                        // }
                        self.fn_saveChatUrl(tripId);
                        if(tripId == 20){
                            self.$router.replace({path:'/platinumclub'});
                        }
                        else if(tripId == 17){
                            self.$router.replace({path:'/gcamericas'});
                        }
                        else if(tripId == 18){
                            self.$router.replace({path:'/gcasia'});
                        }
                        else if(tripId == 19){
                            self.$router.replace({path:'/gcemea'});
                        }
                        else if(tripId == 11){
                            self.$router.replace({path:'/demotrip'});
                        }
                        else if(tripId == 16){
                            self.$router.replace({path:'/pinnacle'});
                        }
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast("exception");
                    }
                }
            );
        },

        // saving chat url in case of app is closed and notification arrives to open chat app directly
        fn_saveChatUrl: function(tripId){
            var chatUrl = null;
            console.log(this.$root.Envirenment);
            if(this.$root.Envirenment == 3)
                chatUrl = "https://clubtrip-chatapp.web.app/";
            else
                chatUrl = "https://pcgctripchat-uat.web.app/";
            let dataObj = {"chatUrl": chatUrl + "?tid="+tripId+"&notification=1"};
            this.postData("PostChatUrlToLocal", dataObj,
            {
                onSuccess: function(response){
                    console.log(response);
                },
                onError: function(error){
                    console.log(error);
                }
            });
        },

        fn_goBack: function(){
            if(this.$route.query.goback){
                this.$router.go(-1);
            }
            else{
                this.exitApp();
            }
        }

        // fn_enterTrip: function(path, tripId){
        //     let self = this;
        //     let pcgcTripId = tripId.toString();
        //     this.getData('SwitchTrip', pcgcTripId, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 localStorage.removeItem('lastactivetab');
        //                 self.$router.replace('/'+path);
        //             },
        //             onError: function(error){
        //                 self.loading = false;
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },
    },
    mounted(){
        // this.fn_toast();
        // console.log(this.$route);
        // window.js_globals.fn_initActions = this.fn_initActions;
        // if(this.$route.params && this.$route.params.tripsData){
        //     this.tripsData = this.$route.params.tripsData;
        // }
        // if(this.$route.params && this.$route.params.token){
        //     this.fn_getTrips();
        // }
        console.log(this.$root.tripOnLoadData);
        window.js_globals.fn_onBackPress = this.fn_goBack;
        this.fn_getFirebaseToken();
    }
}
</script>