<template>
  <div class="schedule-page-wrapper">
    <v-tabs fixed-tabs v-model="activeTabIndex" background-color="#f0f0f0" :slider-color="$root.themeColor" @change="fn_tabChange">
        <!-- <v-tab capitalize>Mon, Nov 14</v-tab>
        <v-tab capitalize>Tue, Nov 15</v-tab>
        <v-tab capitalize>Wed, Nov 16</v-tab>
        <v-tab capitalize>Thu, Nov 17</v-tab>
        <v-tab capitalize>Fri, Nov 18</v-tab> -->

        <v-tab capitalize v-for="(item, index) in agendaDates" :key="index">{{new Date(item.Month+'/'+item.Date+'/'+item.Year) | moment("ddd, MMM D")}}</v-tab>

        <v-tab-item v-for="(tabItem, tabItemIndex) in agendaDates" :key="tabItemIndex">
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>
            

            <template v-else>
                <schedule-card :content="listContents" :readMore="$parent.fn_readMore" :addToCalendar="fn_checkCalenderPermission"></schedule-card>    
            </template>
        </v-tab-item>

    </v-tabs>

    <v-bottom-sheet inset v-model="bottomSheet" style="border-radius:24px">
        <v-sheet class="text-center" style="border-radius:24px">
            <v-card style="border-top-left-radius:24px;border-top-right-radius:24px" v-if="calendarObj && calendarObj.calendars">
                <v-card-title primary-title class="pl-6">Select Calendar</v-card-title>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item v-for="(item, index) in calendarObj.calendars" :key="index" @click="fn_addToCalendar(item)">
                        <v-list-item-content>
                            <v-list-item-title>{{item}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>   
            </v-card>
        </v-sheet>
    </v-bottom-sheet>

    <!-- <p class="pl-4" style="font-size:small;color:rgba(0,0,0,0.54)"><em>Schedule subject to change</em></p> -->

    <v-snackbar v-model="snackbarMsg" :timeout="-1">
        Updated content available
        <template v-slot:action="{ attrs }">
            <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
            <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_tabChange(activeTabIndex)">Refresh Now</v-btn>
        </template>
    </v-snackbar>
  </div>
</template>

<script>
import scheduleCard from '../info_pages/sub_components/schedule-card'

import {pageMixin} from '../info_pages/info_page_mixin'

export default{
    mixins:[pageMixin],
    props:['paramsObj'],
    components:{
        'schedule-card':scheduleCard
    },
    data(){
        return{
            activeTabIndex:0,
            // listContents:null,
            agendaDates:[
                {"Date":4, "Month":10, "Year":2024},
                {"Date":5, "Month":10, "Year":2024},
                {"Date":6, "Month":10, "Year":2024},
                {"Date":7, "Month":10, "Year":2024},
                {"Date":8, "Month":10, "Year":2024}
            ],
            currentItem:null,
            dialogTabIndex:0,
            contentDialog:false,
            calendarObj:null,
            bottomSheet:false
        }
    },
    methods:{
        fn_openMoreDetails: function(item){
            this.currentItem = item;
            this.$root.fn_postClickMetrics('My Agenda', item.Title, null);
            this.dialogTabIndex = 0;
            this.contentDialog = true;
        },

        fn_tabChange: function(tabIndex){
            this.listContents = null;
            // this.fn_getFilterData('agenda', '2022-10-'+("0"+(tabIndex+7)).slice(-2));
            // let date = '2022-11-'+("0"+(tabIndex+this.agendaDates[0].Date)).slice(-2);
            let currentTab = this.agendaDates[tabIndex];
            let date = currentTab.Year + '-' + currentTab.Month + '-' +  ("0"+(currentTab.Date)).slice(-2);
            // this.fn_getData('agenda', null, date);
            this.fn_getPageDataFromServer('agenda', null, date);
            this.$root.fn_postClickMetrics('My Agenda', date, null);
        },

        fn_updateAvailable: function(dataObj){
            let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
            if(updateObj.pageName==='agenda'){
                if(this.listContents){
                    this.snackbarMsg = true;
                }
                else{
                    // let date = '2022-11-'+("0"+(this.activeTabIndex+this.agendaDates[0].Date)).slice(-2);
                    let currentTab = this.agendaDates[this.activeTabIndex];
                    let date = currentTab.Year + '-' + currentTab.Month + '-' +  ("0"+(currentTab.Date)).slice(-2);
                    // this.fn_getData('agenda', null, date);
                    this.fn_getPageDataFromServer('agenda', null, date);
                    // this.fn_getFilterData('agenda', '2022-10-'+("0"+(this.activeTabIndex+7)).slice(-2));
                }
            }
        },

        fn_checkCalenderPermission: function(item){
            let self = this;
            this.currentItem = item;

            this.$root.fn_postClickMetrics('My Agenda', item.Title, null);

            if(this.$root.platform === 'android'){
                this.fn_addToCalendar(null);
            }
            else{
                this.getData('CheckCalenderPermission', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            if(JSON.parse(response)){
                                self.fn_getAllCalendars();
                            }
                        },
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            }
            
        },

        fn_getAllCalendars: function(){
            let self = this;
            this.getData('GetAllCalendar', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.calendarObj = response
                        self.bottomSheet = true;
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_addToCalendar: function(calendar){
            this.bottomSheet = false;
            let self = this;
            let dataObj = {};
            dataObj.calendarName = calendar;
            dataObj.eventTitle = this.currentItem.Title;
            dataObj.timeZoneId = "Pacific/Honolulu";
            // dataObj.startDate = this.currentItem.StartTime;
            // dataObj.endDate = this.currentItem.EndTime;

            let startTime = new Date(this.currentItem.StartTime);
            startTime.setHours(startTime.getHours() + 10);
            let endTime = new Date(this.currentItem.EndTime);
            endTime.setHours(endTime.getHours() + 10);
            dataObj.startDate = startTime.getFullYear()+'-'+("0" + (startTime.getMonth() + 1)).slice(-2)+'-'+("0" + startTime.getDate()).slice(-2)+'T'+startTime.toTimeString().split(' ')[0];
            dataObj.endDate = endTime.getFullYear()+'-'+("0" + (endTime.getMonth() + 1)).slice(-2)+'-'+("0" + endTime.getDate()).slice(-2)+'T'+endTime.toTimeString().split(' ')[0];

            console.log(JSON.stringify(dataObj));

            this.postData("AddEventToCalendar", dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$root.platform === 'ios' ? self.$root.fn_showToast("Added to calendar") : '';
                    },
                    onError: function(error){
                        console.log(error);
                        self.$root.fn_showToast("exception");
                    }
                }
            );
        },
    },
    mounted(){
        this.$root.fn_postClickMetrics('My Agenda', 'Default', null);
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        console.log(JSON.stringify(this.paramsObj));
        if(localStorage.getItem('last_schedule_active_tab_'+this.$root.tripSiteId)){
            this.activeTabIndex = Number(localStorage.getItem('last_schedule_active_tab_'+this.$root.tripSiteId));
            localStorage.removeItem('last_schedule_active_tab_'+this.$root.tripSiteId);
        }
        else if(this.paramsObj && this.paramsObj.agendaDay){
            let currentIndex = this.agendaDates.findIndex(item=>{
                return item.Date == this.paramsObj.agendaDay;
            });
            this.activeTabIndex = currentIndex;
        }
        else{
            let currentDate = new Date().getDate();
            let currentMonth = new Date().getMonth() + 1;
            let eventDayIndex = this.agendaDates.findIndex(item=>{
                return item.Date === currentDate && item.Month === currentMonth;
            });
            eventDayIndex !== -1 ? this.activeTabIndex = eventDayIndex : '';
        }
        
        
        
        let currentTab = this.agendaDates[this.activeTabIndex];
        let date = currentTab.Year + '-' + currentTab.Month + '-' +  ("0"+(currentTab.Date)).slice(-2);
        // this.fn_getData('agenda', null, date);
        this.fn_getPageDataFromServer('agenda', null, date);
        this.$root.fn_postClickMetrics('My Agenda', date, null);
    }
}

</script>