<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Gifts</v-toolbar-title>
        </v-toolbar>

        <div class="ml-4 mr-4">

            <template v-if="!loading && (!giftItems || !giftItems.length) && !error">
                <div class="placeholder-wrapper pa-4">
                    <div center-align>
                        <v-icon class="placeholder-icon">redeem</v-icon>
                        <p class="pt-4 light-opacity-color">No gifts for now</p>
                        <v-btn class="mt-4" outlined @click="fn_gifts"><v-icon left>refresh</v-icon> Reload</v-btn>
                    </div>
                </div>
            </template>

            <template v-if="error">
                <div style="text-align:center;" class="pa-4">
                    <p>Something went wrong. Please try again.</p>
                    <br>
                    <v-btn outlined @click="fn_gifts"><v-icon left>refresh</v-icon> Retry</v-btn>
                </div>
            </template>

            <!-- <v-layout row wrap ma-0>
                <v-flex xs6 sm6 md6>
                    <v-card color="blue-grey darken-3" dark class="pb-2" height="248" style="border-radius:10px" @click="fn_collectGift">
                        <div style="">
                            <v-icon style="font-size:48px">redeem</v-icon>
                        </div>
                        <v-card-title>Gift Voucher</v-card-title>
                            <v-card-subtitle>Get the value of <strong>$1000</strong> cash</v-card-subtitle>

                            <v-card-actions>
                                <v-btn class="ml-2 mt-5" outlined rounded small>
                                    Collect Now
                                </v-btn>
                            </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout> -->

            <template v-if="loading">
                <div class="pa-8">
                    <v-img width="120" style="margin:auto" src="../../../assets/giphy.gif"></v-img>
                </div>
            </template>

            <template v-else>
                <div class="mt-4" v-if="giftItems && giftItems.length">
                    <v-card :disabled="fn_isCollected(item)" :color="fn_isCollected(item) ? 'blue-grey lighten-1' : 'blue-grey darken-3'" :class="{'mt-4':index!==0}" dark class="pb-2" style="border-radius:10px" @click="fn_collectGift(item)" v-for="(item, index) in giftItems" :key="index">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-card-title class="text-h5" style="word-break:break-word">{{item.Title}}</v-card-title>
                                <v-card-subtitle class="pt-0 pb-0" v-html="item.GiftDescription"></v-card-subtitle>
                                <v-card-subtitle class="pt-0 pb-0" v-html="item.Location"></v-card-subtitle>
                                <v-card-subtitle class="pt-0 pb-0" v-html="item.DisplayTime"></v-card-subtitle>

                                <v-card-actions :class="{'pl-0':fn_isCollected(item)}">
                                    <v-chip class="mt-4" color="white" text-color="black" label v-if="fn_isCollected(item)" style="border-top-left-radius:0 !important;border-bottom-left-radius:0 !important">
                                        Collected
                                    </v-chip>
                                    <v-btn class="ml-2 mt-5" outlined rounded small v-else>
                                        Collect now
                                    </v-btn>
                                </v-card-actions>

                                
                            </div>

                            <v-avatar class="ma-4" size="80" tile>
                                <v-icon style="font-size:64px">redeem</v-icon>
                            </v-avatar>
                        </div>
                    </v-card>
                    <!-- <v-card color="blue-grey darken-3" dark class="pb-2" style="border-radius:10px" @click="fn_collectGift">
                        <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="text-h5">Gift Voucher</v-card-title>
                            <v-card-subtitle>Get the value of <strong>$1000</strong> cash</v-card-subtitle>

                            <v-card-actions>
                                <v-btn class="ml-2 mt-5" outlined rounded small>
                                    Collect Now
                                </v-btn>
                            </v-card-actions>
                        </div>

                            <v-avatar class="ma-4" size="125" tile>
                                <v-icon style="font-size:64px">redeem</v-icon>
                            </v-avatar>
                        </div>
                    </v-card>

                    <v-card color="light-green darken-3" dark class="pb-2 mt-4" style="border-radius:10px" @click="fn_collectGift">
                        <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="text-h5">Gift Voucher</v-card-title>
                            <v-card-subtitle>Dinner dining at Grand Velas Riviera Maya</v-card-subtitle>

                            <v-card-actions>
                                <v-btn class="ml-2 mt-5" outlined rounded small>
                                    Collect Now
                                </v-btn>
                            </v-card-actions>
                        </div>

                        <v-avatar
                            class="ma-4"
                            size="125"
                            tile
                        >
                            <v-icon style="font-size:64px">dinner_dining</v-icon>
                        </v-avatar>
                        </div>
                    </v-card>

                    <v-card color="pink darken-3" dark class="pb-2 mt-4" style="border-radius:10px" @click="fn_collectGift">
                        <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="text-h5">Gift Voucher</v-card-title>
                            <v-card-subtitle>Tickets for the live music concert</v-card-subtitle>

                            <v-card-actions>
                                <v-btn class="ml-2 mt-5" outlined rounded small>
                                    Collect Now
                                </v-btn>
                            </v-card-actions>
                        </div>

                        <v-avatar
                            class="ma-4"
                            size="125"
                            tile
                        >
                            <v-icon style="font-size:64px">music_note</v-icon>
                        </v-avatar>
                        </div>
                    </v-card> -->
                </div>
            </template>


        </div>

        <v-bottom-sheet inset v-model="bottomSheet" style="border-radius:24px">
            <v-sheet class="text-center" style="border-radius:24px">
                <v-card style="border-top-left-radius:24px;border-top-right-radius:24px" v-if="currentItem">
                    <v-card-title primary-title class="pl-6">
                        {{currentItem.Title}}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text left-align>
                        <!-- <div class="pa-3 blue-grey darken-3" style="width:fit-content;margin:auto;border-radius:5px">
                            <v-icon color="#fff">redeem</v-icon>
                        </div> -->
                        <v-icon>redeem</v-icon>
                        <!-- <p class="mt-2">Get the value of <strong>$1000</strong> cash</p> -->
                        <p class="mt-2">{{currentItem.GiftDescription}}</p>
                    </v-card-text>
                    
                    <div class="pa-4">
                        <v-btn block outlined rounded @click="fn_gotoQR(3)" :disabled="currentItem.CollectedForGuest" v-if="$root.tripOnLoadData.AttendeeInfo.AttendeeType != 1">
                            {{currentItem.CollectedForGuest && !currentItem.CollectedForBoth ? 'Already collected for self' : 'Collect for self'}}
                        </v-btn>
                        <v-btn block outlined rounded @click="fn_gotoQR(2)" :disabled="currentItem.CollectedForAttendee || currentItem.CollectedForBoth" v-if="$root.tripOnLoadData.AttendeeInfo.AttendeeType == 1">
                            {{currentItem.CollectedForAttendee && !currentItem.CollectedForBoth ? 'Already collected for self' : 'Collect for self'}}
                        </v-btn>
                        <v-btn block outlined rounded class="mt-4" @click="fn_gotoQR(3)" :disabled="currentItem.CollectedForGuest || currentItem.CollectedForBoth" v-if="!currentItem.IsAttendeeOnly && $root.tripOnLoadData.AttendeeInfo.AttendeeType == 1">
                             {{currentItem.CollectedForGuest && !currentItem.CollectedForBoth ? 'Already collected for guest' : 'Collect for guest'}}
                        </v-btn>
                        <!-- <v-divider class="mt-6 ml-16 mr-16"></v-divider> -->
                        <!-- <v-btn block rounded color="blue-grey darken-3"  style="color:#fff" class="mt-4" @click="fn_gotoQR(1)" :disabled="currentItem.CollectedForBoth || currentItem.CollectedForAttendee || currentItem.CollectedForGuest" v-if="!currentItem.IsAttendeeOnly && $root.tripOnLoadData.AttendeeInfo.AttendeeType == 1">
                            {{currentItem.CollectedForBoth ? 'Already collected for both' : 'Collect for both'}}
                        </v-btn> -->
                    </div>
                </v-card>
            </v-sheet>
        </v-bottom-sheet>

    </div>
</template>

<script>
import axios from 'axios';

export default{
    data(){
        return{
            // snackbar:true
            bottomSheet:null,
            currentItem:null,
            giftItems:null,
            loading:false,
            error:false
        }
    },
    methods:{
        fn_collectGift: function(item){
            this.currentItem = item;
            this.bottomSheet = true;
        },

        fn_isCollected: function(item){

            if(item.IsAttendeeOnly){
                return item.CollectedForAttendee ? true : false;
            }
            else{
                if(this.$root.tripOnLoadData.AttendeeInfo.AttendeeType == 1){
                    if(item.CollectedForBoth){
                        return true;
                    }
                    else if(item.CollectedForAttendee && item.CollectedForGuest){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    if(item.CollectedForGuest){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            }
        },

        fn_gotoQR: function(giftFor){
            this.$router.push({name:'qrcode', params:{giftId:this.currentItem.GiftID, giftFor:giftFor}});
        },

        fn_gifts: function(){

            if(!navigator.onLine){
                this.error = true;
                this.$root.fn_showToast("nointernet");
                return;
            }

            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    this.error = true;
                    this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/gifts", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.giftItems = response.data.gifts;
                    }).catch(e => {
                        this.loading = false;
                        this.error = true;
                        console.log(e);
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                    })
                }
            })
            
        },

        // fn_gifts: function(){
        //     if(!navigator.onLine){
        //         this.showToast("Internet not available");
        //         return;
        //     }

        //     this.loading = true;
        //     this.error = false;
            
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.TripSiteID = 7;
        //     this.getServerData('Gifts', dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 console.log(JSON.stringify(response.gifts))
        //                 self.giftItems = response.gifts;
        //                 self.loading = false;
        //                 // self.fn_generateQRCode();
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //                 self.loading = false;
        //                 self.error = true;
        //                 self.showToast("Something went wrong. Please try again");
        //             }, 
        //         }
        //     );
        // },

        fn_getRandomColorClass: function(){
            let colorClasses = ['blue-grey', 'light-green', 'pink'];
            let classIndex = Math.floor(Math.random()*colorClasses.length);
            return colorClasses[classIndex];
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Gifts', null);
        // window.js_globals.fn_initActions = this.fn_initActions;
        this.fn_gifts();
    }
}
</script>