<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Scan QR Code</v-toolbar-title>
        </v-toolbar>

        <div>
            <v-card flat center-align class="pa-4 pt-8">
                <template v-if="$root.tripOnLoadData.AttendeeInfo">
                    <div class="title">
                        <template v-if="this.$route.params.giftFor == 1">
                            {{$root.tripOnLoadData.AttendeeInfo.AttendeeName}} &amp; Guest
                        </template>
                        <template v-else-if="this.$route.params.giftFor == 2">
                            {{$root.tripOnLoadData.AttendeeInfo.AttendeeName}}
                        </template>
                        <template v-else>
                            Guest
                        </template>
                    </div>
                    <div style="font-size:1rem" v-if="$root.tripOnLoadData.AttendeeInfo.JobTitle">{{$root.tripOnLoadData.AttendeeInfo.JobTitle}}</div>
                </template>
                
                <v-card-text class="text-center">
                    <template v-if="!loading">
                        <v-icon>redeem</v-icon>
                        <p class="mt-2 mb-0">Get this QR Code scanned to collect your gifts</p>         
                    </template>
                    
                    <!-- Scan this QR Code for more information -->

                    <v-img style="margin:auto" width="320" height="320" :src="dataUrl">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-card-text>
            </v-card>

            <!-- <div style="color:#999">Show this QR Code at the reception to get scanned</div> -->
        </div>
        

    </div>
</template>

<script>
import QRCode from 'qrcode'
import axios from 'axios';

  export default {
    data: () => ({ 
        dataUrl:null,
        loading:false
    }),
    methods:{

        // fn_getRequestId: function(){
        //     // this.showToast("Test");
        //     if(!navigator.onLine){
        //         this.showToast("Internet not available");
        //         return;
        //     }
            
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.TripSiteID = 7;
        //     dataObj.GiftID = this.$route.params.giftId;
        //     dataObj.GiftFor = this.$route.params.giftFor;
        //     this.postServerData('GiftRequestId', dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.fn_generateQRCode(response.RequestID);
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //             }, 
        //         }
        //     );
        // },

        fn_getRequestId: function(){

            if(!navigator.onLine){
                this.showToast("nointernet");
                return;
            }

            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    this.error = true;
                    this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    let dataObj = {};
                    dataObj.GiftID = this.$route.params.giftId;
                    dataObj.GiftFor = this.$route.params.giftFor;

                    axios.post(this.$root.baseUrl + "/api/mobileapp/gifting/qrcoderequest", dataObj, headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.fn_generateQRCode(response.data.RequestID);
                        // this.giftItems = response.data.gifts;
                    }).catch(e => {
                        this.loading = false;
                        this.error = true;
                        console.log(e);
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                    })
                }
            })

        },

        fn_generateQRCode: function(requestId){
            // With promises
            QRCode.toDataURL(requestId).then(url => {
                this.dataUrl = url;
                this.fn_addWatcher(requestId);
            })
            .catch(err => {
                console.error(err)
            })
        },

        fn_addWatcher: function(requestId){
            let dataObj = {};
            dataObj.RequestID = requestId;
            this.postData('AddWatcherToGift', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },  

        fn_updateGiftDetails: function(){
            console.log('update');
            this.$root.fn_showToast("You've collected your gift.");
            this.$router.go(-1);
        }

        // fn_updateGiftDetails: function(obj){
        //     console.log('update');

        //     let dataObj = {};
        //     dataObj.GiftID = obj.GiftID;
        //     dataObj.TripSiteID = this.$root.tripSiteId;
        //     if(obj.GiftFor == 1){
        //         dataObj.CollectedForBoth = true;
        //     }
        //     if(obj.GiftFor == 2){
        //         dataObj.CollectedForAttendee = true;
        //     }
        //     else{
        //         dataObj.CollectedForGuest = true;
        //     }

        //     this.postData('UpdateGiftItemDetailToLocal', dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // }
    },
    mounted: function(){
        // this.fn_generateQRCode();
        this.fn_getRequestId();
        window.js_globals.fn_detailsUpdated = this.fn_updateGiftDetails;
    }
  }
</script>