<template>
    <div class="explore_page" v v-scroll="onScroll">

        <v-toolbar :height="64" :color="$root.themeColor" dark>
            <div style="height:40px;width:40px;padding:4px;background-color:#ffefe2;border-radius:10px;margin-right:16px">
                <img width="32" height="32" src="../../../assets/PC_Maui_Icon_128x128.png"/>
            </div>
            <v-toolbar-title>Platinum Club</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-badge :content="$root.tripOnLoadData.NotificationCount" :value="$root.tripOnLoadData.NotificationCount" color="#f69220" overlap v-if="$root.tripOnLoadData">
                <v-icon @click="fn_gotoNotificationPage">notifications</v-icon>
            </v-badge>
            <v-btn @click="fn_navigatetopage" icon class="pr-1">
                <v-icon size="30px">mdi-account-circle</v-icon>
            </v-btn>
            <!-- <v-badge class="ml-4" :content="$root.unreadMessageCount" :value="$root.unreadMessageCount" color="#f69220" overlap>
                <v-icon color="white" @click="$root.fn_openChatView">chat</v-icon>
            </v-badge> -->
        </v-toolbar>

        <div>

            <template v-if="loadingTiles">
                <div class="pa-8 center-align">
                    <v-progress-circular color="blue-grey" width="3" indeterminate></v-progress-circular>
                </div>
            </template>

            

            <template v-else>
            
                <div class="slider-card-wrapper" v-if="topTiles && topTiles.length">

                    <!-- <template>
                        <v-card class="hero_bg_image_formatting mr-4" color="red" height="160" dark @click="fn_openSocialFeed">
                            <div class="d-flex flex-no-wrap justify-space-between">
                                <div>
                                    <v-card-title class="text-h5">Social Feed</v-card-title>

                                    <v-card-subtitle>Social feed</v-card-subtitle>

                                    <v-card-actions style="width:100%">
                                        <v-btn class="ml-2 mr-2" fab icon height="40px" right width="40px">
                                            <v-icon>pin_drop</v-icon>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn capitalize text>View More</v-btn>
                                    </v-card-actions>
                                </div>
                                <div class="watermarkimage"></div>
                            </div>
                        </v-card>        

                    </template> -->

                    <template v-for="(item, index) in topTiles">

                        <v-card class="hero_bg_image_formatting" :color="item.Color" :class="{'full-width':topTiles.length == 1, 'mr-4':topTiles.length-1 != index}" height="160" dark :key="index" v-if="item.Status" @click="$parent.fn_readMore(item)">
                            <div class="d-flex flex-no-wrap justify-space-between">
                                <div>
                                    <v-card-title class="text-h5">{{item.Title}}</v-card-title>

                                    <v-card-subtitle>{{item.Overview}}</v-card-subtitle>

                                    <v-card-actions style="width:100%">
                                        <v-btn class="ml-2 mr-2" fab icon height="40px" right width="40px">
                                            <v-icon>{{item.Icon}}</v-icon>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn capitalize text v-if="item.ReadMoreContent">
                                            View More
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                                <div class="watermarkimage"></div>
                            </div>
                        </v-card>        
                        
                    </template>

                </div>

                <!--<div class="mt-7">
                    <h3 class="title ml-4 mr-4 center-align">Discover more about this trip</h3>
                    <v-layout class="top-tiles-grid" row wrap center-align ma-0 mb-10>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile" @click="$parent.fn_gotoInfoPage('photoalbum')">
                                <div class="tile-image-wrapper">
                                    <v-icon style="font-size:42px">photo_library</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Photo Gallery</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile" @click="$parent.fn_gotoInfoPage('resort')">
                                <div class="tile-image-wrapper">
                                    <v-icon style="font-size:42px">hotel</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Resort Info 2</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile" @click="$parent.fn_gotoInfoPage('infobooth')">
                                <div class="tile-image-wrapper">
                                    <v-icon style="font-size:42px">info</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Info Booth</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile" @click="$parent.fn_gotoInfoPage('eveningevents')">
                                <div class="tile-image-wrapper">
                                    <v-icon style="font-size:42px">event</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Evening Events</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile" @click="$parent.fn_gotoInfoPage('activities')">
                                <div class="tile-image-wrapper">
                                    <v-icon style="font-size:42px">directions_run</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">My Activities</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile" @click="$parent.fn_gotoInfoPage('allactivities')">
                                <div class="tile-image-wrapper">
                                    <v-icon style="font-size:42px">local_activity</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Activities for Purchase</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile" @click="$parent.fn_gotoInfoPage('restaurantreservation')">
                                <div class="tile-image-wrapper">
                                    <v-icon style="font-size:42px">dinner_dining</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Evenings at Leisure</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile" @click="$parent.fn_gotoInfoPage('gifts')">
                                <div class="tile-image-wrapper">
                                    <v-icon style="font-size:42px">redeem</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Gifts</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile">
                                <div class="tile-image-wrapper" @click="$parent.fn_gotoInfoPage('buyininformation')">
                                    <v-icon style="font-size:42px">group</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Buy-In Package</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile">
                                <div class="tile-image-wrapper" @click="$parent.fn_gotoInfoPage('safetyandsecurity')">
                                    <v-icon style="font-size:42px">health_and_safety</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Safety &amp; Security</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile">
                                <div class="tile-image-wrapper" @click="$parent.fn_gotoInfoPage('galaseating')">
                                    <v-icon style="font-size:42px">nightlife</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Gala Event Seating</div>
                            </div>
                        </v-flex>

                        <v-flex xs4 sm4 md4 mt-7>
                            <div class="tile">
                                <div class="tile-image-wrapper" @click="$parent.fn_gotoInfoPage('departureinfo')">
                                    <v-icon style="font-size:42px">flight_takeoff</v-icon>
                                </div>
                                <div class="tile-title mt-2 mr-4 ml-4">Departure Info</div>
                            </div>
                        </v-flex>

                    </v-layout>

                </div>-->

                <!-- <div class="more-tiles-wrapper mr-10 ml-10 mt-6">
                    <h3 class="title ml-4 mr-4 mb-3 center-align">Discover more about this trip</h3>
                    <v-layout class="pctile_formatting" row wrap center-align ml-0 mt-0 mb-10>
                        <template v-for="(item, index) in infoPages">
                            <v-flex d-flex :key="index" xs6 sm6 md6 mt-4 class="px-0">
                                <v-card class="tile-wrapper card_tile pa-0 mr-2" :class="(index+1)%2 !== 0 ? 'pr-1' : 'pl-1'" @click="$parent.fn_gotoInfoPage(item.Path)">
                                    <div class="tile">
                                        <div class="tile-image-wrapper">
                                            <v-img width="60" alt="winnerimage" :src="item.ImageName"></v-img>
                                            <div class="tile-title mt-2 mr-4 ml-4">{{item.Title}}</div>
                                        </div>
                                        
                                    </div>
                                </v-card>
                            </v-flex>
                        </template>
                    </v-layout>
                </div> -->

                <div class="more-tiles-wrapper mr-4 ml-4 mt-6">
                    <h3 class="title ml-4 mr-4 center-align">Discover more about this trip</h3>
                    <v-layout class="tile-section" row wrap ma-0>

                        <template v-for="(item, index) in infoPages">
                            <v-flex xs6 sm6 md6 mt-4  :key="index" v-if="IsShowTile(item.PageName) == true">
                                <v-card flat class="tile-wrapper pa-0 mr-2" :class="(index+1)%2 !== 0 ? 'pl-1 pr-1' : 'pl-1 pr-1'" @click="$parent.fn_gotoInfoPage(item.Path)">
                                    <v-img :lazy-src="$root.imagePlaceholder" height="120" :src="item.ImageName"></v-img>
                                    <!-- <img :src="item.ImageName" alt=""> -->
                                    <div class="tile-title">{{item.Title}}</div>
                                </v-card>
                            </v-flex>  
                        </template>
                         
                    </v-layout>
                </div>
                
            </template>
            

            
        </div>

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getTopTilesData">Refresh Now</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

import axios from 'axios';
// import { CometChat } from "@cometchat-pro/chat";

export default {
    data () {
      return {
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
          'deep-purple accent-4',
        ],
        offsetTop: 0,
        topTiles:null,
        snackbarMsg:false,
        loadingTiles:false,
        pages:null,
        infoPages:[
            //{'Title':'Executive Attendance', 'PageName':'Executive Attendance', 'Path':'executiveattendence', 'ImageName':require('../assets/GeneralInfo.svg')},
            {'Title':'Resort Info', 'PageName':'Hotel', 'Path':'resort', 'ImageName':require('../assets/2024/PlatinumClub_ResortInfoTile_240x175.jpg')},
            {'Title':'Buy-In Package', 'PageName':'Buy-In Package', 'Path':'buyininformation', 'ImageName':require('../assets/2024/PlatinumClub_BuyInTile_240x175.jpg')},
            {'Title':'Info Booth', 'PageName':'Info Booth', 'Path':'infobooth', 'ImageName':require('../assets/2024/PlatinumClub_InfoBoothTile_240x175.jpg')},
            {'Title':'Evenings at Leisure', 'PageName':'Evening Events', 'Path':'eveningevents', 'ImageName':require('../assets/2024/PlatinumClub_EveningsAtLeisureTile_240x175.jpg')},
            {'Title':'My Confirmations', 'PageName':'Your Confirmations', 'Path':'yourconfirmations', 'ImageName':require('../assets/2024/PlatinumClub_YourConfirmationsTile_240x175.jpg')},
            {'Title':'Gala Event', 'PageName':'Gala Seating', 'Path':'galaseating', 'ImageName':require('../assets/2024/PlatinumClub_GalaEventTile_240x175.jpg')},
            {'Title':'Headshots', 'PageName':'Head Shot', 'Path':'headshotpc', 'ImageName':require('../assets/2024/PlatinumClub_HeadshotsTile_240x175.jpg')},
            {'Title':'Spa Award', 'PageName':'Spa Award', 'Path':'spaaward', 'ImageName':require('../assets/2024/PC_SpaAwardTile_240x175.png')},
            // {'Title':'Key Locations', 'Path':'keylocations', 'ImageName':require('../assets/2024/PC_KeyLocationsTile_240x175.png')},
            // {'Title':'Shuttle Services', 'Path':'shuttle', 'ImageName':require('../assets/2024/PC_ShuttleTile_240x175.png')},
            {'Title':'Gifts', 'PageName':'Gifts', 'Path':'gifts', 'ImageName':require('../assets/2024/PlatinumClub_GiftsTile_240x175.jpg')},
            {'Title':'Photo Gallery', 'PageName':'Album', 'Path':'photoalbum', 'ImageName':require('../assets/2024/PlatinumClub_PhotoGalleryTile_240x175.jpg')},
            {'Title':'Donate', 'PageName':'Donate', 'Path':'donate', 'ImageName':require('../assets/2024/PlatinumClub_DonateTile_240x175.jpg')},
            //{'Title':'Activities for Purchase', 'PageName':'Activities', 'Path':'allactivities', 'ImageName':require('../assets/PlatinumClub_Image3Banner_200x200.png')},
            
            
            //{'Title':'My Activities', 'PageName':'My Activities', 'Path':'activities', 'ImageName':require('../assets/PlatinumClub_ActivitiesTile_200x200_2023.png')},
            
            //{'Title':'Meals At Leisure', 'PageName':'Restaurant Reservation', 'Path':'restaurantreservation', 'ImageName':'dinner_dining'},
            
            
            {'Title':'Safety & Security', 'PageName':'Safety & Security', 'Path':'safetyandsecurity', 'ImageName':require('../assets/2024/PlatinumClub_SafetyAndSecurityTile_240x175.jpg')},
            {'Title':'Departure', 'PageName':'Departure Info', 'Path':'departureinfo', 'ImageName':require('../assets/2024/PlatinumClub_DepartureTile_240x175.jpg')}
            
            
            
        ],
        unreadMessageCount:0
      }
    },
    methods: {
        onScroll () {
            this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
        },

        fn_navigatetopage: function(){
            this.$router.push({path:'/platinumclub/profile'});
        },

        IsShowTile: function(value){
            console.log(this.$root.tripOnLoadData.AttendeeInfo);
            console.log("Is Show tile called");
            if(value == 'Head Shot' || value == 'Spa Award'){
                if(this.$root.tripOnLoadData.AttendeeInfo != null){
                    if(value == 'Head Shot' && this.$root.tripOnLoadData.AttendeeInfo.AttendeeType == 1 && (this.$root.tripOnLoadData.AttendeeInfo.RegistrationFormType == 1 || this.$root.tripOnLoadData.AttendeeInfo.RegistrationFormType == 3)){
                        return true
                    }
                    else if(value == 'Spa Award' && this.$root.tripOnLoadData.AttendeeInfo.AttendeeType == 1){
                        if(this.$root.tripOnLoadData.AttendeeInfo.TagList && this.$root.tripOnLoadData.AttendeeInfo.TagList.includes('Spa Award')){
                            return true
                        }
                        else
                            return false
                    }
                    else
                        return false
                }
                else
                    return false
                
            }
            else
                return true
        },

        fn_gotoNotificationPage: function(){
            this.$router.push('/notifications');
        },

        fn_getTopTilesData: function(type){
            let self = this;
            this.loadingTiles = true;
            this.getData('Hero', '/Hero', 
                {
                    onSuccess: function(response){
                        console.log(JSON.stringify(response));
                        self.topTiles = response.homeTopTiles;
                        self.topTiles && self.topTiles.length >= 0 ? self.loadingTiles = false : ''; 
                        !type ? self.fn_getTopTilesFromServer(response.ModifiedDate) : '';
                        //self.snackbarMsg = false;
                    },
                    onError: function(error){
                        console.log(error);
                        self.loadingTiles = false;
                        self.snackbarMsg = false;
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },

        fn_getTopTilesFromServer: function(modifiedDate){
            // if(!navigator.onLine){
            //     // this.$root.fn_showToast("nointernet");
            //     return;
            // }

            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    // this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                        headers: {
                            'Authorization': 'bearer '+token,
                        }
                    };

                    let queries = modifiedDate ? "&ModifiedDate="+modifiedDate : '';

                     axios.get(this.$root.baseUrl + "/api/mobileapp/pwa/hometoptiles?excludecontent=false"+queries, headersObj, {timeout:30000})
                    .then(response => {
                        //this.loading = false;
                        console.log(response);
                        if(response.data.Message !== 'No Update Found!'){
                            this.fn_postHeroTilesData(response.data);
                        }
                        //this.fn_saveTopTiles(response.data);
                    }).catch(e => {
                        //this.loading = false;
                        console.log(e);
                    })
                }
            })
        },

        //fn_saveTopTiles: function(dataObj){
        //    let self = this;
        //    this.postData("Hero", dataObj, 
        //        {
        //            onSuccess: function(response){
        //                console.log(response);
        //                self.fn_getTopTilesData();
        //            },
        //            onError: function(error){
        //                console.log(error);
        //            }
        //        }
        //    );
        //},

        fn_postHeroTilesData: function(HeroTilesData){
            let self = this;
            this.postData("Hero", HeroTilesData, {
                onSuccess: function(response){
                    console.log(response);
                    self.fn_getTopTilesData('saved');
                },
                onError: function(error){
                    console.log(error);
                }
            });
        },

        // fn_openSocialFeed: function(){
        //     this.$router.push('/platinumclub/socialfeed');
        // }

        // fn_getPages: function(){
        //     if(!navigator.onLine){
        //         this.$root.fn_showToast("nointernet");
        //         return;
        //     }

        //     this.$root.fn_getToken(token=>{
        //         if(token == 'error'){
        //             this.$root.fn_showToast('exception');
        //         }
        //         else{
        //             var headersObj = {
        //                 headers: {
        //                     'Authorization': 'bearer '+token,
        //                     }
        //                 };

        //             axios.get(this.$root.baseUrl + "/api/mobileapp/active/pages", headersObj, {timeout:30000})
        //             .then(response => {
        //                 this.loading = false;
        //                 console.log(response);
        //                 this.pages = response.data.Pages;
        //                 this.$root.fn_saveLocalData('HomePageTiles_'+this.$root.tripSiteId, response.data.Pages, ()=>{
        //                     this.fn_filterHomePageTiles();
        //                 });
        //             }).catch(e => {
        //                 this.loading = false;
        //                 console.log(e);
        //             })
        //         }
        //     })
        // },

        // fn_filterHomePageTiles: function(){
        //     this.$root.fn_getLocalData('HomePageTiles_'+this.$root.tripSiteId, response=>{
        //         // this.pages = response;
        //         console.log(response);
        //     })
        // },

        // fn_getTopTiles: function(){
        //     if(!navigator.onLine){
        //         this.$root.fn_showToast("nointernet");
        //         return;
        //     }

        //     this.$root.fn_getToken(token=>{
        //         if(token == 'error'){
        //             this.$root.fn_showToast('exception');
        //         }
        //         else{
        //             var headersObj = {
        //                 headers: {
        //                     'Authorization': 'bearer '+token,
        //                     }
        //                 };

        //             axios.get(this.$root.baseUrl + "/api/mobileapp/active/pages", headersObj, {timeout:30000})
        //             .then(response => {
        //                 this.loading = false;
        //                 console.log(response);
        //                 this.pages = response.data.Pages;
        //                 this.$root.fn_saveLocalData('HomePageTiles_'+this.$root.tripSiteId, response.data.Pages, ()=>{
        //                     this.fn_filterHomePageTiles();
        //                 });
        //             }).catch(e => {
        //                 this.loading = false;
        //                 console.log(e);
        //             })
        //         }
        //     })
        // },

        //fn_updateAvailable: function(dataObj){
        //    let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //    console.log(updateObj);
        //    console.log('update available');
        //    if(updateObj.pageName==='Hero'){
        //        if(this.topTiles && this.topTiles.length>=0){
        //            this.snackbarMsg = true;
        //        }
        //        else{
        //            this.fn_getTopTilesData();
        //        }
        //    }
        //}
    },
    mounted() {
        //window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.$root.fn_postClickMetrics('Explore', 'Default', null);
        this.fn_getTopTilesData();
        this.$root.fn_getUnreadMessageCount();
        // if(localStorage.getItem("pn_registered_"+this.$root.tripSiteId)){
        //   CometChat.getUnreadMessageCount().then(array => {
        //     console.log("Message count fetched", array);
        //     for(let group in array){
        //         for(let key in array[group]){
        //             this.unreadMessageCount += array[group][key];
        //         }
        //     }
        //   }, error => {
        //     console.log("Error in getting message count", error);
        //   });
        // }
    },
    // computed:{
    //     filteredHomePageTiles: function(){
    //         if(this.pages && this.pages.length){
    //             return this.infoPages.filter(staticPage => !this.pages.some(serverPage => staticPage.PageName === serverPage.PageName));
    //         }
    //         else{
    //             return [];
    //         }
    //     }
    // }
  }
</script>

<style>
.platinumclub .slider-card-wrapper::-webkit-scrollbar {
  height: 5px !important;
}
  
.platinumclub .slider-card-wrapper::-webkit-scrollbar-thumb {
  background-color: #395b83 !important;
  border-radius: 5px !important;
}

.platinumclub .slider-card-wrapper::-webkit-scrollbar-track-piece:end {
  margin-right: 128px !important; 
  background-color: #eee !important;
}

.platinumclub .slider-card-wrapper::-webkit-scrollbar-track-piece:start {
  margin-left: 128px !important;
  background-color: #eee !important;
}

.watermarkimage{
        position: absolute;
        right:0;
        bottom: 0;
        width: 75%;
        height: 75%;
        background-image:url('../assets/2024/PC_SurfboardWatermark.png') !important;
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
    }
</style>